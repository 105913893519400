import { Onboarding, useAPI } from "../../components/lib";
import React from "react";
import ESPSelectView from "./ESPSelectView";
import ESPConfigurationView from "./ESPConfigurationView";
import ESPIntegrationDone from "./ESPIntegrationDone";
import ScriptConfiguration from "views/onboarding/ScriptConfiguration";
import DomainConfiguration from "views/onboarding/DomainConfiguration";

export default function IntegrateView(props) {
  const query = new URLSearchParams(props.location.search);
  const esp = query.get("esp");

  const user = useAPI("/api/user");

  if (!user.data) {
    return <div></div>;
  }
  return (
    <Onboarding
      noCache
      noSteps
      onFinish="/settings"
      views={[
        {
          name: "Select an ESP",
          component: (
            <ESPSelectView
              ESPs={[
                {
                  id: "mailchimp",
                  name: "Mailchimp",
                  integrateUrl: `${
                    process.env.REACT_APP_MAILCHIMP_INTEGRATE_URL
                  }?user=${user.data.id}&redirectTo=${encodeURIComponent(
                    "https://referralkit.herokuapp.com/integrate?esp=mailchimp#3"
                  )}`,
                },
                {
                  id: "aweber",
                  name: "AWeber",
                  integrateUrl: `${
                    process.env.REACT_APP_AWEBER_INTEGRATE_URL
                  }?user=${user.data.id}&redirectTo=${encodeURIComponent(
                    "https://referralkit.herokuapp.com/integrate?esp=aweber#3"
                  )}`,
                },
                {
                  id: "mailerlite",
                  name: "Mailerlite",
                  local: true,
                  integrateUrl: "/integrate?esp=mailerlite#2",
                },
                {
                  id: "convertkit",
                  name: "ConvertKit",
                  local: true,
                  integrateUrl: "/integrate?esp=convertkit#2",
                },
              ]}
            />
          ),
        },
        {
          name: "Configure ESP",
          description:
            "Fill in your API key. You can find the API key on your ESPs settings page." + "\n" + "Note: ConvertKit will only work with a premium ConvertKit account.",
          nextCopy: "Install Script",
          component: <ESPConfigurationView query={query} />,
        },
        {
          name: "Add script",
          description: "Add this script to your website. Ideally in the head tag.",
          nextCopy: "Setup Domain",
          component: <ScriptConfiguration hasBack={true} />,
        },
        {
          name: "Setup Domain",
          description:
            "Enter the URL of the website where you added the script.",
          nextCopy: "Check Installation",
          component: <DomainConfiguration />,
        },
        {
          name: "Done",
          component: (
            <ESPIntegrationDone query={query} baseURL={user?.data?.baseURL} />
          ),
        },
      ]}
    />
  );
}

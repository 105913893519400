import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  HiArrowRight,
  HiBadgeCheck,
  HiCheckCircle,
  HiChevronDown,
} from "react-icons/hi";
import Axios from "axios";
import EmailListDetails from "../../components/EmailListDetails";
import {
  Flex,
  Spinner,
  Text,
  Box,
  Button as ChakraButton,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { Chart, Card, Grid, useAPI, Icon } from "components/lib";
import Column from "components/custom/Column";
import Row from "components/custom/Row";
import CustomButton from "components/custom/CustomButton";

export function Plans({ location }) {
  const history = useHistory();

  const [subscribers, setSubscribers] = React.useState(200);

  const price =
    subscribers < 3000
      ? 0
      : subscribers < 5000
      ? 19
      : subscribers < 10000
      ? 29
      : subscribers < 15000
      ? 39
      : subscribers < 20000
      ? 49
      : subscribers < 25000
      ? 59
      : subscribers < 30000
      ? 69
      : subscribers < 35000
      ? 79
      : subscribers < 40000
      ? 89
      : null;

  function goToBilling() {
    history.replace("/settings/billing");
  }

  return (
    <div>
      <Column spacing="20px" align="center">
        <Heading
          as="h2"
          fontWeight="bold"
          fontSize="24px"
          lineHeight="32px"
          color="#4A4F56"
        >
          Pay As You Go
        </Heading>

        <Column
          p="40px"
          spacing="40px"
          bg="white"
          borderRadius="20px"
          align="center"
        >
          <Column spacing={`${32 + 24}px`} align="center">
            <Text
              fontWeight="bold"
              fontSize="24px"
              lineHeight="32px"
              color="#4A4F56"
            >
              I have{" "}
              <Box as="span" color="#4F7EFE" borderBottom="5px solid #4F7EFE;">
                {subscribers}
              </Box>{" "}
              subscribers
            </Text>

            <Slider
              aria-label="slider-ex-1"
              defaultValue={subscribers}
              onChange={setSubscribers}
              max={40000}
              maxW="300px"
              w="300px"
              position="relative"
            >
              <Text
                position="absolute"
                top="-24px"
                color="#4F7EFE"
                left="0"
                fontWeight="bold"
                fontSize="10px"
                lineHeight="14px"
              >
                0
              </Text>
              <Text
                position="absolute"
                top="-24px"
                color="#4F7EFE"
                right="0"
                fontWeight="bold"
                fontSize="10px"
                lineHeight="14px"
              >
                40k
              </Text>
              <SliderTrack bg="#BCCEFE" h="8px" borderRadius="8px">
                <SliderFilledTrack bg="#4F7EFE" h="8px" borderRadius="8px" />
              </SliderTrack>
              <SliderThumb bg="#4F7EFE" boxSize="16px" />
            </Slider>
          </Column>

          <List spacing={4}>
            {benefits.map((benefit) => (
              <ListItem>
                <ListIcon
                  as={() => (
                    <HiCheckCircle
                      size={24}
                      color="#1EC26A"
                      style={{ display: "inline" }}
                    />
                  )}
                  color="#1EC26A"
                  size={24}
                />
                <Text
                  ml="1rem"
                  display="inline"
                  fontSize="16px"
                  lineHeight="170%"
                  textTransform="capitalize"
                >
                  {benefit}
                </Text>
              </ListItem>
            ))}
          </List>

          {subscribers === 40000 ? (
            <Text
              fontWeight="bold"
              fontSize="36px"
              lineHeight="36px"
              color="#4A4F56"
            >
              Contact Us
            </Text>
          ) : (
            <Row align="flex-end">
              <Text
                fontWeight="bold"
                fontSize="36px"
                lineHeight="36px"
                color="#4A4F56"
              >
                ${price}
              </Text>
              <Text fontSize="16px" lineHeight="24px" color="#4A4F56">
                / month
              </Text>
            </Row>
          )}

          <CustomButton
            rightIcon={<HiArrowRight />}
            size="lg"
            fontSize="14px"
            p={8}
            onClick={goToBilling}
          >
            Manage Billing
          </CustomButton>
        </Column>

        <Text fontSize="12px" lineHeight="16px">
          * Price automatically adjusts based on the number of your subscribers.
        </Text>
      </Column>
    </div>
  );
}

const benefits = [
  "Includes unlimited referrals",
  "Includes unlimited rewards",
  "Includes unlimited team members",
];

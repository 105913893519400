/***
 *
 *   ONBOARDING
 *   Flow to help users set up the app, accepts multiple views/steps
 *   On finish/cancel, the user will be marked as onboarded
 *
 *   PROPS
 *   views: array of views to render containing keys:
 *     name, description and component to render
 *
 **********/

import React, { useState } from "react";
import Axios from "axios";
import {
  Animate,
  Card,
  CheckList,
  Button,
  Logo,
  History,
} from "components/lib";
import Style from "./onboarding.module.scss";
import Column from "components/custom/Column";
import Row from "components/custom/Row";
import { Flex, Text } from "@chakra-ui/react";
import CustomButton, { LinkButton } from "components/custom/CustomButton";
import useLocalStorage from "hooks/useLocalStorage";
import { useHistory } from "react-router-dom";

export function Onboarding({ noCache = false, noSteps = false, ...props }) {
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);

  const hash = window.location.hash;
  // const activeView = hash ? Number(hash.substring(1)) - 1 : 0;
  const activeView = hash ? Number(hash.substring(1)) - 1 : 0;

  const [esp, setEsp] = useLocalStorage("onboarding-esp", query.get("esp"));
  const [onboardingStep, setOnboardingStep] = useLocalStorage(
    "onboarding-step",
    1
  );

  console.log("onboardingStep: " + onboardingStep);

  React.useEffect(() => {
    if (activeView + 1 > onboardingStep) {
      setOnboardingStep(onboardingStep);
      // TODO: uncomment this
      // history.push(`/onboarding?esp=${esp}#${onboardingStep}`);
    }
  }, []);

  React.useEffect(() => {
    if (activeView + 1 > onboardingStep) {
      setOnboardingStep(onboardingStep);
      // history.push(`/onboarding?esp=${esp}#${onboardingStep}`);
    }
  }, [hash]);

  // React.useEffect(() => {}, [hash]);

  if (!props?.views?.length) return false;

  const view = props.views[activeView];

  const pathname = window.location.pathname.includes("onboarding")
    ? "/onboarding"
    : "/integrate";

  async function navigate(view) {
    const newView = view + 1;

    const espFromQueryParams = query.get("esp");

    const hasEspFromLocalStorage = esp !== "undefined";
    const hasEspFromQueryParams = espFromQueryParams;

    const espParam = hasEspFromLocalStorage
      ? `esp=${esp}`
      : hasEspFromQueryParams
      ? `esp=${espFromQueryParams}`
      : "";

    history.push(`${pathname}?${espParam}#${newView}`);
    setOnboardingStep(newView);
  }

  async function finish() {
    if (props.save) await Axios.patch("/api/user", { onboarded: true });

    History.push(props.onFinish || "/dashboard");
  }

  const isFinal = activeView === props.views.length - 1;

  return (
    <Column spacing="32px" pt="40px" align="center">
      <Logo
        isBlue
        hasLink={pathname.includes("onboarding")}
        link={`/onboarding`}
      />

      <Column
        bg="white"
        borderRadius="16px"
        p="40px"
        maxW="500px"
        spacing="32px"
      >
        {!isFinal && (
          <Column spacing="16px">
            <Column spacing="4px">
              {!noSteps && (
                <Text fontSize="10px" lineHeight="14px" color="#4F7EFE">
                  <b>STEP {activeView + 1}</b> OUT OF 4
                </Text>
              )}
              <Text
                fontWeight="bold"
                fontSize="24px"
                lineHeight="32px"
                color="#4A4F56"
              >
                {view.name}
              </Text>
            </Column>
            <Text fontSize="14px" lineHeight="20px" color="#4A4F56">
              {view.description}
            </Text>
          </Column>
        )}

        {React.cloneElement(view.component, {
          next: () => {
            navigate(activeView + 1);
          },
          back: () => {
            navigate(activeView - 1);
          },
        })}

        {/* <Flex w="100%" justify={hasBack ? "space-between" : "flex-end"}>
          {hasBack && (
            <LinkButton onClick={() => navigate(activeView - 1)}>
              Back
            </LinkButton>
          )}
          {hasNext && (
            <CustomButton
              alignSelf="flex-end"
              onClick={() => {
                navigate(activeView + 1);
              }}
            >
              Next
            </CustomButton>
          )}
        </Flex> */}
      </Column>

      {/* <Card noPadding className={Style.onboarding}>
        <Button
          color="light"
          icon="arrow-right-circle"
          text="Skip Intro"
          className={Style.skip}
          size={16}
          action={finish}
        />

        <section className={Style.sidebar}>
          <Logo />
          <CheckList
            className={Style.checklist}
            hideCross
            circles
            interactive
            items={props.views.map((view, i) => {
              return {
                name: view.name,
                checked: i <= activeView ? true : false,
                onClick: () => navigate(i),
              };
            })}
          />
        </section>

        <section className={Style.main}>
          <header>
            <h2>{view.name}</h2>
            {view.description && <span>{view.description}</span>}
          </header>

          {view.component}
        </section>

        <nav className={Style.nav}>
          {activeView > 0 && (
            <Button
              icon="chevron-left"
              color="dark"
              size={16}
              text="Prev"
              className={Style.prev}
              action={() => navigate(activeView - 1)}
            />
          )}

          {activeView < props.views.length - 1 && (
            <Button
              icon="chevron-right"
              color="dark"
              size={16}
              text="Next"
              className={Style.next}
              action={() => {
                navigate(activeView + 1);
              }}
            />
          )}

          {activeView === props.views.length - 1 && (
            <Button
              icon="check-circle"
              color="dark"
              size={16}
              text="Finish"
              className={Style.next}
              action={finish}
            />
          )}
        </nav>
      </Card> */}
    </Column>
  );
}

/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

import React from "react";
import { HomeNav, Footer } from "components/lib";
import Style from "./public.module.scss";
import AuthCard from "components/custom/AuthCard";
import LandingPageCard from "components/custom/LandingPageCard";

export function PublicLayout(props) {
  const hasTerms = props?.data?.location?.pathname === "/signup";

  return (
    <main className={Style.auth}>
      <LandingPageCard hasTerms={hasTerms}>
        {<props.children {...props.data} />}
      </LandingPageCard>
    </main>
  );
}

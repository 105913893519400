import { Input } from "@chakra-ui/react";
import Column from "components/custom/Column";
import { SecondaryButton } from "components/custom/CustomButton";
import Pagination from "components/custom/Pagination";
import { ViewContext } from "components/lib";
import React, { useContext } from "react";
import { HiClipboard } from "react-icons/hi";
import copyToClipboard from "utils/copyToClipboard";
import toast from "react-hot-toast";

const script =
  '<script type="text/javascript" src="https://app.referralkit.co/embed.js" data-referralkit></script>';

export default function ScriptConfiguration({ hasBack, back, next }) {
  const viewContext = useContext(ViewContext);

  const copy = () => {
    copyToClipboard(script);
    toast.success("Script code copied!");
  };

  return (
    <Column spacing="32px">
      <Column spacing="8px">
        <Input
          background="#F7F7F8"
          p="10px 16px"
          border="1px solid rgba(74, 79, 86, 0.5)"
          fontSize="14px"
          isReadOnly
          h="100px"
          as="textarea"
          fontWeight={600}
          color="#4A4F56"
          value={script}
          borderRadius="8px"
        />

        <SecondaryButton rightIcon={<HiClipboard />} onClick={copy} w="160px">
          Copy To Clipboard
        </SecondaryButton>
      </Column>
      <Pagination
        onPrevious={back}
        onNext={next}
        hasNext={true}
        hasBack={hasBack}
      />
    </Column>
  );
}

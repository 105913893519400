import React from "react";

function Referral({ size, color = "#4A4F56" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.941 18.1508L21.0124 12.8048C21.6623 12.4601 21.6623 11.5325 21.0124 11.1869L10.9556 5.84913C10.1966 5.4458 9.34598 6.20663 9.66773 7.00046L11.6743 11.9505L9.65123 17.0013C9.33407 17.796 10.1838 18.5523 10.941 18.1508Z"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.6736 11.9541L21.5003 11.9999"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.00012 14.75H6.46679"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.0835 12H6.46683"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.16687 9.24996H6.46687"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

export default React.memo(Referral);

import React from "react";
import { Bar } from "react-chartjs-2";

import "./customBar";

export function BarChart(props) {
  let options = { ...props.options };
  options.maintainAspectRatio = false;
  options.responsive = true;
  options.cornerRadius = 8;

  return <Bar data={props.data} options={options} />;
}

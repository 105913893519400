import { Dashboard } from "views/dashboard/dashboard";

export default [
  {
    path: "/",
    view: Dashboard,
    layout: "app",
    permission: "user",
    title: "Dashboard",
  },
];

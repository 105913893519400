import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HiChevronDown } from "react-icons/hi";
import Axios from "axios";
import EmailListDetails from "../../components/EmailListDetails";
import {
  Flex,
  Spinner,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  Portal,
  MenuItemOption,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { Link, Card, Grid, useAPI, Icon } from "components/lib";

export function Emails({ location }) {
  return <div>Emails</div>;
}

import { Flex } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import CustomButton, { LinkButton } from "./CustomButton";

function isPromise(promise) {
  return !!promise && typeof promise.then === "function";
}

const Pagination = ({
  onNext,
  onPrevious,
  hasNext = true,
  hasBack = true,
  onSubmit = () => {},
}) => {
  const history = useHistory();

  function next() {
    const submitPromise = onSubmit();
    if (isPromise(submitPromise)) {
      console.log("yeah");
      submitPromise
        .then((res) => {
          //TODO: test this with valid api key
          onNext();
          if (res) {
            onNext();
            console.log(res);
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("nope");
      onNext();
    }
  }

  return (
    <Flex w="100%" justify={hasBack ? "space-between" : "flex-end"}>
      {hasBack && <LinkButton onClick={onPrevious}>Back</LinkButton>}
      {hasNext && (
        <CustomButton alignSelf="flex-end" onClick={next}>
          Next
        </CustomButton>
      )}
    </Flex>
  );
};

export default Pagination;

import React from "react";
import { Form } from "./lib";
import { Grid } from "./grid/grid";
import Column from "./custom/Column";
import UploadButton from "./custom/UploadButton";
import { Box, Text, Image as ChImage } from "@chakra-ui/react";
import Row from "./custom/Row";

export default function MilestoneEditor({
  listId,
  milestone,
  onSuccess,
  ctaName,
}) {
  const [subject, setSubject] = React.useState(
    milestone ? milestone.subject : ""
  );
  const [image, setImage] = React.useState("");
  const [name, setName] = React.useState(milestone ? milestone.name : "");
  const [referrals, setReferrals] = React.useState(
    milestone ? milestone.referralCount : null
  );
  const [message, setMessage] = React.useState(
    milestone ? milestone.message : ""
  );

  let method = "POST";
  let action = `/api/lists/${listId}/milestones`;

  const form = {
    image: {
      hidden: true,
      label: "Image",
      type: "text",
      value: image,
    },
    name: {
      label: "Name",
      required: true,
      placeholder: "eg. Level Diamond",
      value: name,
    },
    referralCount: {
      label: "Referral Count",
      placeholder: "Referrals goal to get the reward",
      required: true,
      type: "number",
      value: referrals,
    },
    rewardMessage: {
      label: "Reward Message (can be HTML or text)",
      placeholder: "Type in a message for your subscribers",
      required: true,
      type: "textarea",
      value: message,
    },
    subject: {
      label: "Email Subject (Optional)",
      placeholder: "Type in the email subject",
      required: false,
      type: "text",
      value: subject,
    },
  };

  if (milestone) {
    console.log(milestone);
    // form.name.value = milestone.name;
    // form.referralCount.value = milestone.referralCount;
    // form.rewardMessage.value = milestone.message;

    const rewardId = milestone.rewardId ? milestone.rewardId : "null";
    method = "PUT";
    action += `/${milestone.id}/${rewardId}`;
  }

  console.log(milestone);

  function onFormSuccess(response) {
    if (onSuccess) {
      onSuccess(response.data.data);
    }
  }

  return (
    <Column spacing={16}>
      <Column>
        <Text
          fontFamily="Inter"
          fontSize="10px"
          lineHeight="14px"
          color="#4A4F56"
        >
          Image (Optional):
        </Text>
        <Row align="flex-end">
          <ChImage
            maxW="150px"
            src={image !== "" ? image : milestone?.imageURL}
            boxShadow="0px 8px 16px -8px rgba(0, 0, 0, 0.3)"
            borderRadius="8px"
          />
          <Box>
            <UploadButton
              isSecondary
              text="Upload Image"
              onComplete={(url) => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                const img = new Image();

                img.onload = function () {
                  // set size proportional to image
                  canvas.width = 400;
                  canvas.height = canvas.width * (img.height / img.width);

                  // step 1 - resize to 50%
                  const oc = document.createElement("canvas"),
                    octx = oc.getContext("2d");

                  oc.width = img.width * 0.5;
                  oc.height = img.height * 0.5;
                  octx.drawImage(img, 0, 0, oc.width, oc.height);

                  // step 2
                  octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);

                  // step 3, resize to final size
                  ctx.drawImage(
                    oc,
                    0,
                    0,
                    oc.width * 0.5,
                    oc.height * 0.5,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                  );

                  const base64 = canvas.toDataURL("image/jpg", 0.7);
                  setImage(base64);
                };
                img.src = url;
              }}
            />
          </Box>
        </Row>
      </Column>

      <Form
        updateOnChange
        onChange={(obj) => {
          switch (obj.input) {
            case "name":
              setName(obj.value);
              break;
            case "subject":
              setSubject(obj.value);
              break;
            case "referralCount":
              setReferrals(obj.value);
              break;
            case "rewardMessage":
              setMessage(obj.value);
              break;
          }
        }}
        method={method}
        url={action}
        buttonText={ctaName}
        data={form}
        callback={onFormSuccess}
        fullWidthButton
      />
    </Column>
  );
}

import React from "react";

function ConvertKit({ size = 24, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.427 73.8384C64.0555 73.8384 76.135 62.0477 76.135 47.5034C76.135 33.8769 64.7865 25.5574 57.2701 25.5574C46.8034 25.5574 38.4133 32.8921 37.218 43.7809C36.9966 45.7974 35.3967 47.4884 33.3664 47.474C29.9914 47.4498 25.0839 47.421 21.9777 47.4318C20.6402 47.4364 19.535 46.3528 19.6009 45.018C20.0693 35.5742 23.2317 26.744 29.2614 19.9611C35.9614 12.4241 45.7187 8 57.2701 8C76.6781 8 95 25.6104 95 47.5034C95 71.7443 74.8872 91.3958 48.8399 91.3958C30.2642 91.3958 11.5552 79.2731 5.02309 62.3247C4.99959 62.2619 4.99389 62.1938 5.00662 62.128C5.09413 61.6266 5.26658 61.1684 5.43647 60.7175C5.68356 60.0616 5.92551 59.4202 5.89051 58.6815L5.77726 56.3207C5.75124 55.7492 5.89617 55.183 6.19355 54.6944C6.49092 54.2057 6.92722 53.8168 7.44672 53.5774L8.39084 53.1383C8.91978 52.8938 9.38237 52.526 9.73977 52.0658C10.0972 51.6055 10.339 51.0663 10.4448 50.4933C10.7614 48.762 12.2832 47.5034 14.0597 47.5034C16.8344 47.5034 19.2467 49.3458 20.1527 51.965C24.5146 64.5722 31.4488 73.8384 48.427 73.8384Z"
        fill="#FB6970"
      />
    </svg>
  );
}

export default React.memo(ConvertKit);

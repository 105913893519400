/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { HiCheckCircle, HiClipboard } from "react-icons/hi";
import { Text, Box, Button, Spinner } from "@chakra-ui/react";
import { HiUpload } from "react-icons/hi";
import { TiWarningOutline } from "react-icons/ti";
import {
  AuthContext,
  ViewContext,
  Form,
  Card,
  AccountNav,
  History,
  Animate,
  useAPI,
  TextInput,
} from "components/lib";
import Axios from "axios";
import SettingsCard from "components/custom/SettingsCard";
import Row from "components/custom/Row";
import Column from "components/custom/Column";
import CustomButton from "components/custom/CustomButton";
import copyToClipboard from "utils/copyToClipboard";
import toast from "react-hot-toast";

export function Script(props) {
  const history = useHistory();

  const [isSetup, setIsSetup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI("/api/user");

  console.log(user);

  const hasBaseUrl = user?.data?.baseURL;

  React.useEffect(() => {
    const baseURL = user?.data?.baseURL;

    if (baseURL) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("url", baseURL);

      Axios({
        method: "GET",
        url: `/api/account/script/validate/${encodeURIComponent(baseURL)}`,
        data: formData,
      })
        .then((response) => {
          const isValid = response.data === "OK";

          setIsLoading(false);
          setIsSetup(isValid);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [user]);

  function closeAccount() {
    viewContext.modal.show(
      {
        title: "Close Your Account",
        form: closeAccount,
        buttonText: "Close Account",
        url: "/api/account",
        method: "DELETE",
        text:
          "Are you sure you want to delete your account? " +
          "You will lose all of your data and this can not be undone.",
      },
      () => {
        // destory user
        localStorage.clear();
        History.push("/signup");
      }
    );
  }

  function copy() {
    copyToClipboard(scriptText);
    toast.success("Script copied!");
  }

  const scriptText = `<script type="text/javascript" src="https://app.referralkit.co/embed.js" data-referralkit></script>`;

  return (
    <SettingsCard>
      <Card
        title="Script Installation"
        loading={user.loading}
        noPadding
        lgTitle
      >
        <Column maxW="600px">
          <Text align="center" mb={8} spacing={4} textAlign="left">
            Paste the following <CodeBadge>{"<script>"}</CodeBadge> tag onto
            every page of your website, ideally in the{" "}
            <CodeBadge>{"<head>"}</CodeBadge> tag:
          </Text>

          <Box position="absolute" top="0" right="0">
            {isLoading ? (
              <Spinner />
            ) : hasBaseUrl ? (
              isSetup ? (
                <Row
                  p="8px"
                  borderRadius="4px"
                  color="#106A3A"
                  bg="#D2F4E2"
                  align="center"
                >
                  <HiCheckCircle />
                  <Text fontSize="12px" fontWeight="600">
                    Installed
                  </Text>
                </Row>
              ) : (
                <Row
                  p="8px"
                  borderRadius="4px"
                  color="#9B5E18"
                  bg="#FAECDC"
                  align="center"
                >
                  <TiWarningOutline />
                  <Text fontSize="12px" fontWeight="600">
                    Not Installed
                  </Text>
                </Row>
              )
            ) : (
              <Row
                p="8px"
                borderRadius="4px"
                color="#9B5E18"
                bg="#FAECDC"
                align="center"
              >
                <Text fontSize="12px" fontWeight="600">
                  No Domain Yet
                </Text>
              </Row>
            )}
          </Box>

          <TextInput
            type="textarea"
            value={scriptText}
            disabled
            label="Script:"
            style={{ maxHeight: "64px" }}
            onChange={() => {}}
          />

          <CustomButton
            maxW="160px"
            size="lg"
            rightIcon={<HiClipboard />}
            onClick={copy}
            p="2rem !important"
            isFullWidth="false"
          >
            Copy To Clipboard
          </CustomButton>
        </Column>
      </Card>
    </SettingsCard>
  );
}

const CodeBadge = ({ children }) => {
  return (
    <Box
      px={2}
      py={1}
      borderRadius="md"
      bg="gray.100"
      color="gray.900"
      fontStyle="italic"
      fontSize="14px"
      fontWeight="600"
      display="inline"
      as="span"
    >
      {children}
    </Box>
  );
};

import { Profile } from "views/settings/profile";
import { Password } from "views/settings/password";
import { Billing } from "views/settings/billing";
import { Branding } from "views/settings/branding";
import { Upgrade } from "views/settings/upgrade";
import { Script } from "views/settings/script";
import { Users } from "views/settings/users";
import Newsletters from "../views/settings/newsletters";

export default [
  {
    path: "/settings",
    view: Profile,
    layout: "app",
    permission: "user",
    title: "Settings",
  },
  {
    path: "/settings/password",
    view: Password,
    layout: "app",
    permission: "user",
    title: "Settings",
  },
  {
    path: "/settings/script",
    view: Script,
    layout: "app",
    permission: "user",
    title: "Script Settings",
  },
  {
    path: "/settings/billing",
    view: Billing,
    layout: "app",
    permission: "owner",
    title: "Settings",
  },
  {
    path: "/settings/upgrade",
    view: Upgrade,
    layout: "app",
    permission: "user",
    title: "Settings",
  },
  {
    path: "/settings/users",
    view: Users,
    layout: "app",
    permission: "admin",
    title: "Settings",
  },
  {
    path: "/settings/newsletters",
    view: Newsletters,
    layout: "app",
    permission: "user",
    title: "Settings",
  },
  {
    path: "/settings/branding",
    view: Branding,
    layout: "app",
    permission: "user",
    title: "Settings",
  },
];

/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import React from "react";
import { Link, Form, Card } from "components/lib";
import { Heading, Text } from "@chakra-ui/react";
import Column from "components/custom/Column";

export function ForgotPassword(props) {
  return (
    <Column w="100%" align="center" spacing="32px">
      <Heading
        as="h2"
        fontfamily="Inter"
        fontstyle="normal"
        fontweight="bold"
        fontsize="24px"
        lineheight="32px"
        textalign="center"
        color="#4A4F56"
      >
        Forgot My Password
      </Heading>

      <Text
        fontSize="16px"
        lineHeight="24px"
        textAlign="center"
        color="#4A4F56"
      >
        Enter your email address and we'll send you instructions to reset your
        password.
      </Text>

      <Form
        data={{
          email: {
            label: "Email",
            type: "email",
            required: true,
          },
        }}
        url="/api/user/password/reset/request"
        method="POST"
        buttonText="Reset Password"
        fullWidthButton
        xlButton
      />

      <span style={{ fontSize: "14px" }}>
        I have an account already. <Link url="/signin" text="Log In" />
      </span>
    </Column>
  );
}

import { Box, Heading, Text, Image } from "@chakra-ui/react";
import Column from "components/custom/Column";
import CustomButton from "components/custom/CustomButton";
import { useAPI } from "components/lib";
import useLocalStorage from "hooks/useLocalStorage";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import previewImg from "./preview.jpg";

export function LockedDashboard({ location }) {
  const history = useHistory();

  const query = new URLSearchParams(window.location.search);
  const reason = query.get("reason");

  const user = useAPI("/api/user");

  React.useEffect(() => {
    if (user.data && !user.loading) {
      const isImporting = user?.data?.is_importing;

      if (!isImporting && reason === "import") {
        history.push("/");
      }
    }
  }, [user]);

  const [step, _] = useLocalStorage("onboarding-step");
  const [esp, __] = useLocalStorage("onboarding-esp");

  const [url, setUrl] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [ctaText, setCtaText] = React.useState("");

  React.useEffect(() => {
    // Select which message/cta to display

    const isImporting = reason === "import";
    const isOnboarding = reason === "onboarding";
    if (isImporting) {
      const importURL = "/milestones";
      setUrl(importURL);
      setTitle("Importing Your Data...");
      setDescription(
        "We'll send you an email when your data are ready! You can setup your rewards in the meanwhile."
      );
      setCtaText("Go To Milestones");
    } else if (isOnboarding) {
      const onboardingURL = `/onboarding/${
        esp !== "undefined" ? `?esp=${esp}` : ""
      }#${step !== undefined ? `${step}` : ""}`;
      setUrl(onboardingURL);
      setTitle("Complete your onboarding process to unlock your stats");
      setCtaText("Complete Now");
    } else {
      // Never here but let's render the onboarding options anyway
      const onboardingURL = `/onboarding/${
        esp !== "undefined" ? `?esp=${esp}` : ""
      }#${step !== undefined ? `${step}` : ""}`;
      setUrl(onboardingURL);
      setTitle("Complete your onboarding process to unlock your stats");
      setCtaText("Complete Now");
    }
  }, []);

  return (
    <Box
      // w='100%' h='100%'
      position="relative"
    >
      <Image
        src={previewImg}
        style={{ filter: "blur(3px)", objectFit: "cover" }}
        w="100%"
        minH="300px"
      />

      <Column
        bg="white"
        borderRadius="16px"
        position="absolute"
        top="0"
        left="50%"
        transform="translate(-50%, 100%)"
        p="32px"
        align="center"
        spacing="16px"
        border="1px solid #F1F1F1"
        boxShadow="0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
        maxW="300px"
      >
        <Heading
          fontWeight="600"
          fontSize="20px"
          lineHeight="28px"
          textAlign="center"
          color="#4A4F56"
        >
          {title}
        </Heading>

        <Text
          fontWeight="400"
          fontSize="16px"
          lineHeight="28px"
          textAlign="center"
          color="#4A4F56"
        >
          {description}
        </Text>

        <Link to={url}>
          <CustomButton>{ctaText}</CustomButton>
        </Link>
      </Column>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useWaitForIt from "../../components/hooks/useWaitForIt";
import Axios from "axios";
import {
  Flex,
  Spinner,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  Portal,
  MenuItemOption,
  Button as ChakraButton,
  Heading,
  Box,
  Image,
  AspectRatio,
} from "@chakra-ui/react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Card } from "../../components/card/card";
import { Button } from "../../components/button/button";
import { Grid } from "../../components/grid/grid";
import { useAPI } from "../../components/lib";
import CustomButton, { LinkButton } from "components/custom/CustomButton";
import MailChimp from "components/icons/MailChimp";
import Row from "components/custom/Row";
import ConvertKit from "components/icons/ConvertKit";
import Mailerlite from "components/icons/Mailerlite";
import {
  HiArrowRight,
  HiChevronDown,
  HiPencil,
  HiPlus,
  HiX,
} from "react-icons/hi";
import Column from "components/custom/Column";
import Referral from "components/icons/Referral";
import NewMilestonIllustration from "components/icons/NewMilestonIllustration";
import MilestoneEditor from "components/MilestoneEditor";
import MilestoneDetails from "./MilestoneDetails";
import Aweber from "components/icons/Aweber";

export default function MilestoneList({ location }) {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const mailchimpAudiences = useAPI("/api/mailchimp/lists");
  const mailerliteLists = useAPI("/api/mailerlite/lists");
  const convertkitLists = useAPI("/api/convertkit/lists");
  const aweberAudiences = useAPI("/api/aweber/lists");

  const [listId, setListId] = useState("");
  const [esp, setEsp] = useState("");
  const [milestones, setMilestones] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [mailchimpName, setMailchimpName] = React.useState("");
  const [aweberName, setAweberName] = React.useState("");
  const [milestoneId, setMilestoneId] = React.useState(null);

  async function loadMilestonesForList() {
    const newMilestones = await Axios.get(
      `/api/lists/${listId}/milestones`
    ).then((response) => response.data.data);

    console.log(newMilestones);
    setMilestones(newMilestones);
  }

  function onListSelected(espListId) {
    const [esp, listId] = espListId.split("_");
    setSelectedId(espListId);
    history.replace(`/milestones?esp=${esp}&listId=${listId}`);
  }

  useEffect(() => {
    const isLoading =
      mailchimpAudiences?.loading ||
      aweberAudiences?.loading ||
      mailerliteLists?.loading ||
      convertkitLists?.loading;

    if (isLoading) {
      return;
    }

    const query = new URLSearchParams(location.search);
    const queryEsp = query.get("esp");
    const queryListId = query.get("listId");

    if (!queryEsp || !queryListId) {
      if (mailchimpAudiences.data && mailchimpAudiences.data.length > 0) {
        setMailchimpName(mailchimpAudiences.data[0].name);
        return onListSelected(`mailchimp_${mailchimpAudiences.data[0].id}`);
      } else if (aweberAudiences.data && aweberAudiences.data.length > 0) {
        setAweberName(aweberAudiences.data[0].name);
        return onListSelected(`aweber_${aweberAudiences.data[0].id}`);
      } else if (mailerliteLists.data) {
        return onListSelected(`mailerlite_${mailerliteLists.data[0].id}`);
      } else if (convertkitLists.data) {
        return onListSelected(`convertkit_${convertkitLists.data[0].id}`);
      }
    }

    setEsp(queryEsp);
    setListId(queryListId);
    setSelectedId(`${queryEsp}_${queryListId}`);
  }, [
    location,
    aweberAudiences,
    mailchimpAudiences,
    mailerliteLists,
    convertkitLists,
  ]);

  useEffect(() => {
    if (listId) {
      loadMilestonesForList();
    }
  }, [listId]);

  const createNew = () => {
    history.replace(`/lists/${esp}/${listId}/milestones/new`);
  };

  const isLoading = React.useMemo(
    () =>
      mailchimpAudiences?.loading ||
      aweberAudiences?.loading ||
      mailerliteLists?.loading ||
      convertkitLists?.loading,
    [aweberAudiences, mailchimpAudiences, mailerliteLists, convertkitLists]
  );

  const hasLoadLists =
    Array.isArray(mailchimpAudiences?.data) ||
    Array.isArray(aweberAudiences?.data) ||
    Array.isArray(convertkitLists?.data) ||
    Array.isArray(mailerliteLists?.data);

  const noMilestones = Array.isArray(milestones) && milestones.length === 0;

  if (isLoading || !hasLoadLists || !Array.isArray(milestones)) {
    return <Spinner />;
  }

  return (
    <div>
      <Grid cols="1">
        <Flex justify="space-between" zIndex={10}>
          <Menu>
            <MenuButton
              as={ChakraButton}
              rightIcon={<HiChevronDown size={18} />}
              p="10px 16px"
              borderRadius="8px"
              bg="white"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
              minH="40px"
              _hover={{ bg: "white" }}
              _expanded={{ bg: "#f8f8f8" }}
              _focus={{ bg: "white" }}
              isDisabled={esp === null}
              zIndex={10}
            >
              <Row align="center">
                {esp === "convertkit" ? (
                  <ConvertKit />
                ) : esp === "mailerlite" ? (
                  <Mailerlite />
                ) : esp === "aweber" ? (
                  <Aweber />
                ) : esp === "mailchimp" ? (
                  <MailChimp />
                ) : null}
                <Text
                  fontfamily="Inter"
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="20px"
                  color="#4A4F56"
                  textTransform="capitalize"
                >
                  {esp === "mailchimp"
                    ? mailchimpName
                    : esp === "aweber"
                    ? aweberName
                    : esp}
                  {esp === null && "Please, Connect A Newsletter"}
                </Text>
              </Row>
            </MenuButton>

            <Portal>
              <MenuList>
                {mailerliteLists?.data?.length > 0 && (
                  <MenuItem
                    onClick={() =>
                      onListSelected(
                        `mailerlite_${mailerliteLists?.data[0]?.id}`
                      )
                    }
                  >
                    <Row p={2} spacing={4}>
                      <Mailerlite />
                      <Text>MailerLite</Text>
                    </Row>
                  </MenuItem>
                )}
                {convertkitLists?.data?.length > 0 && (
                  <MenuItem
                    onClick={(event) =>
                      onListSelected(
                        `convertkit_${convertkitLists?.data[0]?.id}`
                      )
                    }
                  >
                    <Row p={2} spacing={4}>
                      <ConvertKit />
                      <Text>Convertkit</Text>
                    </Row>
                  </MenuItem>
                )}

                {mailchimpAudiences?.data?.length > 0 && (
                  <MenuOptionGroup
                    defaultValue="asc"
                    title="MailChimp"
                    type="radio"
                  >
                    {mailchimpAudiences.data.map((audience) => (
                      <MenuItemOption
                        key={audience.id}
                        onClick={() => {
                          onListSelected(`mailchimp_${audience.id}`);
                          setMailchimpName(audience.name);
                        }}
                      >
                        <Row p={2} spacing={4}>
                          <MailChimp />
                          <Text>{audience.name}</Text>
                        </Row>
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                )}

                {aweberAudiences?.data?.length > 0 && (
                  <MenuOptionGroup
                    defaultValue="asc"
                    title="Aweber"
                    type="radio"
                  >
                    {aweberAudiences.data.map((audience) => (
                      <MenuItemOption
                        key={audience.id}
                        onClick={() => {
                          onListSelected(`aweber_${audience.id}`);
                          setAweberName(audience.name);
                        }}
                      >
                        <Row p={2} spacing={4}>
                          <Aweber />
                          <Text>{audience.name}</Text>
                        </Row>
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                )}
              </MenuList>
            </Portal>
          </Menu>

          {listId && !noMilestones && (
            <CustomButton
              p={8}
              fontSize="16px"
              rightIcon={<HiPlus />}
              // onClick={createNew}
              onClick={onOpen}
            >
              Create New
            </CustomButton>
          )}
        </Flex>
      </Grid>

      {listId && (
        <Column spacing="20px" mt="20px">
          {noMilestones ? (
            <EmptyCard createNew={createNew} />
          ) : (
            milestones.map((milestone) => (
              <Flex
                key={milestone.id}
                borderRadius="16px"
                p="20px"
                w="100%"
                bg="white"
                justify="space-between"
              >
                <Row spacing="20px">
                  <AspectRatio ratio={3 / 2} w="130px">
                    <Image
                      src={milestone.imageURL} //"https://source.unsplash.com/random"
                      borderRadius="8px"
                      fallbackSrc="https://referralkit.s3.us-east-2.amazonaws.com/placeholder.jpg"
                    />
                  </AspectRatio>
                  <Column spacing="15px">
                    <Heading
                      as="h2"
                      fontWeight="bold"
                      fontSize="24px"
                      lineHeight="32px"
                      color="#4A4F56"
                      textTransform="capitalize"
                    >
                      {milestone.name}
                    </Heading>
                    <Row>
                      <Referral size={24} />
                      <Text
                        fontWeight="normal"
                        fontSize="16px"
                        lineHeight="24px"
                        color="#4A4F56"
                      >
                        <b>{milestone.referralCount}</b> Referrals
                      </Text>
                    </Row>
                  </Column>
                </Row>

                {/* <Link to={`/lists/${esp}/${listId}/milestones/${milestone.id}`}> */}
                <LinkButton
                  leftIcon={<HiPencil />}
                  onClick={() => {
                    onEditOpen();
                    setMilestoneId(milestone.id);
                  }}
                >
                  Edit Milestone
                </LinkButton>
                {/* </Link> */}
              </Flex>
            ))
          )}
        </Column>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay color="white" />
        <ModalContent
          p="40px"
          borderRadius="20px"
          border="1px solid #DDDDDD"
          maxW="420px"
        >
          <ModalHeader p={0} mb="20px">
            <Flex w="100%" justify="space-between">
              <Heading as="h2" fontSize="18px" fontWeight="500">
                Create Milestone
              </Heading>
              <Box cursor="pointer" onClick={onClose}>
                <HiX />
              </Box>
            </Flex>
          </ModalHeader>
          <ModalBody maxW="420px" p={0}>
            <MilestoneEditor
              listId={listId}
              onSuccess={() => {
                onClose();
                loadMilestonesForList();
              }}
              ctaName="Create The Milestone"
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={onEditClose} size="60">
        <ModalOverlay />
        <ModalContent
          p="40px"
          borderRadius="20px"
          border="1px solid #DDDDDD"
          maxW="500px"
        >
          <ModalHeader p={0} mb="20px">
            <Flex w="100%" justify="space-between">
              <Heading as="h2" fontSize="18px" fontWeight="500">
                Edit Milestone
              </Heading>
              <Box cursor="pointer" onClick={onEditClose}>
                <HiX />
              </Box>
            </Flex>
          </ModalHeader>
          <ModalBody maxW="350px" p={0}>
            <MilestoneDetails
              esp={esp}
              listId={listId}
              milestoneId={milestoneId}
              onSuccess={() => {
                onEditClose();
                loadMilestonesForList();
              }}
            />{" "}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

const EmptyCard = ({ createNew }) => {
  return (
    <Flex w="100%" justify="center" align="center">
      <Column
        p="40px"
        spacing="20px"
        align="center"
        bg="white"
        borderRadius="16px"
        w="500px"
      >
        <NewMilestonIllustration />
        <Heading
          as="h2"
          fontWeight="bold"
          fontSize="24px"
          lineHeight="32px"
          color="#4A4F56"
          textTransform="capitalize"
        >
          Create Your First Milestone
        </Heading>
        <Text
          fontWeight="normal"
          fontSize="16px"
          lineHeight="24px"
          color="#4A4F56"
          textAlign="center"
        >
          Offer your subscribers amazing deals by setting milestones. When your
          subscribers reach a specific milestone (referrals number), they get a
          reward.
        </Text>

        <CustomButton p={8} rightIcon={<HiArrowRight />} onClick={createNew}>
          Create A Milestone
        </CustomButton>
      </Column>
    </Flex>
  );
};

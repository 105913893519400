/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import React, { Fragment, useContext } from "react";
import { AuthContext, AppNav, Header } from "components/lib";
import ClassNames from "classnames";
import Style from "./app.module.scss";
import "../layout.scss"; // globals
import { HiChevronDown } from "react-icons/hi";
import {
  Avatar,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
} from "@chakra-ui/react";
import Row from "components/custom/Row";
import { useHistory, useLocation } from "react-router-dom";
import { useAPI } from "components/hooks/api";

export function AppLayout(props) {
  const history = useHistory();
  const location = useLocation();

  const user = useAPI("/api/user");

  // context & style
  const context = useContext(AuthContext);
  const css = ClassNames([Style.app, "with-sidebar"]);

  React.useEffect(() => {
    const route = location.pathname.split("/")[1];

    if (user.data && !user.loading) {
      const hasOnboarded = user?.data?.onboarded;
      const isImporting = user?.data?.is_importing;

      const whitelistRoutes = ["settings", "plans"];
      if (isImporting) {
        whitelistRoutes.push("milestones");
        whitelistRoutes.push("lists");
      }

      const isProtectedRoute = !whitelistRoutes.includes(route);

      if (isProtectedRoute && !hasOnboarded) {
        history.push("/locked?reason=onboarding");
      } else if (isProtectedRoute && isImporting) {
        history.push("/locked?reason=import");
      }
    }
  }, [user]);

  return (
    <Fragment>
      <AppNav
        type="popup"
        items={[
          { label: "Dashboard", icon: "home", link: "/dashboard" },
          { label: "Subscribers", icon: "users", link: "/subscribers" },
          { label: "Milestones", icon: "award", link: "/milestones" },
          { label: "Branding", icon: "zap", link: "/branding" },
          { label: "Settings", icon: "settings", link: "/settings" },
        ]}
      />

      <main className={css}>
        <Header title={props.title}>
          {user.loading ? (
            <Spinner />
          ) : (
            <AvatarDropdownMenu
              src={user?.data?.avatarURL}
              items={[
                {
                  name: "Settings",
                  onClick: (e) => (window.location = "/settings"),
                },
                {
                  name: "Log Out",
                  onClick: context.signout,
                },
              ]}
              firstname={context.user.name}
            />
          )}
          {/* <HoverNav
            avatar="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=70"
            label={context.user.name}
            align="right"
            dark
          >
            <Button
              text="Account Settings"
              action={(e) => (window.location = "/account")}
            />
            <Button text="Signout" action={context.signout} />
          </HoverNav> */}

          {/* <Icon image="settings" size={24} /> */}
        </Header>

        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}

export const AvatarDropdownMenu = ({ src, items, firstname }) => {
  return (
    <Menu zIndex={12}>
      <MenuButton as={Box} cursor="pointer">
        <Row spacing={6} align="center">
          <Avatar src={src} border="4px solid #F1F1F1" size="lg" />
          <Row align="center">
            <Text
              fontWeight="600"
              fontSize="16px"
              lineHeight="24px"
              color="#4A4F56"
            >
              {firstname}
            </Text>
            <HiChevronDown size={16} />
          </Row>
        </Row>
      </MenuButton>

      <MenuList zIndex={12}>
        {items.map((item, index) => (
          <MenuItem onClick={item.onClick} _hover={{ bg: "#f8f8f8" }}>
            <Text
              py={2}
              px={4}
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              color={index === items.length - 1 ? "red.300" : "#4A4F56"}
            >
              {item.name}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

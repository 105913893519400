import React, { useEffect, useState } from "react";
import MilestoneEditor from "../../components/MilestoneEditor";
import { Button, Card, Grid, Header, useAPI } from "../../components/lib";
import RewardEditor from "../../components/RewardEditor";
import update from "immutability-helper";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { LinkButton } from "components/custom/CustomButton";
import Column from "components/custom/Column";
import { Heading, Text } from "@chakra-ui/react";
import Row from "components/custom/Row";
import { HiArrowLeft } from "react-icons/hi";

export default function EmailListMilestones({
  onSuccess,
  esp,
  listId,
  milestoneId,
}) {
  const history = useHistory();

  const milestone = useAPI(`/api/lists/${listId}/milestones/${milestoneId}`);
  // const rewards = useAPI(
  //   `/api/lists/${listId}/milestones/${milestoneId}/rewards`
  // );

  const [editableRewardList, setEditableRewardList] = useState(null);
  // const [rewardMessage, setRewardMessage] = React.useState("");

  // useEffect(() => {
  //   console.log(rewards);
  //   // if (!rewards.loading && rewards.data) {
  //   //   setEditableRewardList(rewards.data);
  //   // }
  //   if (!rewards.loading && rewards.data && rewards.data.length > 0) {
  //     setRewardMessage(rewards.data[0]?.message);
  //   }
  // }, [rewards]);

  if (milestone.loading) {
    return <Card loading={true} />;
  } else if (!milestone.data) {
    return <div>An error occurred</div>;
  }

  async function deleteMilestone() {
    if (window.confirm("Are you sure")) {
      await Axios.delete(`/api/lists/${listId}/milestones/${milestoneId}`);
      // history.replace(`/milestones?esp=${esp}&listId=${listId}`);
      onSuccess();
    }
  }

  function addReward() {
    if (editableRewardList.find((it) => it == null)) {
      return;
    }
    setEditableRewardList(
      update(editableRewardList, {
        $push: [null],
      })
    );
  }

  async function reloadRewardList() {
    const rewards = await Axios.get(
      `/api/lists/${listId}/milestones/${milestoneId}/rewards`
    ).then((response) => response.data.data);
    setEditableRewardList(rewards);
  }

  async function deleteReward(reward, index) {
    if (!reward) {
      return setEditableRewardList(
        update(editableRewardList, { $splice: [[index, 1]] })
      );
    }
    if (window.confirm("Are you sure?")) {
      await Axios.delete(
        `/api/lists/${listId}/milestones/${milestoneId}/rewards/${reward.id}`
      );
      reloadRewardList();
    }
  }

  return (
    <Column spacing="0">
      <MilestoneEditor
        listId={listId}
        milestone={milestone.data}
        // rewardMessage={rewardMessage}
        ctaName="Save Changes"
        onSuccess={onSuccess}
      />
      <LinkButton
        color="red.300"
        _hover={{ color: "red.300" }}
        _focus={{ color: "red.300" }}
        onClick={deleteMilestone}
      >
        Delete
      </LinkButton>
    </Column>
  );
}

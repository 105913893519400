import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  HiArrowRight,
  HiBadgeCheck,
  HiCheckCircle,
  HiChevronDown,
  HiClipboard,
} from "react-icons/hi";
import Axios from "axios";
import EmailListDetails from "../../components/EmailListDetails";
import {
  Flex,
  Spinner,
  Text,
  Box,
  Button as ChakraButton,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  List,
  ListItem,
  ListIcon,
  Image,
  Input,
  Wrap,
  WrapItem,
  AspectRatio,
  Center,
} from "@chakra-ui/react";
import { Chart, Card, Grid, useAPI, Icon, TextInput } from "components/lib";
import Column from "components/custom/Column";
import Row from "components/custom/Row";
import CustomButton from "components/custom/CustomButton";
import copyToClipboard from "utils/copyToClipboard";
import toast from "react-hot-toast";
import {
  FBLogo,
  LinkedInLogo,
  MessengerLogo,
  TwitterLogo,
} from "components/icons/SocialMedia";
import SocialShare from "components/custom/SocialShare";

export function Preview({ match, isPreview = false }) {
  const listId = match.params.listId;
  const esp = match.params.esp || "convertkit";

  const [isVerified, setIsVerified] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [brandColor, setBrandColor] = React.useState("#111111");
  const [refLink, setRefLink] = React.useState("");
  const [totalReferrals, setTotalReferrals] = React.useState(0);
  const [refUntilReward, setRefUntilReward] = React.useState(0);
  const [milestones, setMilestones] = React.useState([]);
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    if (!listId) return;

    // Fetch milestones
    const url = `/api/lists/${listId}/milestones/public`;

    Axios.get(url)
      .then((response) => response.data.data)
      .then((data) => {
        // const newMilestones = data.map((milestone) => ({
        //   imageURL: milestone.imageURL,
        //   referrals: milestone.referralCount,
        //   title: milestone.name,
        //   description: milestone.message,
        // }));
        setMilestones(data);
      });

    // TODO: Fetch logo & brand color
    const brandURL = `/api/lists/${listId}/brand/public`;

    Axios.get(brandURL)
      .then((res) => {
        return res.data.data;
      })
      .then((data) => {
        setBrandColor(data.brandColor);
        setLogo(data.logoURL);
        setIsReady(true);
      })
      .catch((err) => {
        setIsReady(true);
      });
  }, [listId]);

  function verify() {
    setIsLoading(true);
    Axios.get(`/api/verify/subscribers/${esp}/${listId}/${email}`)
      .then((response) => {
        const data = response.data.data;
        const { isValid, id } = data;
        setIsVerified(isValid);
        setRefLink(data.refLink);
        setRefUntilReward(data.refUntilReward);
        setTotalReferrals(data.totalReferrals);
        setIsLoading(false);

        if (isValid) {
          toast.success("You're verified!");

          //  TODO: Save email to URL query parameter
          // const url = new URL(window.location.href);
          // url.searchParams.set("email", email);
          // window.history.replaceState(null, null, url);
        } else {
          toast.error("You're not subscribed to this newsletter");
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  function copy() {
    copyToClipboard(refLink);
    toast.success("You referral link is copied!");
  }

  if (!isReady) {
    return <Spinner />;
  }

  return (
    <div>
      {isPreview && (
        <Center
          position="fixed"
          top="2rem"
          right="2rem"
          // w="200px"
          bg={brandColor}
          color="white"
          bg="#E8EEFF"
          color="#013BDA"
          borderRadius="1000px"
          fontWeight="600"
          fontSize="10px"
          px={6}
          py={4}
        >
          PREVIEW MODE
        </Center>
      )}
      <Column spacing="60px" align="center">
        <Column spacing="40px" align="center">
          <Image src={logo} maxW="150px" maxW="100px" />
          {isVerified ? (
            <>
              <Row shouldWrapComponent={false} align="center">
                <Input
                  value={refLink}
                  minW="300px"
                  p="10px 16px"
                  fontSize="14px"
                  height="40px"
                  bg="#F7F7F8"
                  border="1px solid rgba(74, 79, 86, 0.5)"
                  borderRadius="4px"
                  isReadOnly
                />

                <CustomButton
                  h="40px"
                  maxH="40px"
                  p="0 16px !important"
                  onClick={copy}
                  fontWeight="600"
                  isFullWidth="false"
                  background={brandColor}
                  _hover={{
                    background: brandColor,
                  }}
                  _focus={{
                    background: brandColor,
                  }}
                >
                  Copy
                </CustomButton>
              </Row>

              <SocialShare
                brandColor={brandColor}
                canonicalUrl={refLink}
                description="Best referral tool for newsletters"
                title="ReferralKit"
              />

              <Column spacing="10px" align="center">
                <Text>
                  You have{" "}
                  <Box display="inline" color={brandColor} fontWeight="600">
                    {totalReferrals} referral{totalReferrals > 1 ? "s" : ""}
                  </Box>
                  .
                </Text>
                <Text>
                  You’re only{" "}
                  <Box display="inline" color={brandColor} fontWeight="600">
                    {refUntilReward} referral{refUntilReward > 1 ? "s" : ""}
                  </Box>{" "}
                  away from your next reward.
                </Text>
              </Column>
            </>
          ) : (
            <Row shouldWrapComponent={false} align="center">
              <Input
                placeholder="john@example.com"
                value={email}
                type="email"
                minW="300px"
                p="10px 16px"
                fontSize="14px"
                height="40px"
                bg="#F7F7F8"
                border="1px solid rgba(74, 79, 86, 0.5)"
                borderRadius="4px"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <CustomButton
                isLoading={isLoading}
                h="40px"
                maxH="40px"
                p="0 16px !important"
                onClick={verify}
                fontWeight="600"
                isFullWidth="false"
                background={brandColor}
                _hover={{
                  background: brandColor,
                }}
                _focus={{
                  background: brandColor,
                }}
              >
                Verify
              </CustomButton>
            </Row>
          )}
        </Column>

        <Wrap spacing="24px">
          {milestones.map((milestone) => (
            <WrapItem>
              <RewardCard {...milestone} brandColor={brandColor} />
            </WrapItem>
          ))}
        </Wrap>
      </Column>
    </div>
  );
}

const RewardCard = ({
  imageURL,
  title,
  description,
  referrals,
  brandColor,
}) => {
  return (
    <Column maxW="200px" spacing="1rem">
      <AspectRatio ratio={3 / 2} w="100%">
        <Image
          src={imageURL}
          borderRadius="8px"
          fallbackSrc="https://referralkit.s3.us-east-2.amazonaws.com/placeholder.jpg"
        />
      </AspectRatio>
      <Text
        fontSize="12px"
        fontWeight="600"
        color={brandColor}
        textTransform="uppercase"
      >
        {referrals} referrals
      </Text>
      <Heading
        as="h2"
        fontSize="20px"
        color="#4A4F56"
        textTransform="capitalize"
      >
        {title}
      </Heading>
      <Text fontSize="14px" color="#4A4F56" fontWeight="500">
        {description}
      </Text>
    </Column>
  );
};

// const SocialShareCustom = ({ color }) => {
//   return (
//     <Row align="center" spacing={4}>
//       <FBLogo color={color} />
//       <MessengerLogo color={color} />
//       <TwitterLogo color={color} />
//       <LinkedInLogo color={color} />
//     </Row>
//   );
// };

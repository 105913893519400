import React from "react";
import MilestoneEditor from "../../components/MilestoneEditor";
import { Link, useHistory } from "react-router-dom";
import Column from "components/custom/Column";
import Row from "components/custom/Row";
import { HiArrowLeft } from "react-icons/hi";
import { Center, Heading, Text } from "@chakra-ui/react";

export default function NewMilestone({
  computedMatch: {
    params: { esp, listId },
  },
}) {
  const history = useHistory();

  function onNewMilestoneSuccess(milestone) {
    history.replace(`/milestones?esp=${esp}&listId=${listId}`);
  }

  return (
    <Center w="100%">
      <Column spacing="20px" w="100%" align="center">
        <Row w="100%">
          <Link to="/milestones">
            <Row align="center" color="gray.900" spacing={3}>
              <HiArrowLeft size={14} />
              <Text fontSize="14px">Back</Text>
            </Row>
          </Link>
        </Row>
        <Column
          bg="white"
          borderRadius="16px"
          p="40px"
          pb="20px"
          spacing="40px"
          maxW="380px"
        >
          <Heading as="h2" fontSize="18px" fontWeight="500">
            Create New Milestone
          </Heading>

          <MilestoneEditor
            listId={listId}
            onSuccess={onNewMilestoneSuccess}
            ctaName="Create The Milestone"
          />
        </Column>
      </Column>
    </Center>
  );
}

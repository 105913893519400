import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { AccountNav } from "../nav/sub/account";

const SettingsCard = ({ children }) => {
  return (
    <Flex bg="white" borderRadius="16px" minH="70vh">
      <AccountNav />

      <Box p="40px" w="100%">
        {children}
      </Box>
    </Flex>
  );
};

export default SettingsCard;

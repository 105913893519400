import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  Spinner,
  Heading,
  Text,
  Flex,
  Box,
  Menu,
  Button as ChakraButton,
  Portal,
  MenuList,
  MenuButton,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
} from "@chakra-ui/react";
import { useAPI } from "components/lib";

import Row from "components/custom/Row";
import { HiChevronDown } from "react-icons/hi";
import ConvertKit from "components/icons/ConvertKit";
import Mailerlite from "components/icons/Mailerlite";
import MailChimp from "components/icons/MailChimp";
import { useHistory } from "react-router-dom";
import Aweber from "components/icons/Aweber";

export function ListDropdown({ location, currentPath, onSelect = () => {} }) {
  const history = useHistory();

  const mailchimpAudiences = useAPI("/api/mailchimp/lists");
  const mailerliteLists = useAPI("/api/mailerlite/lists");
  const convertkitLists = useAPI("/api/convertkit/lists");
  const aweberAudiences = useAPI("/api/aweber/lists");

  const [esp, setEsp] = React.useState();
  const [mailchimpName, setMailchimpName] = React.useState();
  const [aweberName, setAweberName] = React.useState();

  function onListSelected(espListId) {
    const [esp, listId] = espListId.split("_");
    history.replace(`${currentPath}?esp=${esp}&listId=${listId}`);
    onSelect({ esp, listId });
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryEsp = query.get("esp");
    const queryListId = query.get("listId");

    if (!queryEsp || !queryListId) {
      if (mailchimpAudiences.data && mailchimpAudiences.data.length > 0) {
        setMailchimpName(mailchimpAudiences.data[0].name);
        return onListSelected(`mailchimp_${mailchimpAudiences.data[0].id}`);
      } else if (aweberAudiences.data && aweberAudiences.data.length > 0) {
        setAweberName(aweberAudiences.data[0].name);
        return onListSelected(`aweber_${aweberAudiences.data[0].id}`);
      } else if (mailerliteLists.data) {
        return onListSelected(`mailerlite_${mailerliteLists.data[0].id}`);
      } else if (convertkitLists.data) {
        return onListSelected(`convertkit_${convertkitLists.data[0].id}`);
      }
    }

    setEsp(queryEsp);
  }, [
    location,
    mailchimpAudiences,
    mailerliteLists,
    convertkitLists,
    aweberAudiences,
  ]);

  const isListsLoading = React.useMemo(
    () =>
      mailchimpAudiences?.loading ||
      aweberAudiences?.loading ||
      mailerliteLists?.loading ||
      convertkitLists?.loading,
    [aweberAudiences, mailchimpAudiences, mailerliteLists, convertkitLists]
  );

  const hasLoadLists =
    Array.isArray(mailchimpAudiences?.data) ||
    Array.isArray(aweberAudiences?.data) ||
    Array.isArray(convertkitLists?.data) ||
    Array.isArray(mailerliteLists?.data);

  if (isListsLoading || !hasLoadLists) {
    return <Spinner />;
  }

  return (
    <Menu>
      <MenuButton
        as={ChakraButton}
        rightIcon={<HiChevronDown size={18} />}
        p="10px 16px"
        borderRadius="8px"
        bg="white"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
        minH="40px"
        _hover={{ bg: "white" }}
        _expanded={{ bg: "#f8f8f8" }}
        _focus={{ bg: "white" }}
        isDisabled={esp === null}
        zIndex={10}
      >
        <Row align="center" shouldWrapChildren>
          <Box w="24px" h="24px">
            {esp === "convertkit" ? (
              <ConvertKit />
            ) : esp === "mailerlite" ? (
              <Mailerlite />
            ) : esp === "aweber" ? (
              <Aweber />
            ) : esp === "mailchimp" ? (
              <MailChimp />
            ) : null}
          </Box>
          <Text
            fontfamily="Inter"
            fontWeight="600"
            fontSize="14px"
            lineHeight="20px"
            color="#4A4F56"
            textTransform="capitalize"
          >
            {esp === "mailchimp"
              ? mailchimpName
              : esp === "aweber"
              ? aweberName
              : esp}
            {esp === null && "Please, Connect A Newsletter"}
          </Text>
        </Row>
      </MenuButton>

      <Portal>
        <MenuList>
          {mailerliteLists?.data?.length > 0 && (
            <MenuItem
              onClick={() =>
                onListSelected(`mailerlite_${mailerliteLists?.data[0]?.id}`)
              }
            >
              <Row p={2} spacing={4}>
                <Mailerlite />
                <Text>MailerLite</Text>
              </Row>
            </MenuItem>
          )}
          {convertkitLists?.data?.length > 0 && (
            <MenuItem
              onClick={(event) =>
                onListSelected(`convertkit_${convertkitLists?.data[0]?.id}`)
              }
            >
              <Row p={2} spacing={4}>
                <ConvertKit />
                <Text>Convertkit</Text>
              </Row>
            </MenuItem>
          )}

          {aweberAudiences?.data?.length > 0 && (
            <MenuOptionGroup defaultValue="asc" title="Aweber" type="radio">
              {aweberAudiences.data.map((audience) => (
                <MenuItemOption
                  key={audience.id}
                  onClick={() => {
                    onListSelected(`aweber_${audience.id}`);
                    setAweberName(audience.name);
                  }}
                >
                  <Row p={2} spacing={4}>
                    <Aweber />
                    <Text>{audience.name}</Text>
                  </Row>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          )}

          {mailchimpAudiences?.data?.length > 0 && (
            <MenuOptionGroup defaultValue="asc" title="MailChimp" type="radio">
              {mailchimpAudiences.data.map((audience) => (
                <MenuItemOption
                  key={audience.id}
                  onClick={() => {
                    onListSelected(`mailchimp_${audience.id}`);
                    setMailchimpName(audience.name);
                  }}
                >
                  <Row p={2} spacing={4}>
                    <MailChimp />
                    <Text>{audience.name}</Text>
                  </Row>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
}

import React, { useState } from "react";
import { useAPI } from "../../components/hooks/api";
import { useHistory } from "react-router-dom";

import { Form } from "../../components/lib";
import useLocalStorage from "hooks/useLocalStorage";

export default function ConvertKitConfiguration() {
  const [_, setStep] = useLocalStorage("onboarding-step");

  const history = useHistory();
  const form = {
    apiKey: {
      label: "API Key",
      required: true,
      placeholder: "12345",
    },
  };

  function onFinish() {
    setStep(3);
    if (window.location.pathname.includes("integrate")) {
      history.push("/integrate?esp=convertkit#3");
    } else if (window.location.pathname.includes("onboarding")) {
      history.push("/onboarding?esp=convertkit#3");
    }
  }

  return (
    <div>
      <Form
        method="POST"
        url="/api/convertkit/settings"
        data={form}
        buttonText="Save settings"
        callback={onFinish}
      />
    </div>
  );
}

import React from "react";
import { Animate, Button, Chart, Stat, Table, useAPI } from "./lib";
import { Header } from "./header/header";
import { Card } from "./card/card";
import { LinkButton } from "./custom/CustomButton";
import { Link } from "react-router-dom";
import Column from "./custom/Column";
import { Grid, GridItem, Heading, Wrap, WrapItem } from "@chakra-ui/react";
import { HiArrowRight } from "react-icons/hi";

function mostReferralsFirst(a, b) {
  if (a.referrals > b.referrals) {
    return -1;
  }
  if (a.referrals < b.referrals) {
    return 1;
  }
  return 0;
}

export default function EmailListDetails({
  emailList,
  esp,
  listId,
  dateRange,
}) {
  const table = {
    body: emailList.stats.leaderboard
      .map(({ email, referralCount }) => ({
        email,
        referrals: referralCount,
      }))
      .sort(mostReferralsFirst)
      .slice(0, 10),
  };

  // const table = {
  //   body: emailList.members
  //     .map(({ email, referralCount }) => ({
  //       email,
  //       referrals: Number(referralCount),
  //     }))
  //     .sort(mostReferralsFirst)
  //     .slice(0, 10),
  // };

  const subsChange =
    emailList.stats.subscriberCountChange !== undefined &&
    emailList.stats.subscriberCountChange !== null
      ? `${emailList.stats.subscriberCountChange}%`
      : null;
  const referralsChange =
    emailList.stats.referralCountChange !== undefined &&
    emailList.stats.referralCountChange !== null
      ? `${emailList.stats.referralCountChange}%`
      : null;
  const refVsSubsChange =
    emailList.stats.refSubPercentChange !== undefined &&
    emailList.stats.refSubPercentChange !== null
      ? `${emailList.stats.refSubPercentChange.toFixed(2)}%`
      : null;
  const refVsSubs =
    emailList.stats.refSubPercent !== undefined &&
    emailList.stats.refSubPercent !== null
      ? `${100 * emailList.stats.refSubPercent.toFixed(2)}%`
      : null;

  return (
    <Column spacing="20px" mt="20px">
      <Grid
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(3, 1fr)",
          "repeat(3, 1fr)",
        ]}
        templateRows={[
          "repeat(3, 1fr)",
          "repeat(3, 1fr)",
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
        ]}
        gap="16px"
      >
        <Stat
          value={emailList.stats.subscriberCount}
          label="Subscribers"
          icon="users"
          change={subsChange}
        />
        <Stat
          value={emailList.stats.referralCount}
          label="Referrals"
          icon="share-2"
          change={referralsChange}
        />

        <Stat
          value={refVsSubs}
          label="Referrals vs Total Subscribers"
          icon="calendar"
          change={refVsSubsChange}
        />
      </Grid>

      <Column spacing="40px" p="40px" borderRadius="16px" bg="white">
        <Heading fontWeight="600" fontSize="16px" lineHeight="24px">
          Referrals
        </Heading>
        <Chart
          type="bar"
          legend={true}
          loading={emailList.stats.loading}
          data={emailList.stats.refsLineChart}
          color={["blue", "green"]}
        />
      </Column>

      <Grid
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(3, 1fr)",
          "repeat(3, 1fr)",
        ]}
        templateRows={[
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
        ]}
        gap="16px"
      >
        <GridItem colSpan={[1, 1, 2, 2]}>
          <Column spacing="20px" p="40px" borderRadius="16px" bg="white">
            <Heading fontWeight="600" fontSize="16px" lineHeight="24px">
              Leaderboard
            </Heading>
            <Table data={table.body} />

            <Link to={`/subscribers?esp=${esp}&listId=${listId}`}>
              <LinkButton rightIcon={<HiArrowRight />} fontSize="14px" p="0">
                View All Subscribers
              </LinkButton>
            </Link>
          </Column>
        </GridItem>
        <GridItem colSpan={1}>
          <Column spacing="40px" p="40px" borderRadius="16px" bg="white">
            <Heading fontWeight="600" fontSize="16px" lineHeight="24px">
              Subscriber Types
            </Heading>
            <Chart
              type="pie"
              legend={true}
              loading={emailList.stats.loading}
              data={emailList.stats.pieChart}
              color={["blue", "green"]}
            />
          </Column>
        </GridItem>
      </Grid>
    </Column>
  );
}

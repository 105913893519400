import React from "react";
import { Preview } from "views/public/preview";

export default [
  {
    path: "/refer-a-friend/preview/:esp/:listId",
    view: (props) => <Preview {...props} isPreview />,
    layout: "public",
    title: "Preview",
  },
  {
    path: "/refer-a-friend/:esp/:listId",
    view: Preview,
    layout: "public",
    title: "Preview",
  },
  // {
  //   path: "/public/:listId/:subscriberId",
  //   view: MilestoneDetails,
  //   layout: "preview",
  //   title: "Public Page",
  // },
];

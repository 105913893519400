import { Logo } from "components/logo/logo";
import { Stack } from "@chakra-ui/react";
import React from "react";

const LandingPageCard = ({ children, hasTerms }) => {
  return (
    <Stack maxW="900px" pt="64px" align="center" m="0 auto" spacing="32px">
      <Stack
        w="100%"
        bg="white"
        borderRadius="1rem"
        border="1px solid rgba(74, 79, 86, 0.2)"
        p="40px"
        maxW="900px"
        align="center"
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default LandingPageCard;

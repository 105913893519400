/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

import React from "react";
import { HomeNav, Footer } from "components/lib";
import Style from "./auth.module.scss";
import AuthCard from "components/custom/AuthCard";

export function AuthLayout(props) {
  const hasTerms = props?.data?.location?.pathname === "/signup";

  return (
    <main className={Style.auth}>
      <AuthCard hasTerms={hasTerms}>
        {<props.children {...props.data} />}
      </AuthCard>
    </main>
  );
}

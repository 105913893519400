import React from 'react'
import {Content, Form} from "../../components/lib";

export default function Referral({location}) {
    const query = new URLSearchParams(location.search)
    const refId = query.get("ref_id")

    const list = query.get("list")

    const formData = {
        email: {
            label: "Email",
            required: true,
            type: 'email',
            placeholder: "foo@bar.email",
        },
        list: {
            type: "text",
            hidden: true,
            value: list
        },
        refId: {
            type: "text",
            hidden: true,
            value: refId
        }

    }

    function onSuccess(data) {
        alert("You subscribed to the list!")
    }

    return <Content>
        <p>Enter your email below to subscribe</p>
        <Form callback={onSuccess}
              url={`/api/referral`}
              method="POST"
              data={formData}
              buttonText="Subscribe">

        </Form>
    </Content>
}

import React from "react";
import Moment from "react-moment";
import { Button, Badge } from "components/lib";
import Style from "./table.module.scss";

export function Body(props) {
  if (props.data?.length) {
    return (
      <tbody>
        {props.data.map((row, index) => {
          return (
            <Row
              key={index}
              data={row}
              show={props.show}
              hide={props.hide}
              badge={props.badge}
              actions={props.actions}
            />
          );
        })}
      </tbody>
    );
  }

  return (
    <tbody>
      <tr>
        <td colSpan="10">No results found</td>
      </tr>
    </tbody>
  );
}

export function Row(props) {
  let row = { ...props.data };
  row.actions = props.actions;
  const hasActions = Object.values(row.actions).some((x) => x !== undefined);

  return (
    <tr data-id={props.data.id}>
      {Object.keys(row).map((cell, index) => {
        // actions
        if (cell === "actions") {
          if (hasActions) {
            return (
              <td key={index} className={Style.actions}>
                {row.actions?.custom?.map((action, i) => {
                  return (
                    <Button
                      key={i}
                      icon={action.icon}
                      action={() => action.action(row)}
                    />
                  );
                })}
                {row.actions.edit && (
                  <Button icon="edit" action={() => row.actions.edit(row)} />
                )}

                {row.actions.delete && (
                  <Button icon="trash" action={() => row.actions.delete(row)} />
                )}

                {row.actions.email && (
                  <Button
                    icon="mail"
                    action={() => (window.location = `mailto:${row.email}`)}
                  />
                )}
              </td>
            );
          } else return false;
        }

        // hide
        if (props.hide?.includes(cell)) return false;

        // show
        if (props.show && !props.show.includes(cell)) return false;

        let value = row[cell];

        // is date
        if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value))
          value = <Moment date={value} format="YYYY-MM-DD" />;

        // has badge
        if (props.badge && cell === props.badge.col && value) {
          return (
            <td key={index}>
              <Badge text={value} color={props.badge.color} />
            </td>
          );
        }

        // standard cell
        return (
          <td key={index}>
            {value === true ? "Yes" : value === false ? "No" : value}
          </td>
        );
      })}
    </tr>
  );
}

import React from "react";

function NewMilestonIllustration({ size, color }) {
  return (
    <svg
      width="277"
      height="207"
      viewBox="0 0 277 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M225.349 179.107L244.086 129.146C244.845 127.122 244.888 124.899 244.208 122.848L217.143 41.1947C215.92 37.505 212.565 34.9382 208.683 34.726L81.1092 27.6983C78.0439 27.5293 75.0855 28.8593 73.1777 31.2639L34.3617 80.1901C32.3297 82.7517 31.7639 86.1827 32.8679 89.2619L65.0702 179.107H225.349Z"
        fill="#F4F4F4"
      />
      <path
        d="M126.517 57.6443C126.517 57.6443 115.841 68.9379 108.002 76.2536C102.477 81.4131 85.61 96.9191 85.61 96.9191C85.61 96.9191 98.9322 105.234 104.252 108.328C108.392 110.736 123.525 119.85 123.525 119.85C123.525 119.85 147.417 108.397 155.816 103.916C160.451 101.442 174.939 92.2582 174.939 92.2582C174.939 92.2582 153.283 78.5617 146.929 74.0473C141.621 70.2782 126.517 57.6443 126.517 57.6443Z"
        fill="#D9DCDE"
      />
      <path
        d="M85.61 96.9191C85.61 96.9191 102.475 81.4131 108.002 76.2536C115.839 68.9379 126.517 57.6443 126.517 57.6443V71.7238L121.925 72.1723L129.39 81.1285L115.294 80.83L118.225 86.9486L94.6921 103.919L85.61 96.9191Z"
        fill="white"
      />
      <path
        d="M132.394 120.155L124.386 119.829L124.607 118.697L126.001 119.111L124.723 118.107L124.843 117.497L126.539 118.414L124.964 116.879L125.618 113.548L127.145 114.468L125.718 113.032L126.627 108.404L128.019 109.263L126.756 107.74L127.122 105.875H128.019L127.231 105.327V105.325L127.495 103.98L128.716 104.53L127.586 103.512L128.364 99.5498L129.882 107.292L128.716 107.975L130.061 108.202L130.194 108.879L129.434 108.813L130.301 109.431L130.748 111.706L129.344 112.506L130.958 112.774L131.379 114.926L129.434 115.88L131.483 115.454L131.559 115.837L130.049 117.024L131.635 116.227L131.773 116.926L129.725 118.056L132.057 118.376L132.394 120.155Z"
        fill="#4A4F56"
      />
      <path
        d="M149.726 111.775L141.719 111.449L141.94 110.317L143.333 110.731L142.055 109.729L142.176 109.117L143.872 110.036L142.297 108.499L142.951 105.168L144.477 106.088L143.051 104.652L143.96 100.026L145.352 100.883L144.089 99.36L144.455 97.4953H145.352L144.563 96.9467L144.827 95.5995L146.049 96.1498L144.919 95.132L145.697 91.1697L147.215 98.9115L146.049 99.5944L147.394 99.8223L147.527 100.498L146.766 100.435L147.634 101.05L148.081 103.326L146.677 104.126L148.291 104.394L148.712 106.546L146.766 107.5L148.816 107.074L148.891 107.457L147.382 108.644L148.967 107.847L149.105 108.546L147.058 109.677L149.39 109.996L149.726 111.775Z"
        fill="#4A4F56"
      />
      <path
        d="M193.703 81.9685C193.703 81.9685 171.002 95.7858 162.904 100.205C153.195 105.503 122.392 120.398 122.392 120.398L167.133 150.749L243.021 131.98L244.084 129.146C244.843 127.122 244.886 124.899 244.206 122.848L239.257 107.916C239.257 107.916 218.067 97.3883 212.566 94.4489C201.821 88.7064 193.703 81.9685 193.703 81.9685Z"
        fill="#D9DCDE"
      />
      <path
        opacity="0.2"
        d="M193.703 81.9685C193.703 81.9685 171.002 95.7858 162.904 100.205C153.195 105.503 122.392 120.398 122.392 120.398L167.133 150.749L243.021 131.98L244.084 129.146C244.843 127.122 244.886 124.899 244.206 122.848L239.257 107.916C239.257 107.916 218.067 97.3883 212.566 94.4489C201.821 88.7064 193.703 81.9685 193.703 81.9685Z"
        fill="#4A4F56"
      />
      <path
        d="M122.392 120.398C122.392 120.398 153.197 105.503 162.904 100.205C171.002 95.7858 193.703 81.9685 193.703 81.9685L187.438 101.471L181.356 102.598L179.546 115.44L154.605 114.457L161.806 121.192L125.442 124.662L122.392 120.398Z"
        fill="white"
      />
      <path
        d="M38.3312 104.509C38.3312 104.509 45.4123 99.7291 52.0846 95.3804C60.5751 89.8449 67.1214 84.4405 67.1214 84.4405C67.1214 84.4405 79.7398 93.0982 83.9402 95.8324C102.725 108.063 158.736 140.418 157.118 139.465L44.2686 121.035L38.3312 104.509Z"
        fill="#858990"
      />
      <path
        d="M38.3312 104.509C38.3312 104.509 45.4123 99.7291 52.0846 95.3804C60.5751 89.8449 67.1214 84.4405 67.1214 84.4405L69.997 96.4879L59.8057 100.171L67.6113 104.509L43.3992 118.62L38.3312 104.509Z"
        fill="white"
      />
      <path
        d="M42.8695 117.169C42.8695 117.169 56.0951 116.801 90.8797 125.43C107.464 129.544 120.005 132.73 133.968 135.176C149.295 137.86 166.007 139.665 183.824 141.088L159.511 154.838L53.9457 148.074L42.8695 117.169Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.2"
        d="M42.8695 117.169C42.8695 117.169 56.0951 116.801 90.8797 125.43C107.464 129.544 119.297 132.608 133.261 135.052C148.588 137.736 166.007 139.663 183.824 141.086L159.511 154.836L53.9457 148.072L42.8695 117.169Z"
        fill="white"
      />
      <path
        d="M67.1196 144.862L57.1715 144.458L57.4475 143.053L59.1777 143.567L57.5907 142.321L57.739 141.56L59.847 142.702L57.8908 140.793L58.7016 136.656L60.5991 137.798L58.8275 136.016L59.9557 130.267L61.6858 131.333L60.1178 129.441L60.5715 127.124H61.6858L60.706 126.443L61.0338 124.769L62.5501 125.452L61.1476 124.188L62.1136 119.267L63.9991 128.883L62.5501 129.732L64.2216 130.017L64.3872 130.855L63.4419 130.774L64.5217 131.54L65.0755 134.367L63.3298 135.362L65.3359 135.694L65.8603 138.367L63.4419 139.553L65.9897 139.025L66.0829 139.499L64.2078 140.974L66.1777 139.984L66.3468 140.851L63.8041 142.257L66.7004 142.654L67.1196 144.862Z"
        fill="#4A4F56"
      />
      <path
        d="M99.6668 147.199L89.717 146.796L89.993 145.39L91.7232 145.904L90.1362 144.659L90.2863 143.898L92.3925 145.04L90.4364 143.13L91.2471 138.994L93.1446 140.136L91.373 138.354L92.5012 132.604L94.2314 133.67L92.6633 131.778L93.117 129.461H94.2314L93.2516 128.78L93.5793 127.107L95.0956 127.79L93.6932 126.525L94.6592 121.604L96.5463 131.221L95.0956 132.069L96.7688 132.354L96.9327 133.192L95.9874 133.111L97.0673 133.877L97.621 136.705L95.877 137.7L97.8815 138.031L98.4059 140.705L95.9874 141.89L98.5352 141.362L98.6284 141.836L96.7533 143.311L98.7233 142.321L98.894 143.189L96.3497 144.595L99.2477 144.991L99.6668 147.199Z"
        fill="#4A4F56"
      />
      <path
        d="M86.3999 145.412L70.2711 144.755L70.7179 142.478L73.5245 143.31L70.9508 141.291L71.1923 140.06L74.6078 141.909L71.4355 138.814L72.7517 132.109L75.8273 133.958L72.9552 131.071L74.7837 121.75L77.5886 123.477L75.0459 120.412L75.7825 116.655H77.5886L75.9998 115.551V115.549L76.5329 112.837L78.9893 113.945L76.7174 111.896L78.282 103.917L81.3404 119.506L78.9893 120.883L81.701 121.343L81.9666 122.704L80.4365 122.573L82.1857 123.814L83.0844 128.399L80.2554 130.01L83.5053 130.548L84.3557 134.883L80.4365 136.805L84.5645 135.947L84.7163 136.717L81.6785 139.109L84.8698 137.501L85.1458 138.909L81.023 141.186L85.7185 141.831L86.3999 145.412Z"
        fill="#4A4F56"
      />
      <path
        d="M52.4727 143.999C52.4727 143.999 55.9485 140.613 61.5789 140.618C73.1606 140.632 104.683 150.046 121.331 149.518C143.297 148.822 172.548 148.745 205.049 132.378C225.906 121.876 244.534 127.512 244.534 127.512C244.534 127.512 244.374 128.252 244.301 128.493C244.034 129.382 243.021 131.98 243.021 131.98L225.349 179.107H65.0703L52.4727 143.999Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.7"
        d="M52.4727 143.999C52.4727 143.999 55.9485 140.613 61.5789 140.618C73.1606 140.632 104.683 150.045 121.331 149.518C143.297 148.822 172.548 148.745 205.049 132.378C224.835 122.416 242.747 127.013 244.534 127.512C244.534 127.512 244.391 128.186 244.325 128.405C244.054 129.318 243.019 131.98 243.019 131.98L225.347 179.107H65.0703L52.4727 143.999Z"
        fill="white"
      />
      <path
        d="M110.567 172.072C110.567 172.072 100.935 145.509 104.543 144.148C108.326 142.721 119.397 172.072 119.397 172.072H110.567Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.5"
        d="M110.567 172.072C110.567 172.072 100.935 145.509 104.543 144.148C108.326 142.721 119.397 172.072 119.397 172.072H110.567Z"
        fill="white"
      />
      <path
        d="M119.618 172.072C119.618 172.072 126.035 140.869 122.425 139.508C118.642 138.081 110.788 172.072 110.788 172.072H119.618Z"
        fill="#4A4F56"
      />
      <path
        d="M162.407 179.108L149.733 155.761C148.917 154.258 147.306 153.361 145.6 153.459L127.312 154.517C125.671 154.612 124.221 155.609 123.544 157.106C122.901 158.529 121.554 159.507 120.003 159.676L118.005 159.895C116.346 160.077 114.931 161.179 114.35 162.743L108.257 179.107L162.407 179.108Z"
        fill="#AFB3B8"
      />
      <path
        d="M143.794 179.317L144.727 174.187C145.133 171.958 147.091 170.347 149.355 170.378L171.582 170.506C173.121 170.527 174.875 171.487 175.722 172.773L180.028 179.315H143.794V179.317Z"
        fill="#4A4F56"
      />
      <path
        d="M93.2861 63.8041C99.0566 63.8041 103.734 59.1263 103.734 53.3558C103.734 47.5853 99.0566 42.9075 93.2861 42.9075C87.5156 42.9075 82.8378 47.5853 82.8378 53.3558C82.8378 59.1263 87.5156 63.8041 93.2861 63.8041Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.5"
        d="M93.2861 63.8041C99.0566 63.8041 103.734 59.1263 103.734 53.3558C103.734 47.5853 99.0566 42.9075 93.2861 42.9075C87.5156 42.9075 82.8378 47.5853 82.8378 53.3558C82.8378 59.1263 87.5156 63.8041 93.2861 63.8041Z"
        fill="white"
      />
      <path
        d="M165.21 179.291C166.138 178.405 167.106 177.551 168.113 176.735C171.665 173.854 175.689 171.434 180.133 169.602C196.201 162.981 213.788 165.691 226.875 175.225L225.349 179.298L165.21 179.291Z"
        fill="#AFB3B8"
      />
      <path
        d="M125.495 179.105C115.711 158.779 91.6388 149.38 70.5145 158.084C66.5539 159.716 62.9693 161.872 59.8057 164.439L65.0686 179.108L125.495 179.105Z"
        fill="#4A4F56"
      />
      <path
        d="M57.6339 154.989C60.1351 154.006 62.8003 153.344 65.5741 153.057C66.1606 152.997 66.6936 153.402 66.7954 153.982L69.6071 169.949L72.3171 149.864C72.3964 149.281 72.9122 148.855 73.4987 148.891C76.2829 149.064 78.9704 149.595 81.5131 150.442C82.0789 150.63 82.3945 151.231 82.2376 151.805L81.206 155.572L78.2752 166.223L86.8813 158.926L90.2053 156.081C90.6211 155.726 91.2334 155.721 91.6543 156.069C94.592 158.498 97.0829 161.448 98.9856 164.775C99.2668 165.269 99.1357 165.895 98.682 166.235L95.3683 168.715L83.2174 176.726L97.0777 172.424L100.59 171.303C101.195 171.109 101.845 171.454 102.013 172.067C102.539 173.987 102.889 175.978 103.043 178.023C103.091 178.674 102.57 179.224 101.918 179.222L65.0773 179.129L56.9922 156.412C56.7886 155.841 57.0715 155.21 57.6339 154.989Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.3"
        d="M57.6339 154.989C60.1351 154.006 62.8003 153.344 65.5741 153.057C66.1606 152.997 66.6936 153.402 66.7954 153.982L69.6071 169.949L72.3171 149.864C72.3964 149.281 72.9122 148.855 73.4987 148.891C76.2829 149.064 78.9704 149.595 81.5131 150.442C82.0789 150.63 82.3945 151.231 82.2376 151.805L81.206 155.572L78.2752 166.223L86.8813 158.926L90.2053 156.081C90.6211 155.726 91.2334 155.721 91.6543 156.069C94.592 158.498 97.0829 161.448 98.9856 164.775C99.2668 165.269 99.1357 165.895 98.682 166.235L95.3683 168.715L83.2174 176.726L97.0777 172.424L100.598 171.308C101.204 171.115 101.854 171.46 102.022 172.072C102.548 173.992 102.888 175.976 103.043 178.023C103.091 178.674 102.57 179.224 101.918 179.222L65.0773 179.129L56.9922 156.412C56.7886 155.841 57.0715 155.21 57.6339 154.989Z"
        fill="white"
      />
      <path
        d="M215.114 168.495L205.27 154.324C204.435 153.123 204.711 151.474 205.891 150.61C207.184 149.661 209.015 150.046 209.818 151.434L217.515 164.729L215.114 168.495Z"
        fill="white"
      />
      <path
        d="M225.523 157.058V142.233C225.523 141.236 226.332 140.427 227.329 140.427C228.374 140.427 229.202 141.312 229.132 142.356L228.147 156.834L225.523 157.058Z"
        fill="white"
      />
      <path
        d="M226.146 166.92L228.46 143.556C228.628 141.866 230.137 140.634 231.826 140.807C233.592 140.988 234.838 142.625 234.539 144.374L231.353 163.097L228.131 171.689L226.146 166.92Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.5"
        d="M226.146 166.92L228.46 143.556C228.628 141.866 230.137 140.634 231.826 140.807C233.592 140.988 234.838 142.625 234.539 144.374L231.353 163.097L228.131 171.689L226.146 166.92Z"
        fill="white"
      />
      <path
        d="M222.116 173.973L218.369 139.171C218.142 137.055 219.701 135.166 221.823 134.986C223.927 134.81 225.77 136.385 225.921 138.492L228.764 169.999L222.116 173.973Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.3"
        d="M222.116 173.973L218.369 139.171C218.142 137.055 219.701 135.166 221.823 134.986C223.927 134.81 225.77 136.385 225.921 138.492L228.764 169.999L222.116 173.973Z"
        fill="white"
      />
      <path
        d="M224.186 161.727C224.096 161.727 224.02 161.658 224.012 161.567L221.648 135.005C221.648 135.005 221.78 134.99 221.823 134.986C221.866 134.983 221.997 134.976 221.997 134.976L224.36 161.536C224.369 161.633 224.298 161.717 224.201 161.726C224.196 161.727 224.191 161.727 224.186 161.727Z"
        fill="#4A4F56"
      />
      <path
        d="M228.764 170L218.269 146.889C217.445 145.074 215.49 144.053 213.529 144.414C210.8 144.916 209.237 147.812 210.314 150.37L216.375 164.763L207.88 157.722C206.044 156.2 203.284 156.659 202.04 158.693C200.955 160.468 201.445 162.781 203.16 163.961L225.304 179.214L228.764 170Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.15"
        d="M228.764 170L218.269 146.889C217.445 145.074 215.49 144.053 213.529 144.414C210.8 144.916 209.237 147.812 210.314 150.37L216.375 164.763L207.88 157.722C206.044 156.2 203.284 156.659 202.04 158.693C200.955 160.468 201.445 162.781 203.16 163.961L225.304 179.214L228.764 170Z"
        fill="white"
      />
      <path
        d="M219.154 170.673C219.118 170.673 219.083 170.663 219.052 170.64L202.159 158.512C202.159 158.512 202.23 158.412 202.254 158.379C202.282 158.341 202.37 158.233 202.37 158.233L219.256 170.356C219.335 170.413 219.352 170.521 219.296 170.601C219.263 170.649 219.209 170.673 219.154 170.673Z"
        fill="#4A4F56"
      />
      <path
        d="M222.561 168.182C222.492 168.182 222.428 168.143 222.399 168.075L212.597 144.696C212.597 144.696 212.71 144.65 212.747 144.636C212.791 144.619 212.925 144.572 212.925 144.572L222.721 167.941C222.759 168.031 222.716 168.132 222.628 168.17C222.606 168.177 222.583 168.182 222.561 168.182Z"
        fill="#4A4F56"
      />
      <path
        d="M229.487 161.727C229.48 161.727 229.473 161.727 229.468 161.725C229.371 161.715 229.302 161.627 229.313 161.532L231.652 140.793C231.652 140.793 231.781 140.801 231.826 140.805C231.867 140.808 232 140.827 232 140.827L229.661 161.57C229.651 161.662 229.575 161.727 229.487 161.727Z"
        fill="#4A4F56"
      />
      <path
        d="M163.765 118.642C161.055 118.42 161.786 114.711 160.121 112.556L152.616 113.088L138.673 116.71C135.887 117.516 134.483 118.818 134.805 120.943L137.562 145.757C137.588 145.921 137.731 146.042 137.896 146.042H142.325C142.325 146.042 143.197 138.329 143.399 136.001C143.774 131.685 143.266 128.433 142.269 124.897L142.126 124.39L162.491 121.899C165.969 121.295 165.969 118.83 165.969 118.83L163.765 118.642Z"
        fill="#4A4F56"
      />
      <path
        d="M157.699 113.324L162.305 161.539C162.355 161.912 162.676 162.19 163.052 162.186L167.206 162.143L169.271 111.285L160.572 113.195L157.699 113.324Z"
        fill="#4A4F56"
      />
      <path
        d="M162.36 165.069L158.826 167.779L155.307 168.555C154.846 168.65 154.531 169.062 154.531 169.523C154.531 170.061 154.974 170.506 155.514 170.506H167.736C168.084 170.506 168.338 170.204 168.306 169.871L167.91 166.431C167.879 166.209 167.656 166.083 167.449 166.162L166.768 166.464C166.292 166.669 165.738 166.623 165.31 166.337L163.361 165.053C163.075 164.815 162.662 164.832 162.36 165.069Z"
        fill="#4F7EFE"
      />
      <path
        d="M162.899 165.498C163.216 165.165 163.342 164.706 163.263 164.261L162.978 162.724H167.178L167.478 167.496L161.075 167.401L162.899 165.498Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.4"
        d="M162.899 165.498C163.216 165.165 163.342 164.706 163.263 164.261L162.978 162.724H167.178L167.478 167.496L161.075 167.401L162.899 165.498Z"
        fill="white"
      />
      <path
        d="M162.36 165.069L158.826 167.779L155.307 168.555C154.846 168.65 154.531 169.062 154.531 169.523C154.531 170.061 154.974 170.506 155.514 170.506H167.736C168.084 170.506 168.338 170.204 168.306 169.871L167.91 166.431C167.879 166.209 167.656 166.083 167.449 166.162L166.768 166.464C166.292 166.669 165.738 166.623 165.31 166.337L163.361 165.053C163.075 164.815 162.662 164.832 162.36 165.069Z"
        fill="#4F7EFE"
      />
      <path
        d="M162.059 166.274C162.09 166.274 162.107 166.259 162.138 166.243C162.186 166.195 162.202 166.133 162.153 166.085L161.71 165.547C161.662 165.498 161.6 165.483 161.551 165.531C161.503 165.579 161.488 165.641 161.536 165.69L161.979 166.228C161.996 166.259 162.027 166.274 162.059 166.274Z"
        fill="white"
      />
      <path
        d="M161.631 166.576C161.662 166.576 161.679 166.561 161.71 166.545C161.758 166.497 161.774 166.435 161.725 166.387L161.313 165.879C161.265 165.831 161.203 165.816 161.155 165.864C161.106 165.912 161.091 165.974 161.139 166.023L161.551 166.53C161.551 166.559 161.6 166.576 161.631 166.576Z"
        fill="white"
      />
      <path
        d="M161.108 166.892C161.139 166.892 161.156 166.877 161.187 166.861C161.236 166.813 161.251 166.751 161.203 166.702L160.822 166.242C160.773 166.193 160.711 166.178 160.663 166.226C160.615 166.274 160.599 166.337 160.647 166.385L161.029 166.845C161.044 166.876 161.077 166.892 161.108 166.892Z"
        fill="white"
      />
      <path
        d="M168.02 167.496L160.316 170H161.933L168.099 168.082L168.02 167.496Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M168.305 170C168.256 170.27 168.036 170.492 167.734 170.492H155.512C155.148 170.492 154.831 170.302 154.656 170H168.305Z"
        fill="#4A4F56"
      />
      <g opacity="0.15">
        <path
          opacity="0.15"
          d="M136.187 121.668C135.866 119.544 137.269 118.24 140.054 117.435L153.998 113.81L160.604 113.343C160.468 113.065 160.311 112.799 160.121 112.553L152.616 113.084L138.673 116.71C135.887 117.516 134.483 118.818 134.805 120.943L137.562 145.757C137.588 145.921 137.731 146.042 137.896 146.042H138.893L136.187 121.668Z"
          fill="#4A4F56"
        />
      </g>
      <path
        opacity="0.15"
        d="M142.858 127.227L140.149 125.071C139.937 124.935 140.011 124.585 140.279 124.555L142.128 124.391L142.858 127.227Z"
        fill="#4A4F56"
      />
      <path
        d="M137.206 148.676L133.817 151.567L130.343 152.526C129.889 152.645 129.594 153.073 129.616 153.532C129.644 154.07 130.112 154.491 130.65 154.462L142.856 153.827C143.204 153.808 143.442 153.494 143.392 153.164L142.818 149.749C142.775 149.528 142.547 149.414 142.345 149.504L141.679 149.84C141.215 150.072 140.658 150.053 140.217 149.79L138.202 148.609C137.907 148.385 137.495 148.422 137.206 148.676Z"
        fill="#4F7EFE"
      />
      <path
        d="M138.045 148.817C138.362 148.485 138.488 148.026 138.409 147.581L138.124 146.044H142.324V151.117L136.221 150.72L138.045 148.817Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.4"
        d="M138.045 148.817C138.362 148.485 138.488 148.026 138.409 147.581L138.124 146.044H142.324V151.117L136.221 150.72L138.045 148.817Z"
        fill="white"
      />
      <path
        d="M137.206 148.676L133.817 151.567L130.343 152.526C129.889 152.645 129.594 153.073 129.616 153.532C129.644 154.07 130.112 154.491 130.65 154.462L142.856 153.827C143.204 153.808 143.442 153.494 143.392 153.164L142.818 149.749C142.775 149.528 142.547 149.414 142.345 149.504L141.679 149.84C141.215 150.072 140.658 150.053 140.217 149.79L138.202 148.609C137.907 148.385 137.495 148.422 137.206 148.676Z"
        fill="#4F7EFE"
      />
      <path
        d="M136.968 149.894C136.999 149.892 137.015 149.875 137.046 149.858C137.091 149.808 137.103 149.744 137.053 149.699L136.582 149.183C136.532 149.138 136.468 149.126 136.423 149.176C136.378 149.226 136.366 149.29 136.416 149.335L136.887 149.851C136.905 149.882 136.937 149.896 136.968 149.894Z"
        fill="white"
      />
      <path
        d="M136.556 150.218C136.587 150.216 136.603 150.199 136.634 150.182C136.679 150.132 136.691 150.068 136.641 150.023L136.203 149.538C136.153 149.493 136.089 149.481 136.044 149.531C135.999 149.581 135.987 149.645 136.037 149.69L136.475 150.175C136.477 150.206 136.525 150.22 136.556 150.218Z"
        fill="white"
      />
      <path
        d="M136.051 150.561C136.082 150.56 136.097 150.542 136.128 150.525C136.173 150.475 136.185 150.411 136.135 150.366L135.732 149.926C135.681 149.882 135.618 149.87 135.573 149.92C135.528 149.97 135.516 150.033 135.566 150.078L135.97 150.518C135.987 150.549 136.02 150.563 136.051 150.561Z"
        fill="white"
      />
      <path
        d="M142.985 150.803L135.421 153.706L137.036 153.622L143.094 151.384L142.985 150.803Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M143.399 153.29C143.366 153.561 143.156 153.796 142.854 153.811L130.648 154.446C130.284 154.465 129.958 154.291 129.768 153.999L143.399 153.29Z"
        fill="#4A4F56"
      />
      <path
        d="M156.275 112.327L152.616 113.086L152.611 98.5562C152.611 97.2469 152.757 95.941 153.045 94.6628L155.398 87.1556L167.61 86.783L156.556 99.1599L156.275 112.327Z"
        fill="#4F7EFE"
      />
      <path
        d="M139.796 103.76L150.598 105.318C152.24 105.554 153.839 104.666 154.503 103.146L160.961 87.0226L155.593 87.1606C154.17 87.1969 152.947 88.1801 152.604 89.5601L149.958 100.209L139.673 100.488L139.796 103.76Z"
        fill="#4F7EFE"
      />
      <path
        d="M179.546 88.4785C179.546 86.7449 178.141 85.339 176.407 85.339H173.773C172.039 85.339 170.634 86.7449 170.634 88.4785V92.2597H178.815C179.219 92.2597 179.545 91.932 179.545 91.53V88.4785H179.546Z"
        fill="#4A4F56"
      />
      <path
        d="M174.071 112.92H162.171C160.679 112.92 159.469 111.711 159.469 110.219V88.0404C159.469 86.5482 160.679 85.339 162.171 85.339H174.071V112.92Z"
        fill="#4A4F56"
      />
      <path
        d="M175.786 112.92H174.09C172.695 112.92 171.563 111.788 171.563 110.393V87.8661C171.563 86.4706 172.695 85.339 174.09 85.339H175.786C177.181 85.339 178.313 86.4706 178.313 87.8661V110.393C178.313 111.788 177.181 112.92 175.786 112.92Z"
        fill="#4A4F56"
      />
      <path
        d="M168.953 82.7274H167.97C166.528 82.7274 165.358 83.8969 165.358 85.339H166.44C166.44 84.3989 167.201 83.6382 168.141 83.6382H168.783C169.723 83.6382 170.483 84.3989 170.483 85.339H171.565C171.565 83.8969 170.395 82.7274 168.953 82.7274Z"
        fill="#4A4F56"
      />
      <path
        d="M175.406 93.9433L175.011 93.8812C174.963 93.8743 174.913 93.8743 174.865 93.8812L174.47 93.9433C174.24 93.9796 174.071 94.1762 174.071 94.4091V95.7391H175.805V94.4091C175.805 94.1762 175.636 93.9796 175.406 93.9433Z"
        fill="#4F7EFE"
      />
      <path
        d="M175.239 93.9381H174.639C174.556 93.9381 174.489 93.8709 174.489 93.7881C174.489 93.7053 174.556 93.638 174.639 93.638H175.239C175.322 93.638 175.389 93.7053 175.389 93.7881C175.389 93.8709 175.322 93.9381 175.239 93.9381Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.3"
        d="M175.239 93.9381H174.639C174.556 93.9381 174.489 93.8709 174.489 93.7881C174.489 93.7053 174.556 93.638 174.639 93.638H175.239C175.322 93.638 175.389 93.7053 175.389 93.7881C175.389 93.8709 175.322 93.9381 175.239 93.9381Z"
        fill="#4A4F56"
      />
      <path
        d="M175.386 95.4993H174.49C173.35 95.4993 172.426 96.4239 172.426 97.5641V107.843C172.426 108.211 172.724 108.509 173.091 108.509H176.786C177.154 108.509 177.452 108.211 177.452 107.843V97.5641C177.45 96.4239 176.526 95.4993 175.386 95.4993Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.3"
        d="M175.386 95.4993H174.49C173.35 95.4993 172.426 96.4239 172.426 97.5641V107.843C172.426 108.211 172.724 108.509 173.091 108.509H176.786C177.154 108.509 177.452 108.211 177.452 107.843V97.5641C177.45 96.4239 176.526 95.4993 175.386 95.4993Z"
        fill="white"
      />
      <path
        d="M172.426 102.004V109.009C172.426 109.475 172.804 109.851 173.267 109.851H176.609C177.074 109.851 177.45 109.474 177.45 109.009V102.004H172.426Z"
        fill="#4A4F56"
      />
      <path
        d="M177.583 103.193H172.295C171.967 103.193 171.701 102.927 171.701 102.599C171.701 102.272 171.967 102.006 172.295 102.006H177.583C177.911 102.006 178.177 102.272 178.177 102.599C178.177 102.927 177.911 103.193 177.583 103.193Z"
        fill="#4A4F56"
      />
      <path
        d="M159.757 84.4507L156.72 85.515C156.083 85.7375 155.755 86.4396 155.99 87.0709L157.082 90.0052L153.528 91.5007L153.98 100.209L158.379 94.9559L159.906 89.6998L159.757 84.4507Z"
        fill="#4A4F56"
      />
      <path
        d="M160.594 74.3197L158.558 72.4688C158.446 72.367 158.467 72.1842 158.6 72.1083C158.667 72.0703 158.752 72.0703 158.819 72.1083L161.603 73.6832L160.594 74.3197Z"
        fill="#4F7EFE"
      />
      <path
        d="M160.456 74.5077L160.209 74.7717C159.593 75.4013 158.867 76.7071 158.669 77.5713L158.622 77.7749C158.605 77.8025 158.596 77.818 158.579 77.8456L157.206 78.6719C157.049 78.7702 156.994 78.9876 157.115 79.1376L157.482 79.6137L156.814 81.218C156.452 82.0995 156.915 83.1155 157.824 83.4001L159.036 83.79L158.715 89.8171L165.591 88.0266L164.693 83.9331L167.104 79.6603L160.456 74.5077Z"
        fill="#956363"
      />
      <path
        d="M162.402 73.1812L162.276 73.0812C162.749 73.4469 163.164 73.8747 163.754 73.9247L164.536 73.8798C165.722 73.8678 167.073 74.9821 167.094 75.9619C167.147 76.1292 167.132 76.5657 167.494 76.6692C168.469 76.9728 169.036 77.9767 168.872 78.6891L168.86 78.7392C168.743 78.8841 167.942 80.6591 167.942 80.6591C167.056 81.9615 165.819 82.9378 164.691 83.9314C164.691 83.9314 163.818 83.3018 163.882 82.2789L164.289 81.2318L163.197 80.3917L162.99 80.7298C162.904 80.8713 162.723 80.9144 162.581 80.8281C162.454 80.7505 162.402 80.5832 162.466 80.4486L162.902 79.4775L162.426 79.148C161.967 78.7909 161.743 78.3648 161.936 77.8249L162.074 77.4075C162.152 77.1849 162.091 76.9348 161.921 76.7727C161.921 76.7727 160.287 76.1292 160.37 74.7544C160.378 74.5974 160.432 74.4853 160.592 74.3232L162.402 73.1812Z"
        fill="#4A4F56"
      />
      <path
        opacity="0.15"
        d="M159.035 83.7883L160.044 84.0367C160.247 84.0832 160.268 84.3661 160.07 84.4386L158.988 84.6732L159.035 83.7883Z"
        fill="#4A4F56"
      />
      <path
        opacity="0.15"
        d="M163.86 82.2823C163.86 82.2823 163.425 82.5204 163.17 82.5583L164.155 83.3519C164.146 83.3657 163.789 82.9361 163.86 82.2823Z"
        fill="#4A4F56"
      />
      <path
        d="M159.577 78.2865C159.681 78.1149 159.64 77.8989 159.485 77.8042C159.329 77.7095 159.119 77.772 159.014 77.9436C158.909 78.1152 158.95 78.3312 159.106 78.4259C159.261 78.5206 159.472 78.4582 159.577 78.2865Z"
        fill="#4A4F56"
      />
      <path
        d="M159.212 77.9457C159.24 77.9457 159.262 77.9233 159.262 77.8957C159.262 77.8681 159.24 77.8456 159.212 77.8456C159.185 77.8456 159.162 77.8681 159.162 77.8957C159.162 77.9233 159.185 77.9457 159.212 77.9457Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M157.26 80.6764C157.26 80.6764 157.273 80.685 157.26 80.6764C157.287 80.6936 157.844 80.9558 158.386 80.7643C158.439 80.7385 158.474 80.6833 158.458 80.6143C158.433 80.5608 158.377 80.5263 158.308 80.5418C157.865 80.6971 157.366 80.4694 157.366 80.4694C157.31 80.4349 157.234 80.4659 157.213 80.5297C157.201 80.5832 157.216 80.6505 157.26 80.6764Z"
          fill="#4A4F56"
        />
      </g>
      <path
        d="M162.295 73.2485L168.745 78.8841C168.745 78.8841 168.557 79.6552 167.758 80.4987L160.594 74.3215C160.594 74.3197 161.596 73.6884 162.295 73.2485Z"
        fill="#4F7EFE"
      />
      <path
        d="M162.005 82.3116C162.837 82.8188 163.911 82.5462 164.41 81.7286L164.632 81.3629C164.881 80.9541 164.75 80.409 164.341 80.1606C163.932 79.9122 163.387 80.0433 163.138 80.4521L162.005 82.3116Z"
        fill="#956363"
      />
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M163.209 81.1906C163.266 81.2251 163.356 81.2026 163.39 81.1457L163.58 80.8352C163.675 80.68 163.878 80.6299 164.034 80.7248C164.118 80.7766 164.172 80.8473 164.185 80.9525C164.203 81.0198 164.263 81.0785 164.332 81.0612C164.401 81.044 164.458 80.9836 164.441 80.9146C164.411 80.7421 164.318 80.6075 164.163 80.5126C163.896 80.3488 163.532 80.4367 163.37 80.7058L163.18 81.0163C163.123 81.0802 163.14 81.1475 163.209 81.1906Z"
          fill="#4A4F56"
        />
      </g>
      <path
        d="M160.594 74.3199L163.045 72.776C163.518 72.4793 164.101 72.419 164.624 72.6156L167.125 73.5575C167.523 73.7075 167.856 73.9956 168.06 74.3699L169.198 76.452C169.479 76.9661 169.49 77.5853 169.228 78.108L167.944 80.6576L160.594 74.3199Z"
        fill="#4F7EFE"
      />
      <path
        d="M159.505 90.0053L159.257 86.9038C159.212 86.3345 159.574 85.8101 160.123 85.6514L164.774 84.3111L165.317 86.7865L159.505 90.0053Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.15"
        d="M159.505 90.0053L159.257 86.9038C159.212 86.3345 159.574 85.8101 160.123 85.6514L164.774 84.3111L165.317 86.7865L159.505 90.0053Z"
        fill="#F4F4F4"
      />
      <path
        d="M159.505 90.0053L153.98 100.209L152.616 113.086L169.207 113.281L169.128 87.1591L165.317 86.7848L159.505 90.0053Z"
        fill="#4F7EFE"
      />
      <path
        d="M152.616 113.086C152.616 113.086 156.613 112.555 160.996 116.144C164.225 118.787 166.311 120.407 168.971 119.977L169.376 108.511L155.761 109.284L152.616 113.086Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.3"
        d="M152.616 113.086C152.616 113.086 156.613 112.554 160.996 116.144C164.225 118.787 166.311 120.407 168.971 119.977L169.041 118.616C169.041 118.616 166.588 119.292 161.986 115.401C156.785 111.002 152.616 111.899 152.616 111.899V113.086Z"
        fill="#4A4F56"
      />
      <path
        d="M156.005 121.303L155.537 121.523L178.423 170.31L178.893 170.09L156.005 121.303Z"
        fill="#4A4F56"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M156.005 121.303L155.537 121.523L178.423 170.31L178.893 170.09L156.005 121.303Z"
          fill="white"
        />
      </g>
      <path
        d="M155.964 121.393L155.597 121.58C155.388 121.685 155.133 121.602 155.026 121.393L152.854 117.1C152.749 116.891 152.831 116.636 153.04 116.529L153.408 116.342C153.616 116.237 153.872 116.32 153.979 116.529L156.15 120.822C156.257 121.033 156.173 121.288 155.964 121.393Z"
        fill="#4F7EFE"
      />
      <path
        d="M153.661 115.668L156.749 114.029L158.279 116.75C158.279 116.75 158.027 117.091 157.934 117.205L157.546 117.75C157.002 118.442 156.513 118.768 155.866 119.28L155.02 119.948L154.332 120.714C154.149 120.917 153.823 120.914 153.647 120.689C153.53 120.539 153.521 120.325 153.63 120.156L154.053 119.541L153.796 119.905C153.618 120.165 153.244 120.179 153.047 119.929C152.919 119.767 152.911 119.551 153.033 119.373L153.235 119.092C153.033 119.373 152.624 119.392 152.409 119.12C152.271 118.945 152.266 118.707 152.378 118.518L152.476 118.361C152.336 118.592 151.997 118.597 151.829 118.387C151.731 118.262 151.721 118.069 151.793 117.931L152.566 116.634C152.838 116.237 153.216 115.899 153.661 115.668Z"
        fill="#956363"
      />
      <path
        opacity="0.15"
        d="M151.812 117.957L152.53 116.743L152.68 117.189C152.694 117.36 152.788 117.507 152.928 117.579C153.033 117.636 153.102 117.724 153.147 117.831L153.261 118.104C153.296 118.199 153.377 118.274 153.47 118.343L153.66 118.456C153.744 118.511 153.815 118.575 153.849 118.67L153.923 118.815C153.97 118.899 154.039 118.985 154.125 119.018L154.348 119.146C154.455 119.203 154.532 119.303 154.577 119.41L154.619 119.539C154.67 119.68 154.77 119.782 154.912 119.832L155.127 119.925L155.041 119.994L154.353 120.76C154.17 120.964 153.844 120.96 153.668 120.736C153.551 120.586 153.542 120.372 153.651 120.203L154.074 119.587L153.816 119.951C153.639 120.212 153.264 120.226 153.068 119.975C152.94 119.813 152.932 119.598 153.054 119.42L153.256 119.139C153.054 119.42 152.645 119.439 152.43 119.166C152.292 118.992 152.286 118.754 152.399 118.564L152.497 118.407C152.357 118.638 152.017 118.644 151.85 118.433C151.741 118.276 151.717 118.092 151.812 117.957Z"
        fill="#4A4F56"
      />
      <g opacity="0.15">
        <path
          opacity="0.15"
          d="M153.668 119.924L153.817 120.008L154.134 119.515C154.162 119.474 154.144 119.425 154.103 119.398C154.062 119.37 154.013 119.387 153.986 119.429L153.668 119.924Z"
          fill="#4A4F56"
        />
      </g>
      <g opacity="0.15">
        <g opacity="0.15">
          <path
            opacity="0.15"
            d="M153.151 119.142L153.428 118.701L153.432 118.678C153.421 118.666 153.409 118.675 153.409 118.675L153.132 119.116L153.151 119.142Z"
            fill="white"
          />
          <path
            opacity="0.15"
            d="M153.482 118.638C153.501 118.663 153.509 118.699 153.482 118.74L153.164 119.234L153.016 119.149L153.333 118.656C153.361 118.614 153.418 118.609 153.451 118.625C153.471 118.626 153.482 118.638 153.482 118.638Z"
            fill="#4A4F56"
          />
        </g>
      </g>
      <g opacity="0.15">
        <g opacity="0.15">
          <path
            opacity="0.15"
            d="M152.492 118.511L152.77 118.069L152.773 118.047L152.751 118.043L152.461 118.495L152.492 118.511Z"
            fill="white"
          />
          <path
            opacity="0.15"
            d="M152.833 117.999C152.852 118.023 152.861 118.059 152.833 118.1L152.516 118.594L152.368 118.509L152.685 118.016C152.713 117.974 152.77 117.969 152.802 117.985C152.825 117.985 152.825 117.985 152.833 117.999Z"
            fill="#4A4F56"
          />
        </g>
      </g>
      <path
        d="M169.128 87.1591C167.051 87.1108 165.355 88.8082 165.402 90.8851L165.471 94.8888L165.4 107.749L156.033 114.428L158.277 116.751L168.745 111.818C169.588 111.419 170.28 110.734 170.623 109.867C170.742 109.563 170.823 109.246 170.854 108.922L172.107 96.2895L172.645 91.2646C172.933 89.1238 171.284 87.2056 169.128 87.1591Z"
        fill="#4F7EFE"
      />
      <path
        opacity="0.15"
        d="M169.818 87.2419C171.055 87.9474 171.827 89.355 171.625 90.873L171.087 95.8979L169.835 108.53C169.802 108.854 169.723 109.172 169.604 109.475C169.261 110.343 168.569 111.028 167.725 111.426L157.701 116.151L158.281 116.751L168.748 111.818C169.592 111.419 170.283 110.735 170.627 109.867C170.746 109.563 170.827 109.246 170.858 108.922L172.11 96.2895L172.648 91.2646C172.902 89.3602 171.622 87.6317 169.818 87.2419Z"
        fill="#4A4F56"
      />
      <path
        opacity="0.15"
        d="M159.061 112.268L164.358 107.386L165.507 92.479C165.478 90.3538 165.403 107.747 165.403 107.747L159.061 112.268Z"
        fill="#4A4F56"
      />
      <path
        d="M154.253 99.4911V113.124L153.98 113.101V100.209L154.253 99.4911Z"
        fill="#4A4F56"
      />
      <path
        d="M166.911 94.2763C167.209 91.8889 168.062 90.1639 170.011 89.241L171.791 88.413C171.791 88.413 171.28 87.6833 170.042 87.4642L168.425 88.118C166.204 89.1185 164.888 91.4421 165.172 93.8605L165.402 107.747C165.402 107.747 166.481 97.7125 166.911 94.2763Z"
        fill="#4A4F56"
      />
      <path
        d="M166.514 93.9383C166.813 91.5509 167.756 89.8173 169.705 88.8944L171.413 88.0354C171.413 88.0354 170.797 87.4126 169.559 87.1936L168.156 87.8473C165.935 88.8478 164.618 91.1714 164.903 93.5899L165.401 107.745C165.401 107.747 166.085 97.3745 166.514 93.9383Z"
        fill="#4A4F56"
      />
      <path
        opacity="0.15"
        d="M158.574 122.397C163.925 121.486 164.791 119.491 164.791 119.491C164.791 119.491 163.815 121.254 160.282 122.785L163.678 162.171L162.947 162.129C162.604 162.11 162.326 161.843 162.293 161.499L158.574 122.397Z"
        fill="#4A4F56"
      />
      <path
        d="M166.844 162.899H163.097C162.897 162.899 162.735 162.737 162.735 162.536V162.095H167.206V162.536C167.204 162.737 167.042 162.899 166.844 162.899Z"
        fill="#4A4F56"
      />
      <path
        d="M141.962 146.848H138.216C138.015 146.848 137.853 146.685 137.853 146.485V146.044H142.324V146.485C142.324 146.685 142.162 146.848 141.962 146.848Z"
        fill="#4A4F56"
      />
      <path
        d="M128.856 91.8923L135.147 90.5968L139.666 99.7324L139.673 111.549L135.462 110.826V100.745L128.856 91.8923Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M130.025 93.4586L134.703 92.5497L138.535 100.293L138.795 110.486L135.462 110.06V100.745L130.025 93.4586Z"
        fill="#4A4F56"
      />
      <path
        d="M138.764 108.901L137.301 108.784L137.344 106.229H138.685V106.055H137.348L137.394 103.334L138.612 103.288L138.605 103.113L137.398 103.16L137.443 100.541L138.547 100.379L138.521 100.207L137.446 100.364V100.36V100.336L135.845 97.4641L136.93 97.0759L136.872 96.9103L135.759 97.3088L134.364 94.8093L135.645 94.4988L135.604 94.328L134.276 94.6506L133.248 92.8048L133.094 92.8911L134.1 94.6937L131.466 95.3337L131.507 95.5027L134.188 94.8524L135.592 97.3692L133.527 98.1075L133.586 98.2731L135.678 97.5244L137.27 100.379V100.39L135.45 100.657L135.474 100.831L137.267 100.567L137.222 103.167L135.459 103.234L135.466 103.408L137.22 103.341L137.174 106.055H135.462V106.229H137.17L137.127 108.77L135.469 108.637L135.456 108.811L137.124 108.944L137.101 110.279L137.275 110.283L137.298 108.958L138.75 109.075L138.764 108.901Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M128.856 91.8923L131.918 89.8775L132.922 91.054L128.856 91.8923Z"
        fill="#4A4F56"
      />
      <path
        d="M135.481 103.992C134.717 102.619 133.996 101.287 134.188 99.7274C134.36 99.3962 134.486 99.272 134.817 99.1047L137.434 97.823C137.605 97.742 137.803 97.811 137.888 97.9697C137.972 98.1301 137.926 98.3371 137.762 98.432L135.588 99.7671L137.555 99.1254C137.774 99.0512 138.005 99.2203 137.998 99.4566C137.993 99.5929 137.912 99.6981 137.802 99.7533L135.502 100.749L137.253 99.9931C137.465 99.9034 137.691 100.059 137.688 100.283C137.691 100.397 137.626 100.497 137.541 100.55L135.531 101.789L136.899 100.954C137.053 100.854 137.269 100.942 137.308 101.12C137.331 101.216 137.303 101.318 137.239 101.38C137.239 101.38 135.787 102.182 135.513 103.214C135.414 103.59 135.481 103.992 135.481 103.992Z"
        fill="#956363"
      />
      <path
        opacity="0.15"
        d="M135.502 100.752L137.251 99.9963"
        stroke="#4A4F56"
        stroke-width="0.1725"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.15"
        d="M135.768 101.645L136.91 100.947"
        stroke="#4A4F56"
        stroke-width="0.1725"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.15"
        d="M152.612 105.042L152.616 97.0828L153.047 94.654C153.059 94.5867 153.023 94.5212 152.959 94.4971C152.88 94.466 152.79 94.5109 152.769 94.5937L152.141 97.0569L151.022 105.354C151.022 105.354 151.272 105.394 151.867 105.28C152.338 105.189 152.612 105.042 152.612 105.042Z"
        fill="#4A4F56"
      />
      <path
        d="M169.241 61.705C169.241 61.705 168.015 61.4342 167.182 60.6027C166.349 59.7695 164.87 57.3338 161.123 58.3757C157.377 59.4159 157.586 60.0404 155.712 59.8316C153.839 59.6229 151.965 56.1367 149.052 56.9164C146.137 57.6961 142.656 61.7033 142.656 61.7033L169.241 61.705Z"
        fill="white"
      />
      <path
        d="M64.6113 74.0937C64.6113 74.0937 65.41 73.9178 65.9517 73.3761C66.4933 72.8345 67.4576 71.2475 69.8967 71.9254C72.3359 72.6033 72.2013 73.0104 73.4209 72.8742C74.6405 72.7379 75.8618 70.4678 77.7576 70.9767C79.6551 71.4855 81.9217 74.0937 81.9217 74.0937H64.6113Z"
        fill="white"
      />
      <path
        d="M230.594 179.317H56.9767C56.9163 179.317 56.8749 179.278 56.8749 179.215C56.8749 179.153 56.9163 179.114 56.9767 179.114H230.615C230.675 179.114 230.717 179.155 230.717 179.215C230.717 179.276 230.655 179.317 230.594 179.317Z"
        fill="#B2B6BB"
      />
      <path
        d="M67.9891 179.114L62.438 159.921C62.4052 159.809 62.4708 159.692 62.5829 159.659C62.695 159.626 62.8123 159.692 62.8451 159.804L68.4341 179.114H67.9891Z"
        fill="#4A4F56"
      />
      <path
        d="M71.5305 179.114L75.8016 155.729C75.8223 155.614 75.9327 155.54 76.0466 155.56C76.1621 155.581 76.238 155.691 76.2156 155.805L71.9635 179.114H71.5305Z"
        fill="#4A4F56"
      />
      <path
        d="M72.9829 179.114L72.7069 179.115L89.574 163.566C89.6602 163.487 89.793 163.492 89.8724 163.578C89.9517 163.665 89.9466 163.797 89.8603 163.877L73.3314 179.115L72.9829 179.114Z"
        fill="#4A4F56"
      />
      <path
        d="M85.4856 179.114L97.5865 176.569C97.7003 176.545 97.8124 176.618 97.8366 176.731C97.8607 176.845 97.7883 176.957 97.6727 176.982L87.5711 179.114C87.5573 179.117 85.4856 179.114 85.4856 179.114Z"
        fill="#4A4F56"
      />
      <path
        d="M199.974 49.868C199.974 49.868 197.799 45.7591 194.512 46.8234C191.344 47.8498 191.011 50.7875 191.011 50.7875C191.011 50.7875 188.235 47.3444 185.003 48.783C182.091 50.0802 182.651 54.0632 182.651 54.0632C182.651 54.0632 183.257 50.5218 186.069 49.9215C188.882 49.3195 191.328 52.3762 191.328 52.3762C191.328 52.3762 191.93 49.0107 194.885 48.1603C197.837 47.3081 199.974 49.868 199.974 49.868Z"
        fill="#4A4F56"
      />
      <path
        d="M179.165 48.4602C179.165 48.4602 177.706 45.2431 175.015 46.097C172.779 46.8042 172.534 48.8484 172.534 48.8484C172.534 48.8484 170.539 46.4955 168.312 47.5546C166.304 48.5085 166.763 51.2789 166.763 51.2789C166.763 51.2789 167.202 48.7518 169.153 48.2843C171.104 47.8168 172.785 49.9541 172.785 49.9541C172.785 49.9541 173.445 47.6426 175.491 46.9992C177.533 46.3471 179.165 48.4602 179.165 48.4602Z"
        fill="#4A4F56"
      />
      <path
        d="M211.136 55.6399C211.136 55.6399 209.677 52.4228 206.986 53.2766C204.75 53.9839 204.505 56.028 204.505 56.028C204.505 56.028 202.51 53.6751 200.283 54.7343C198.275 55.6882 198.734 58.4585 198.734 58.4585C198.734 58.4585 199.173 55.9314 201.124 55.4639C203.075 54.9965 204.756 57.1337 204.756 57.1337C204.756 57.1337 205.416 54.8222 207.462 54.1788C209.505 53.5285 211.136 55.6399 211.136 55.6399Z"
        fill="#4A4F56"
      />
      <path
        d="M158.77 88.2993L156.552 95.4615L159.505 90.0053L159.378 88.4063C159.378 88.4063 159.136 88.5132 158.953 88.4063"
        fill="#F4F4F4"
      />
      <path
        d="M158.594 76.8193C158.494 76.8607 158.408 76.94 158.356 77.0383L158.179 77.3816C157.837 78.0423 158.096 78.8599 158.757 79.2015C159.417 79.543 160.235 79.2843 160.576 78.6236L160.754 78.2803C160.882 78.0354 160.787 77.7387 160.542 77.611L159.038 76.8313L159.026 76.8244C159.026 76.8262 158.82 76.7347 158.594 76.8193ZM158.919 77.0038L158.931 77.0107L160.435 77.7904C160.582 77.8663 160.64 78.0526 160.564 78.1993L160.387 78.5425C160.095 79.1049 159.395 79.3171 158.833 79.0255C158.27 78.734 158.058 78.0337 158.35 77.4713L158.527 77.128C158.605 76.9797 158.784 76.9331 158.919 77.0038Z"
        fill="#4A4F56"
      />
    </svg>
  );
}

export default React.memo(NewMilestonIllustration);

import Axios from "axios";
import Pagination from "components/custom/Pagination";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "../../components/lib";

export default function DomainConfiguration({ baseURL, next, back }) {
  const form = {
    url: {
      label: "Your Website URL",
      placeholder: "https://example.com",
      value: baseURL,
      required: true,
    },
  };

  async function onSuccess() {
    await Axios.patch("/api/user", { onboarded: true });
    next();
  }

  return (
    <div>
      <Form
        callback={onSuccess}
        url="/api/account/script"
        buttonText="Save settings"
        method="POST"
        data={form}
        customSubmit={
          <Pagination
            onPrevious={back}
            onNext={() => {}}
            hasNext={true}
            hasBack={true}
          />
        }
      />
    </div>
  );
}

/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React, { useContext } from "react";
import { Heading } from "@chakra-ui/react";
import { Animate, AuthContext, Form, Card, Link, Row } from "components/lib";
import Column from "components/custom/Column";

export function Signin(props) {
  const context = useContext(AuthContext);

  return (
    <Column w="100%" align="center" spacing="32px">
      <Heading
        as="h2"
        fontfamily="Inter"
        fontstyle="normal"
        fontweight="bold"
        fontsize="24px"
        lineheight="32px"
        textalign="center"
        color="#4A4F56"
      >
        Welcome Back
      </Heading>

      <Column w="100%" align="center" spacing="8px">
        <Form
          data={{
            email: {
              label: "Email",
              type: "email",
              required: true,
            },
            password: {
              label: "Password",
              type: "password",
              required: true,
            },
            forgotpassword: {
              type: "link",
              url: "/forgotpassword",
              text: "I forgot my password",
            },
          }}
          url="/api/user/auth"
          method="POST"
          buttonText="Sign In"
          callback={context.signin}
          fullWidthButton
          xlButton
        />

        <span style={{ fontSize: "14px" }}>
          I don’t have an account. <Link url="/signup" text="Create Account" />
        </span>
      </Column>
    </Column>
  );
}

/***
 *
 *   RESET PASSWORD
 *   User can set a new password using the token
 *
 **********/

import React, { useContext } from "react";
import { Animate, Row, AuthContext, Card, Form } from "components/lib";
import Column from "components/custom/Column";
import { Heading } from "@chakra-ui/react";

export function ResetPassword(props) {
  // context
  const context = useContext(AuthContext);

  // check for token
  const qs = props.location.search;
  const token = qs.substring(qs.indexOf("?token=") + 7);

  return (
    <Column w="100%" align="center" spacing="32px">
      <Heading
        as="h2"
        fontfamily="Inter"
        fontstyle="normal"
        fontweight="bold"
        fontsize="24px"
        lineheight="32px"
        textalign="center"
        color="#4A4F56"
      >
        Reset Password
      </Heading>

      <Form
        data={{
          jwt: {
            type: "hidden",
            value: token,
          },
          email: {
            label: "Email",
            type: "email",
          },
          password: {
            label: "New Password",
            type: "password",
            required: true,
          },
        }}
        url="/api/user/password/reset"
        method="POST"
        buttonText="Set New Password"
        callback={(data) => {
          context.signin(data);
        }}
        fullWidthButton
        xlButton
      />
    </Column>
  );
}

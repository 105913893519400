/***
 *
 *   STAT
 *   Statistic value with optional icon and -/+ change value
 *
 *   PROPS
 *   value:  numeric value
 *   label: string
 *   loading: boolean to toggle loading animation (optional)
 *   icon: icon to use (optional)
 *   change: positive/negative number indicating change amount (optional)
 *
 **********/

import React from "react";
import ClassNames from "classnames";
import { Card, Icon, Loader } from "components/lib";
import Style from "./stat.module.scss";

export function Stat(props) {
  const isChangeNegative = props.change?.toString().includes("-");

  // style
  const changeCss = ClassNames([
    Style.change,
    isChangeNegative ? Style.down : Style.up,
  ]);

  // is loading
  if (props.loading || props.value === undefined) {
    return (
      <Card>
        <div className={Style.stat}>
          <Loader />
        </div>
      </Card>
    );
  }

  const hasChange = props.change !== null;

  return (
    <Card>
      <div className={Style.stat}>
        <div className={Style.column}>
          <div className={Style.label}>{props.label}</div>

          <div className={Style.row}>
            <div className={Style.value}>{props.value}</div>
            {props.change && hasChange && (
              <div className={Style.row}>
                <GrowIcon isUp={!isChangeNegative} />
                <div className={changeCss}>{props.change}</div>
              </div>
            )}
          </div>
        </div>

        {props.icon && (
          <div className={Style.wrapper}>
            <Icon
              color="dark"
              image={props.icon}
              size={20}
              className={Style.icon}
            />
          </div>
        )}
      </div>
    </Card>
  );
}

const GrowIcon = ({ isUp = true }) => {
  const color = isUp ? "#1EC26A" : "red";
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.05554 10.7099L7.15449 7.61099C7.39343 7.37205 7.78026 7.37205 8.0186 7.61099L9.32699 8.91938C9.56593 9.15833 9.95276 9.15833 10.1911 8.91938L13.8333 5.27777"
        stroke={color}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.2035 5.27777H13.8333V6.9076"
        stroke={color}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {HiChevronDown} from "react-icons/hi";
import Axios from "axios";
import EmailListDetails from "../../components/EmailListDetails";
import {
    Flex,
    Spinner,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuOptionGroup,
    Portal,
    MenuItemOption,
    Button as ChakraButton,
} from "@chakra-ui/react";
import {Link, Card, Grid, useAPI, Icon} from "components/lib";
import MailChimp from "components/icons/MailChimp";
import Row from "components/custom/Row";
import ConvertKit from "components/icons/ConvertKit";
import Mailerlite from "components/icons/Mailerlite";

export default function EmailList({location}) {
    const history = useHistory();

    const mailchimpAudiences = useAPI("/api/mailchimp/lists");
    const mailerliteLists = useAPI("/api/mailerlite/lists");
    const convertkitLists = useAPI("/api/convertkit/lists");

    const [selectedId, setSelectedId] = useState("");
    const [listId, setListId] = useState("");
    const [esp, setEsp] = useState("");
    const [mailchimpName, setMailchimpName] = React.useState("");
    const [dateRange, setDateRange] = React.useState("Last 30 Days");

    const [list, setList] = useState(null);
    const [loadingList, setLoadingList] = useState(false);

    async function loadListData(range) {
        setLoadingList(true);

        Axios.get(`/api/${esp}/lists/${listId}?dateRange=${dateRange}`)
            .then((response) => {
                const data = response.data.data;
                setList(data);
                setLoadingList(false);
            })
            .catch((e) => {
                setLoadingList(false);
            });
    }

    function onListSelected(espListId) {
        console.log(espListId);
        const [esp, listId] = espListId.split("_");
        setSelectedId(espListId);
        history.replace(`/lists?esp=${esp}&listId=${listId}`);
    }

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const queryEsp = query.get("esp");
        const queryListId = query.get("listId");

        if (!queryEsp || !queryListId) {
            if (mailchimpAudiences.data && mailchimpAudiences.data.length > 0) {
                return onListSelected(`mailchimp_${mailchimpAudiences.data[0].id}`);
            } else if (mailerliteLists.data) {
                return onListSelected(`mailerlite_${mailerliteLists.data[0].id}`);
            } else if (convertkitLists.data) {
                return onListSelected(`convertkit_${convertkitLists.data[0].id}`);
            }
        }

        setEsp(queryEsp);
        setListId(queryListId);
        setSelectedId(`${queryEsp}_${queryListId}`);
    }, [location, mailchimpAudiences, mailerliteLists]);

    useEffect(() => {
        console.log(listId);
        if (!listId) {
            return;
        }
        loadListData(dateRange);
    }, [listId]);

    console.log(list);

    return (
        <div>
            <Grid cols="1">
                <Flex justify="space-between" zIndex={10}>
                    <Menu>
                        <MenuButton
                            as={ChakraButton}
                            rightIcon={<HiChevronDown size={18}/>}
                            p="10px 16px"
                            borderRadius="8px"
                            bg="white"
                            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
                            minH="40px"
                            _hover={{bg: "white"}}
                            _expanded={{bg: "#f8f8f8"}}
                            _focus={{bg: "white"}}
                            isDisabled={esp === null}
                            zIndex={10}
                        >
                            <Row align="center">
                                {esp === "convertkit" ? (
                                    <ConvertKit/>
                                ) : esp === "mailerlite" ? (
                                    <Mailerlite/>
                                ) : esp === "mailchimp" ? (
                                    <MailChimp/>
                                ) : null}
                                <Text
                                    fontfamily="Inter"
                                    fontWeight="600"
                                    fontSize="14px"
                                    lineHeight="20px"
                                    color="#4A4F56"
                                    textTransform="capitalize"
                                >
                                    {esp === "mailchimp" ? mailchimpName : esp}
                                    {esp === null && "Please, Connect A Newsletter"}
                                </Text>
                            </Row>
                        </MenuButton>

                        <Portal>
                            <MenuList>
                                {mailerliteLists?.data?.length > 0 && (
                                    <MenuItem
                                        onClick={() =>
                                            onListSelected(
                                                `mailerlite_${mailerliteLists?.data[0]?.id}`
                                            )
                                        }
                                    >
                                        <Row p={2} spacing={4}>
                                            <Mailerlite/>
                                            <Text>MailerLite</Text>
                                        </Row>
                                    </MenuItem>
                                )}
                                {convertkitLists?.data?.length > 0 && (
                                    <MenuItem
                                        onClick={(event) =>
                                            onListSelected(
                                                `convertkit_${convertkitLists?.data[0]?.id}`
                                            )
                                        }
                                    >
                                        <Row p={2} spacing={4}>
                                            <ConvertKit/>
                                            <Text>Convertkit</Text>
                                        </Row>
                                    </MenuItem>
                                )}

                                {mailchimpAudiences?.data?.length > 0 && (
                                    <MenuOptionGroup
                                        defaultValue="asc"
                                        title="MailChimp"
                                        type="radio"
                                    >
                                        {mailchimpAudiences.data.map((audience) => (
                                            <MenuItemOption
                                                key={audience.id}
                                                onClick={() => {
                                                    onListSelected(`mailchimp_${audience.id}`);
                                                    setMailchimpName(audience.name);
                                                }}
                                            >
                                                <Row p={2} spacing={4}>
                                                    <MailChimp/>
                                                    <Text>{audience.name}</Text>
                                                </Row>
                                            </MenuItemOption>
                                        ))}
                                    </MenuOptionGroup>
                                )}
                            </MenuList>
                        </Portal>
                    </Menu>

                    <Menu>
                        <MenuButton
                            as={ChakraButton}
                            rightIcon={<HiChevronDown size={18}/>}
                            p="10px 16px"
                            borderRadius="8px"
                            bg="white"
                            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
                            minH="40px"
                            _hover={{bg: "white"}}
                            _expanded={{bg: "#f8f8f8"}}
                            _focus={{bg: "white"}}
                            zIndex={10}
                        >
                            <Text
                                fontfamily="Inter"
                                fontWeight="600"
                                fontSize="14px"
                                lineHeight="20px"
                                color="#4A4F56"
                                textTransform="capitalize"
                            >
                                {dateRange}
                            </Text>
                        </MenuButton>

                        <Portal>
                            <MenuList zIndex={10}>
                                {dateRangeData.map((range) => (
                                    <MenuItem
                                        onClick={() => {
                                            //alert("Need to change date range at the backend");
                                            setDateRange(range);
                                            loadListData(range)
                                        }}
                                    >
                                        <Text
                                            p={2}
                                            fontfamily="Inter"
                                            fontWeight="600"
                                            fontSize="14px"
                                            lineHeight="20px"
                                            color="#4A4F56"
                                            textTransform="capitalize"
                                        >
                                            {range}
                                        </Text>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Portal>
                    </Menu>
                </Flex>
            </Grid>

            {loadingList ? (
                <Spinner/>
            ) : list ? (
                <div style={{marginTop: "16px"}}>
                    <EmailListDetails dateRange={dateRange} emailList={list}/>
                </div>
            ) : (
                <span style={{fontSize: "14px"}}>
          You haven't connect a newsletter yet!{" "}
                    <Link url="/settings/newsletters" text="Connect Your Newsletter."/>
        </span>
            )}
        </div>
    );
}

const dateRangeData = [
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 15 Days",
    "Last 30 Days",
];

import { Text, Wrap, WrapItem } from "@chakra-ui/react";
import Column from "components/custom/Column";
import CustomButton from "components/custom/CustomButton";
import Row from "components/custom/Row";
import Aweber from "components/icons/Aweber";
import ConvertKit from "components/icons/ConvertKit";
import MailChimp from "components/icons/MailChimp";
import Mailerlite from "components/icons/Mailerlite";
import useLocalStorage from "hooks/useLocalStorage";
import React, { useContext } from "react";
import { HiPlus } from "react-icons/hi";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../app/auth";
import { useAPI } from "../../components/hooks/api";

export default function ESPSelectView({ ESPs, ...props }) {
  return (
    <Wrap w="390px">
      {ESPs.map((esp) => (
        <WrapItem w="192px">
          <IntegrationCard
            key={esp.id}
            esp={esp.id}
            icon={getIcon(esp.id)}
            name={esp.name}
            href={esp.integrateUrl}
          />
        </WrapItem>
      ))}
    </Wrap>
  );
}

function getIcon(name) {
  switch (name) {
    case "mailchimp":
      return <MailChimp />;
    case "mailerlite":
      return <Mailerlite />;
    case "aweber":
      return <Aweber />;
    case "convertkit":
      return <ConvertKit />;
    default:
      return null;
  }
}

const IntegrationCard = ({ key, esp, icon, name, href }) => {
  const [_esp, setEsp] = useLocalStorage("onboarding-esp");
  const [_step, setStep] = useLocalStorage("onboarding-step");

  return (
    <Column
      key={key}
      p="20px"
      justify="space-between"
      bg="white"
      border="1px solid #DDDDDD"
      borderRadius="8px"
      spacing="16px"
    >
      <Row spacing={4}>
        {icon}
        <Text
          fontWeight="600"
          fontSize="14px"
          lineHeight="20px"
          color="#4A4F56"
        >
          {name}
        </Text>
      </Row>
      <Text
        fontWeight="normal"
        fontSize="12px"
        lineHeight="16px"
        color="#4A4F56"
        opacity="0.8"
      >
        Sync your members with {name}’s email marketing platform.
      </Text>
      <CustomButton
        as="a"
        href={href}
        size="lg"
        rightIcon={<HiPlus />}
        w="auto"
        borderRadius="4px"
        maxW="100px"
        onClick={() => {
          const step = esp === "mailchimp" || esp === "aweber" ? 3 : 2;
          setEsp(esp);
          setStep(step);
        }}
      >
        Connect
      </CustomButton>
    </Column>
  );
};

/***
 *
 *   ACCOUNT NAV
 *   Acount sub nav that renders different options based
 *   on user permissions. Shows billing & user admin to only account owners
 *
 **********/

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "components/lib";
import "./sub.scss";

export function AccountNav(props) {
  const context = useContext(AuthContext);

  return (
    <nav className="subnav">
      <NavLink exact to="/settings" activeClassName="active">
        Profile
      </NavLink>
      <NavLink exact to="/settings/password" activeClassName="active">
        Password
      </NavLink>
      <NavLink exact to="/settings/newsletters" activeClassName="active">
        Newsletters
      </NavLink>
      <NavLink exact to="/settings/script" activeClassName="active">
        Script
      </NavLink>
      <NavLink exact to="/settings/branding" activeClassName="active">
        Branding
      </NavLink>

      {context.permission?.admin && (
        <NavLink exact to="/settings/users" activeClassName="active">
          Members
        </NavLink>
      )}
    </nav>
  );
}

/***
 *
 *   PASSWORD
 *   Update the users password
 *
 **********/

import React, { Fragment } from "react";
import {
  Box,
  Center,
  Image as ChImage,
  Input,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { HiX } from "react-icons/hi";
import { Card, Form, AccountNav, Animate } from "components/lib";
import { HexColorPicker } from "react-colorful";

import SettingsCard from "components/custom/SettingsCard";
import UploadButton from "components/custom/UploadButton";
import Column from "components/custom/Column";
import Row from "components/custom/Row";
import CustomButton, { SecondaryButton } from "components/custom/CustomButton";
import { Link } from "react-router-dom";
import { ListDropdown } from "components/custom/ListDropdown";
import Axios from "axios";
import toast from "react-hot-toast";

export function Branding({ isSeperatePage = false, location }) {
  const [brandColor, setBrandColor] = React.useState("#111111");
  const [logo, setLogo] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const [brandId, setBrandId] = React.useState(null);

  const query = new URLSearchParams(location.search);
  const esp = query.get("esp");
  const listId = query.get("listId");

  // const [listId, setListId] = React.useState(null);

  React.useEffect(() => {
    if (!listId) return;

    const url = `/api/lists/${listId}/brand`;
    Axios.get(url)
      .then((res) => {
        return res.data.data;
      })
      .then((data) => {
        setBrandColor(data.brandColor);
        setLogo(data.logoURL);
        setBrandId(data.id);
        // console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [listId]);

  function save() {
    setLoading(true);

    const isNew = !Boolean(brandId);

    Axios({
      method: isNew ? "post" : "put",
      url: isNew
        ? `/api/lists/${listId}/brand`
        : `/api/lists/${listId}/brand/${brandId}`,
      data: {
        logo: logo.includes("referralkit") ? "" : logo,
        brandColor,
      },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        toast.success("Your brand guidelines saved successfully");
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const content = (
    <Card title="Brand Your Referral Page" restrictWidth noPadding lgTitle>
      <Column spacing={16}>
        <Column spacing={2} shouldWrapChildren>
          <Text fontSize="10px" lineHeight="14px" color="#4A4F56">
            Newsletter:
          </Text>
          <ListDropdown
            location={location}
            currentPath={isSeperatePage ? "/branding" : "/settings/branding"}
            // onSelect={({ listId, esp }) => {
            //   console.log(listId, esp);
            //   setListId(listId);
            // }}
          />
        </Column>

        <Column spacing={2} maxW="150px">
          <Text fontSize="10px" lineHeight="14px" color="#4A4F56">
            Logo:
          </Text>
          <Box role="group" position="relative" maxW="150px">
            {logo && <ChImage src={logo} maxW="150px" />}

            <Center
              onClick={() => setLogo("")}
              display="none"
              cursor="pointer"
              _groupHover={{ display: "flex" }}
              position="absolute"
              top={0}
              left={0}
              w="100%"
              h="100%"
              bg="#ffffffcc"
              color="#2e66fe"
            >
              <HiX />
            </Center>
          </Box>

          <UploadButton
            text="Upload Your Logo"
            isSecondary
            onComplete={(url) => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              const img = new Image();

              img.onload = function () {
                canvas.width = 300;
                canvas.height = canvas.width * (img.height / img.width);

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const base64 = canvas.toDataURL("image/jpg", 0.7);
                setLogo(base64);
              };
              img.src = url;
            }}
          />
        </Column>

        <Column spacing={2} maxW="150px">
          <Text fontSize="10px" lineHeight="14px" color="#4A4F56">
            Brand Color:
          </Text>

          <Row
            borderRadius="4px"
            bg="#FFFFFF"
            border="1px solid rgba(74, 79, 86, 0.2)"
            align="center"
            spacing={4}
          >
            <Popover>
              <PopoverTrigger>
                <Box
                  cursor="pointer"
                  w="24px"
                  h="24px"
                  minW="32px"
                  minH="32px"
                  bg={brandColor}
                  borderLeftRadius="4px"
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                {/* <PopoverHeader>Confirmation!</PopoverHeader>
    <PopoverBody>Are you sure you want to have that milkshake?</PopoverBody> */}
                <HexColorPicker color={brandColor} onChange={setBrandColor} />
              </PopoverContent>
            </Popover>

            <Row align="center" spacing={2}>
              <Text
                fontSize="12px"
                lineHeight="14px"
                color="#4A4F56"
                opacity="0.5"
              >
                #
              </Text>
              <Input
                fontSize="12px"
                lineHeight="14px"
                color="#4A4F56"
                p={0}
                maxlength={6}
                value={brandColor.replace("#", "")}
                onChange={(e) => {
                  const color = "#" + e.target.value;
                  const isValid = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(
                    color
                  );

                  setBrandColor(color);
                }}
                bg="transparent"
                border="none"
                shadow="none"
                _focus={{
                  bg: "transparent",
                  border: "none",
                  shadow: "none",
                }}
              />
            </Row>
          </Row>
        </Column>

        <Row spacing={4}>
          <CustomButton isLoading={loading} onClick={save} borderRadius="4px">
            Save Changes
          </CustomButton>
          <Link to={`/refer-a-friend/preview/${esp}/${listId}`} target="_blank">
            <SecondaryButton>Preview Page</SecondaryButton>
          </Link>
        </Row>
      </Column>
    </Card>
  );

  return isSeperatePage ? (
    <Column spacing="20px" p="40px" borderRadius="16px" bg="white">
      {content}
    </Column>
  ) : (
    <SettingsCard>{content}</SettingsCard>
  );
}

/***
 *
 *   BILLING
 *   Change a subscription plan or update card details
 *
 **********/

import React, { Fragment, useContext } from "react";
import {
  AuthContext,
  Card,
  AccountNav,
  TabView,
  Message,
  Form,
  PaymentForm,
  Animate,
  useAPI,
  usePlans,
} from "components/lib";
import SettingsCard from "components/custom/SettingsCard";
import Column from "components/custom/Column";
import { Box, Text } from "@chakra-ui/react";
import Row from "components/custom/Row";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

import { DangerButton } from "components/custom/CustomButton";
import Axios from "axios";
import toast from "react-hot-toast";
import { log } from "handlebars";

export function Billing(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPaid, setIsPaid] = React.useState(false);

  // state/context
  const context = useContext(AuthContext);

  // fetch plans and subscription
  const plans = usePlans();
  const subscription = useAPI("/api/account/subscription");

  React.useEffect(() => {
    if (!subscription.loading) {
      const plan = subscription?.data?.plan;
      setIsPaid(plan !== "free");
    }
  }, [subscription]);

  // const isPaid = context.user.plan === "free" ? false : true;

  const price = plans?.data?.raw.price;
  const currency = plans?.data?.raw.currency;
  const interval = plans?.data?.raw.interval;
  const totalSubscribers = plans?.data?.raw.totalSubscribers;

  function cancelSubscription() {
    Axios.patch("/api/account/plan", { plan: "free" }).then((res) => {
      console.log(res);
      const { data } = res;

      context.update({ plan: data.data.plan });

      toast.success(data.message);
    });
  }

  return (
    <SettingsCard>
      <Card
        title="Billing"
        restrictWidth
        noPadding
        loading={plans.loading}
        className="upgrade-form"
        lgTitle
      >
        <Row position="absolute" top="0" right="0">
          <Box
            as="span"
            display="block"
            p="4px 6px"
            bg="#E8EEFF"
            borderRadius="4px"
            fontSize="10px"
            fontWeight="bold"
            lineHeight="16px"
            color="#2E66FE"
          >
            {isPaid ? "PRO" : "FREE"}
          </Box>
        </Row>
        <Column spacing={20}>
          <Column spacing={12}>
            <Text
              fontStyle="normal"
              fontWeight="600"
              fontSize="16px"
              lineHeight="24px"
              color="#4A4F56"
            >
              Your {isPaid ? "active" : "suggested"} plan{" "}
              <Box as="span" fontWeight="400">
                ({totalSubscribers} subscribers)
              </Box>
              :{" "}
            </Text>

            <Row align="flex-end" spacing={4}>
              <Text
                fontWeight="800"
                fontSize="42px"
                lineHeight="42px"
                height="42px"
                color="#4F7EFE"
              >
                {currency} {price}
              </Text>

              <Text
                fontSize="16px"
                lineHeight="16px"
                height="16px"
                color="#4A4F56"
                opacity="0.9"
              >
                / {interval}
              </Text>
            </Row>

            <Text
              fontSize="12px"
              lineHeight="12px"
              height="12px"
              color="#4A4F56"
              opacity="0.6"
            >
              * Price automatically adjusts based on the number of your
              subscribers.{" "}
            </Text>
          </Column>

          {isPaid ? (
            <Column>
              <Text
                fontStyle="normal"
                fontWeight="600"
                fontSize="16px"
                lineHeight="24px"
                color="#4A4F56"
              >
                Update Credit Card information:
              </Text>
              <PaymentForm
                className="restrict-width"
                data={{
                  token: {
                    label: "",
                    type: "creditcard",
                    // required: true,
                  },
                }}
                url="/api/account/card"
                method="PATCH"
                buttonText="Update"
              />
            </Column>
          ) : (
            <Column spacing={4}>
              <Text
                fontStyle="normal"
                fontWeight="600"
                fontSize="16px"
                lineHeight="24px"
                color="#4A4F56"
              >
                Upgrade Your Account:
              </Text>
              <PaymentForm
                className="restrict-width"
                data={{
                  token: {
                    label: "Credit Card",
                    type: "creditcard",
                    required: true,
                  },
                }}
                url="/api/account/upgrade"
                method="POST"
                buttonText="Upgrade Now"
                callback={(res) => {
                  context.update({ plan: res.data.data.plan });
                  setIsPaid(true);
                }}
              />
            </Column>
          )}
          {isPaid && (
            <Column spacing={4}>
              <Row
                align="center"
                cursor="pointer"
                onClick={() => setIsOpen(!isOpen)}
                color="#C21E32"
              >
                <Text fontWeight="600" fontSize="16px">
                  Danger Zone
                </Text>
                {isOpen ? (
                  <HiChevronUp size={18} />
                ) : (
                  <HiChevronDown size={18} />
                )}
              </Row>

              {isOpen && (
                <>
                  <Text color="#C21E32">
                    Beware that your active referral campaigns will stop working
                  </Text>

                  <DangerButton onClick={cancelSubscription} maxW="200px">
                    Cancel Your Subscription{" "}
                  </DangerButton>
                </>
              )}
            </Column>
          )}
        </Column>
      </Card>
    </SettingsCard>
  );
}

import { Logo } from "components/logo/logo";
import { Stack } from "@chakra-ui/react";
import React from "react";

const AuthCard = ({ children, hasTerms }) => {
  return (
    <Stack maxW="380px" pt="64px" align="center" m="0 auto" spacing="32px">
      <Logo isBlue />
      <Stack
        w="100%"
        bg="white"
        borderRadius="1rem"
        p="40px"
        maxW="380px"
        align="center"
      >
        {children}
      </Stack>
      {hasTerms && (
        <span style={{ fontSize: "14px", opacity: "0.5" }}>
          By creating an account, you accept our terms and policy.
        </span>
      )}
    </Stack>
  );
};

export default AuthCard;

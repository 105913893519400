import { Signup } from "views/auth/signup";
import { SignupUser } from "views/auth/signupuser";
import { Signin } from "views/auth/signin";
import { ForgotPassword } from "views/auth/forgotpassword";
import { ResetPassword } from "views/auth/resetpassword";

export default [
  {
    path: "/master",
    view: Signin,
    layout: "auth",
    title: "Sign in to ReferralKit",
  },
  {
    path: "/signup",
    view: Signup,
    layout: "auth",
    title: "Sign up to ReferralKit",
  },
  {
    path: "/signup/user",
    view: SignupUser,
    layout: "auth",
    title: "Sign up to ReferralKit",
  },
  {
    path: "/signin",
    view: Signin,
    layout: "auth",
    title: "Welcome to ReferralKit",
  },
  {
    path: "/forgotpassword",
    view: ForgotPassword,
    layout: "auth",
    title: "Forgot Your Password?",
  },
  {
    path: "/resetpassword",
    view: ResetPassword,
    layout: "auth",
    title: "Reset Your Password",
  },
];

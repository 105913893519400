import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HiChevronDown } from "react-icons/hi";
import Axios from "axios";

import EmailListDetails from "../../components/EmailListDetails";
import {
  Flex,
  Spinner,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  Portal,
  MenuItemOption,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { Link, Card, Grid, useAPI, Icon } from "components/lib";
import MailChimp from "components/icons/MailChimp";
import Row from "components/custom/Row";
import ConvertKit from "components/icons/ConvertKit";
import Mailerlite from "components/icons/Mailerlite";
import Aweber from "components/icons/Aweber";

export function Dashboard({ location }) {
  const history = useHistory();

  const mailchimpAudiences = useAPI("/api/mailchimp/lists");
  const mailerliteLists = useAPI("/api/mailerlite/lists");
  const convertkitLists = useAPI("/api/convertkit/lists");
  const aweberAudiences = useAPI("/api/aweber/lists");

  const [selectedId, setSelectedId] = useState("");
  const [listId, setListId] = useState("");
  const [esp, setEsp] = useState("");
  const [mailchimpName, setMailchimpName] = React.useState("");
  const [aweberName, setAweberName] = React.useState("");
  const [dateRange, setDateRange] = React.useState(dateRangeData[4]);

  const [list, setList] = useState(null);
  const [loadingList, setLoadingList] = useState(false);

  async function loadListData(range) {
    setLoadingList(true);

    const timezoneOffset = new Date().getTimezoneOffset();

    const hasRange = range !== "";
    const url = hasRange
      ? `/api/${esp}/lists/${listId}?dateRange=${range}&timezoneOffset=${timezoneOffset}`
      : `/api/${esp}/lists/${listId}?timezoneOffset=${timezoneOffset}`;

    Axios.get(url)
      .then((response) => {
        const data = response.data.data;
        setList(data);
        setLoadingList(false);
      })
      .catch((e) => {
        setLoadingList(false);
      });

    // TODO: Remove this
    // Axios.get(`/api/${esp}/lists/${listId}/subscribers?orderBy=referralCount&direction=desc`).then(response => console.log(`/api/${esp}/lists/${listId}/subscribers?orderBy=referralCount&direction=desc`, response.data.data))
    // Axios.get(`/api/${esp}/lists/${listId}/subscribers?page=0&limit=100`).then(response => console.log(`/api/${esp}/lists/${listId}/subscribers?page=0&limit=100`, response.data.data))
    // Axios.get(`/api/${esp}/lists/${listId}/subscribers?page=1&limit=100`).then(response => console.log(`/api/${esp}/lists/${listId}/subscribers?page=1&limit=100`, response.data.data))
    // Axios.get(`/api/${esp}/lists/${listId}/subscribers?page=2&limit=100`).then(response => console.log(`/api/${esp}/lists/${listId}/subscribers?page=2&limit=100`, response.data.data))
    // Axios.get(`/api/${esp}/lists/${listId}/subscribers?page=3&limit=100`).then(response => console.log(`/api/${esp}/lists/${listId}/subscribers?page=3&limit=100`, response.data.data))
  }

  function onListSelected(espListId) {
    const [esp, listId] = espListId.split("_");
    setSelectedId(espListId);
    history.replace(`/dashboard?esp=${esp}&listId=${listId}`);
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryEsp = query.get("esp");
    const queryListId = query.get("listId");

    if (!queryEsp || !queryListId) {
      if (mailchimpAudiences.data && mailchimpAudiences.data.length > 0) {
        setMailchimpName(mailchimpAudiences.data[0].name);
        return onListSelected(`mailchimp_${mailchimpAudiences.data[0].id}`);
      } else if (aweberAudiences.data && aweberAudiences.data.length > 0) {
        setAweberName(aweberAudiences.data[0].name);
        return onListSelected(`aweber_${aweberAudiences.data[0].id}`);
      } else if (mailerliteLists.data) {
        return onListSelected(`mailerlite_${mailerliteLists.data[0].id}`);
      } else if (convertkitLists.data) {
        return onListSelected(`convertkit_${convertkitLists.data[0].id}`);
      }
    }

    setEsp(queryEsp);
    setListId(queryListId);
    setSelectedId(`${queryEsp}_${queryListId}`);
  }, [
    location,
    mailchimpAudiences,
    mailerliteLists,
    convertkitLists,
    aweberAudiences,
  ]);

  useEffect(() => {
    if (!listId) {
      return;
    }
    loadListData(dateRange.id);
  }, [listId]);

  return (
    <div>
      <Grid cols="1">
        <Flex justify="space-between" zIndex={10}>
          <Menu>
            <MenuButton
              as={ChakraButton}
              rightIcon={<HiChevronDown size={18} />}
              p="10px 16px"
              borderRadius="8px"
              bg="white"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
              minH="40px"
              _hover={{ bg: "white" }}
              _expanded={{ bg: "#f8f8f8" }}
              _focus={{ bg: "white" }}
              isDisabled={esp === null}
              zIndex={10}
            >
              <Row align="center">
                {esp === "convertkit" ? (
                  <ConvertKit />
                ) : esp === "mailerlite" ? (
                  <Mailerlite />
                ) : esp === "aweber" ? (
                  <Aweber />
                ) : esp === "mailchimp" ? (
                  <MailChimp />
                ) : null}
                <Text
                  fontfamily="Inter"
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="20px"
                  color="#4A4F56"
                  textTransform="capitalize"
                >
                  {esp === "mailchimp"
                    ? mailchimpName
                    : esp === "aweber"
                    ? aweberName
                    : esp}
                  {esp === null && "Please, Connect A Newsletter"}
                </Text>
              </Row>
            </MenuButton>

            <Portal>
              <MenuList>
                {mailerliteLists?.data?.length > 0 && (
                  <MenuItem
                    onClick={() =>
                      onListSelected(
                        `mailerlite_${mailerliteLists?.data[0]?.id}`
                      )
                    }
                  >
                    <Row p={2} spacing={4}>
                      <Mailerlite />
                      <Text>MailerLite</Text>
                    </Row>
                  </MenuItem>
                )}
                {convertkitLists?.data?.length > 0 && (
                  <MenuItem
                    onClick={(event) =>
                      onListSelected(
                        `convertkit_${convertkitLists?.data[0]?.id}`
                      )
                    }
                  >
                    <Row p={2} spacing={4}>
                      <ConvertKit />
                      <Text>Convertkit</Text>
                    </Row>
                  </MenuItem>
                )}

                {mailchimpAudiences?.data?.length > 0 && (
                  <MenuOptionGroup
                    defaultValue="asc"
                    title="MailChimp"
                    type="radio"
                  >
                    {mailchimpAudiences.data.map((audience) => (
                      <MenuItemOption
                        key={audience.id}
                        onClick={() => {
                          onListSelected(`mailchimp_${audience.id}`);
                          setMailchimpName(audience.name);
                        }}
                      >
                        <Row p={2} spacing={4}>
                          <MailChimp />
                          <Text>{audience.name}</Text>
                        </Row>
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                )}

                {aweberAudiences?.data?.length > 0 && (
                  <MenuOptionGroup
                    defaultValue="asc"
                    title="AWeber"
                    type="radio"
                  >
                    {aweberAudiences.data.map((audience) => (
                      <MenuItemOption
                        key={audience.id}
                        onClick={() => {
                          onListSelected(`aweber_${audience.id}`);
                          setAweberName(audience.name);
                        }}
                      >
                        <Row p={2} spacing={4}>
                          <Aweber />
                          <Text>{audience.name}</Text>
                        </Row>
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                )}
              </MenuList>
            </Portal>
          </Menu>

          <Menu>
            <MenuButton
              as={ChakraButton}
              rightIcon={<HiChevronDown size={18} />}
              p="10px 16px"
              borderRadius="8px"
              bg="white"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
              minH="40px"
              _hover={{ bg: "white" }}
              _expanded={{ bg: "#f8f8f8" }}
              _focus={{ bg: "white" }}
              zIndex={10}
            >
              <Text
                fontfamily="Inter"
                fontWeight="600"
                fontSize="14px"
                lineHeight="20px"
                color="#4A4F56"
                textTransform="capitalize"
              >
                {dateRange.name}
              </Text>
            </MenuButton>

            <Portal>
              <MenuList zIndex={10}>
                {dateRangeData.map((range) => (
                  <MenuItem
                    onClick={() => {
                      setDateRange(range);
                      loadListData(range.id);
                    }}
                  >
                    <Text
                      p={2}
                      fontfamily="Inter"
                      fontWeight="600"
                      fontSize="14px"
                      lineHeight="20px"
                      color="#4A4F56"
                      textTransform="capitalize"
                    >
                      {range.name}
                    </Text>
                  </MenuItem>
                ))}
              </MenuList>
            </Portal>
          </Menu>
        </Flex>
      </Grid>

      {loadingList ? (
        <Spinner />
      ) : list ? (
        <div style={{ marginTop: "16px" }}>
          <EmailListDetails
            emailList={list}
            esp={esp}
            listId={listId}
            dateRange={dateRange.name}
          />
        </div>
      ) : (
        <span style={{ fontSize: "14px" }}>
          You haven't connect a newsletter yet!{" "}
          <Link url="/settings/newsletters" text="Connect Your Newsletter." />
        </span>
      )}
    </div>
  );
}

const dateRangeData = [
  { name: "Today", id: "today" },
  { name: "Yesterday", id: "yesterday" },
  { name: "Last 7 Days", id: "7d" },
  { name: "Last 15 Days", id: "15d" },
  { name: "Last 30 Days", id: "30d" },
  { name: "All Time", id: "" },
];

import React from "react";
import { Dashboard } from "views/dashboard/dashboard";
import { OnboardingView } from "views/onboarding/onboarding";
import IntegrateView from "../views/integrate/IntegrateView";
import EmailList from "../views/emailList/emailList";
import MilestoneList from "../views/milestones/MilestoneList";
import { Emails } from "views/emails/emails";
import { Plans } from "views/plans/plans";
import { Subscribers } from "views/subscribers/subscribers";
import { LockedDashboard } from "views/dashboard/locked";
import { Branding } from "views/settings/branding";

export default [
  {
    path: "/dashboard",
    view: Dashboard,
    layout: "app",
    permission: "user",
    title: "Dashboard",
  },
  {
    path: "/locked",
    view: LockedDashboard,
    layout: "app",
    permission: "user",
    title: "Dashboard",
  },
  {
    path: "/onboarding",
    view: OnboardingView,
    layout: "onboarding",
    permission: "user",
    title: "Welcome",
  },
  {
    path: "/integrate",
    view: IntegrateView,
    layout: "onboarding",
    permission: "user",
    title: "Integrate",
  },
  {
    path: "/lists",
    view: EmailList,
    layout: "app",
    permission: "user",
    title: "Email lists",
  },
  {
    path: "/milestones",
    view: MilestoneList,
    layout: "app",
    permission: "user",
    title: "Milestones",
  },
  {
    path: "/emails",
    view: Emails,
    layout: "app",
    permission: "user",
    title: "Emails",
  },
  {
    path: "/plans",
    view: Plans,
    layout: "app",
    permission: "user",
    title: "Plans",
  },
  {
    path: "/subscribers",
    view: Subscribers,
    layout: "app",
    permission: "user",
    title: "Subscribers",
  },
  {
    path: "/branding",
    view: (props) => <Branding isSeperatePage {...props} />,
    layout: "app",
    permission: "user",
    title: "Branding",
  },
];

/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button as ChButton,
  Avatar,
  Text,
  Center,
} from "@chakra-ui/react";
import { HiUpload, HiChevronDown, HiChevronUp, HiX } from "react-icons/hi";
import {
  AuthContext,
  ViewContext,
  Form,
  Card,
  AccountNav,
  Button,
  History,
  Animate,
  useAPI,
} from "components/lib";
import Axios from "axios";
import SettingsCard from "components/custom/SettingsCard";
import Row from "components/custom/Row";
import Column from "components/custom/Column";
import CustomButton, { DangerButton } from "components/custom/CustomButton";
import UploadButton from "components/custom/UploadButton";

export function Profile(props) {
  const user = useAPI("/api/user");

  const [isOpen, setIsOpen] = React.useState(false);
  const [avatar, setAvatar] = React.useState("");
  const [name, setName] = React.useState();
  const [lastname, setLastname] = React.useState();
  const [email, setEmail] = React.useState();

  React.useEffect(() => {
    if (user.data) {
      setName(user?.data?.name);
      setLastname(user?.data?.lastname);
      setEmail(user?.data?.email);
    }
  }, [user.data]);

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  function closeAccount() {
    viewContext.modal.show(
      {
        title: "Close Your Account",
        form: closeAccount,
        buttonText: "Close Account",
        url: "/api/account",
        method: "DELETE",
        text:
          "Are you sure you want to delete your account? " +
          "You will lose all of your data and this can not be undone.",
      },
      () => {
        // destory user
        localStorage.clear();
        History.push("/signup");
      }
    );
  }

  return (
    <SettingsCard>
      <Card
        title="Account"
        loading={user.loading}
        restrictWidth
        noPadding
        lgTitle
      >
        <Row align="center" mb={8} spacing={4}>
          <Box role="group" position="relative">
            <Avatar
              src={avatar !== "" ? avatar : user?.data?.avatarURL}
              size="xl"
            />

            <Center
              onClick={() => setAvatar("")}
              display="none"
              cursor="pointer"
              _groupHover={{ display: "flex" }}
              position="absolute"
              top={0}
              left={0}
              w="100%"
              h="100%"
              bg="#ffffffcc"
              borderRadius="1000px"
              color="#2e66fe"
            >
              <HiX />
            </Center>
          </Box>

          <UploadButton
            onComplete={(url) => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              const img = new Image();

              img.onload = function () {
                // set size proportional to image
                canvas.width = 150;
                canvas.height = canvas.width * (img.height / img.width);

                // step 1 - resize to 50%
                const oc = document.createElement("canvas"),
                  octx = oc.getContext("2d");

                oc.width = img.width * 0.5;
                oc.height = img.height * 0.5;
                octx.drawImage(img, 0, 0, oc.width, oc.height);

                // step 2
                octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);

                // step 3, resize to final size
                ctx.drawImage(
                  oc,
                  0,
                  0,
                  oc.width * 0.5,
                  oc.height * 0.5,
                  0,
                  0,
                  canvas.width,
                  canvas.height
                );

                const base64 = canvas.toDataURL("image/jpg", 0.7);
                setAvatar(base64);
              };
              img.src = url;
            }}
          />
        </Row>

        {user?.data && (
          <Form
            updateOnChange
            buttonText="Save Changes"
            url={
              authContext.user.permission === "owner"
                ? "/api/account"
                : "/api/user"
            }
            method="PATCH"
            data={{
              avatar: {
                hidden: true,
                label: "Avatar",
                type: "text",
                value: avatar,
              },
              name: {
                label: "First Name",
                type: "text",
                required: true,
                value: name,
                errorMessage: "Please enter your name",
              },
              lastname: {
                label: "Last Name",
                type: "text",
                required: true,
                value: lastname,
                errorMessage: "Please enter your last name",
              },
              email: {
                label: "Email address",
                type: "email",
                required: true,
                value: email,
                errorMessage: "Please enter your email address",
              },
            }}
            callback={(res) => authContext.update({ name: res.data.data.name })}
            onChange={(obj) => {
              switch (obj.input) {
                case "name":
                  setName(obj.value);
                  break;
                case "lastname":
                  setLastname(obj.value);
                  break;
                case "email":
                  setEmail(obj.value);
                  break;
              }
            }}
          />
        )}

        {authContext.permission.owner && (
          <Column mt={16} spacing={4}>
            <Row
              align="center"
              cursor="pointer"
              onClick={() => setIsOpen(!isOpen)}
              color="#C21E32"
            >
              <Text fontWeight="600" fontSize="16px">
                Danger Zone
              </Text>
              {isOpen ? <HiChevronUp size={18} /> : <HiChevronDown size={18} />}
            </Row>

            {isOpen && (
              <>
                <Text color="#C21E32">Close your account permanently</Text>

                <DangerButton onClick={closeAccount}>
                  Close Your Account
                </DangerButton>
              </>
            )}
          </Column>
        )}
      </Card>
    </SettingsCard>
  );
}

import React from "react";
import Row from "./Row";
import {
  FacebookShareCount,
  FacebookIcon,
  FacebookShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookMessengerIcon,
} from "react-share";

export default function SocialShare({
  brandColor,
  canonicalUrl,
  description,
  title,
}) {
  return (
    <Row spacing={0} align="center">
      <TwitterShareButton url={canonicalUrl} title={title}>
        <TwitterIcon
          size={40}
          round
          bgStyle={{ fill: "#fff" }}
          iconFillColor={brandColor}
        />
      </TwitterShareButton>

      <FacebookShareButton
        url={canonicalUrl}
        quote={`${title} - ${description}`}
      >
        <FacebookIcon
          size={40}
          round
          bgStyle={{ fill: "#fff" }}
          iconFillColor={brandColor}
        />
      </FacebookShareButton>

      <LinkedinShareButton url={canonicalUrl}>
        <LinkedinIcon
          size={40}
          round
          bgStyle={{ fill: "#fff" }}
          iconFillColor={brandColor}
        />
      </LinkedinShareButton>

      <TelegramShareButton url={canonicalUrl} title={title}>
        <TelegramIcon
          size={40}
          round
          bgStyle={{ fill: "#fff" }}
          iconFillColor={brandColor}
        />
      </TelegramShareButton>

      <FacebookMessengerShareButton url={canonicalUrl} title={title}>
        <FacebookMessengerIcon
          size={40}
          round
          bgStyle={{ fill: "#fff" }}
          iconFillColor={brandColor}
        />
      </FacebookMessengerShareButton>
    </Row>
  );
}

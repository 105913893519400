import EmailListMilestones from "../views/milestones/MilestoneDetails";
import EmailListDetails from "../components/EmailListDetails";
import NewMilestone from "../views/milestones/NewMilestone";
import MilestoneDetails from "../views/milestones/MilestoneDetails";

export default [
  {
    path: "/lists/:esp/:listId/milestones/new",
    view: NewMilestone,
    layout: "app",
    permission: "user",
    title: "Create Milestone",
  },
  {
    path: "/lists/:esp/:listId/milestones/:milestoneId",
    view: MilestoneDetails,
    layout: "app",
    permission: "user",
    title: "Edit Milestone",
  },
];

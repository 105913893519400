import EmailListMilestones from "../views/milestones/MilestoneDetails";
import EmailListDetails from "../components/EmailListDetails";
import NewMilestone from "../views/milestones/NewMilestone";
import MilestoneDetails from "../views/milestones/MilestoneDetails";

export default [
    {
        path: '/lists/:esp/:listId',
        view: EmailListDetails,
        layout: 'app',
        permission: 'user',
    },
]

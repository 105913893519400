/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/

import React, { Fragment, useContext } from "react";
import { HiPlus } from "react-icons/hi";
import {
  ViewContext,
  Card,
  AccountNav,
  Table,
  Button,
  Animate,
  useAPI,
  usePermissions,
} from "components/lib";
import SettingsCard from "components/custom/SettingsCard";
import CustomButton from "components/custom/CustomButton";
import toast from "react-hot-toast";

export function Users(props) {
  const context = useContext(ViewContext);
  const permissions = usePermissions();
  const users = useAPI("/api/account/users");

  function invite(data) {
    context.modal.show({
      title: "Add User",
      form: {
        email: {
          label: "Email",
          type: "email",
          required: true,
        },
      },
      buttonText: "Send Invite",
      text: "To invite more than one user, seperate the emails with a comma",
      url: "/api/invite",
      method: "POST",
    });
  }

  function editUser(data, callback) {
    context.modal.show(
      {
        title: "Update User",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: "Name",
            type: "text",
            required: true,
            value: data.name,
            errorMessage: "Please enter a name",
          },
          email: {
            label: "Email",
            type: "email",
            value: data.email,
            required: true,
          },
          permission: {
            label: "Permission",
            type: "select",
            options: permissions.data.list,
            default: data.permission,
          },
        },
        buttonText: "Save",
        url: "/api/user",
        method: "PATCH",
      },
      (res) => {
        toast.success(data.name + " was updated");
        callback(res);
      }
    );
  }

  function deleteUser(data, callback) {
    context.modal.show(
      {
        title: "Delete User",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
        },
        buttonText: "Delete User",
        text: "Are you sure you want to delete " + data.name + "?",
        url: "/api/user",
        method: "DELETE",
      },
      () => {
        toast.success(data.name + " was deleted");
        callback();
      }
    );
  }

  return (
    <SettingsCard>
      <Card title="Manage Members" fullWidth noPadding lgTitle>
        <CustomButton
          onClick={invite}
          position="absolute"
          top="0"
          right="0"
          rightIcon={<HiPlus />}
        >
          Add User
        </CustomButton>

        <Table
          className="full-width"
          data={users?.data}
          loading={users?.loading}
          show={["name", "email", "date_created", "last_login", "permission"]}
          badge={{ col: "permission", color: "blue" }}
          actions={{ edit: editUser, delete: deleteUser, email: true }}
        />
      </Card>
    </SettingsCard>
  );
}

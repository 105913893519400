/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext } from "react";
import {
  AuthContext,
  Onboarding,
  Form,
  Image,
  Button,
  useAPI,
  usePlans,
} from "components/lib";
import ESPSelectView from "../integrate/ESPSelectView";
import ESPConfigurationView from "../integrate/ESPConfigurationView";
import ESPIntegrationDone from "../integrate/ESPIntegrationDone";
import ScriptConfiguration from "./ScriptConfiguration";
import DomainConfiguration from "./DomainConfiguration";
import CreditCardConfig from "./CreditCardConfig";
import useLocalStorage from "hooks/useLocalStorage";
import { Spinner } from "@chakra-ui/react";

export function OnboardingView(props) {
  const plans = usePlans();
  const price = plans?.data?.raw?.price;
  // const totalSubscribers = plans?.data?.raw?.totalSubscribers;
  const hasPaid = plans?.data?.raw?.hasPaid;
  const isFreePlan = price === 0;

  const query = new URLSearchParams(props.location.search);
  const esp = query.get("esp");

  const [step, setStep] = useLocalStorage("onboarding-step");
  const [onboardingEsp, setOnboardingEsp] = useLocalStorage(
    "onboarding-esp",
    esp
  );

  console.log(step, onboardingEsp);

  const user = useAPI("/api/user");
  const espData = useAPI("/api/account/esp");

  const totalSubscribers = espData?.data?.totalSubscriberCount;

  React.useEffect(() => {
    if (esp) {
      setOnboardingEsp(esp);
    }
  }, []);

  if (!user.data) {
    return <Spinner />;
  }

  const isInvited = user.data.permission === "user";

  return (
    <Onboarding
      save
      onFinish="/dashboard"
      views={[
        {
          name: "Connect Your Newsletter",
          description: "Choose And Connect Your Email Service Provider (ESP).",

          component: (
            <ESPSelectView
              ESPs={[
                {
                  id: "mailchimp",
                  name: "Mailchimp",
                  integrateUrl: `${
                    process.env.REACT_APP_MAILCHIMP_INTEGRATE_URL
                  }?user=${user.data.id}&redirectTo=${encodeURIComponent(
                    "https://app.referralkit.co/onboarding?esp=mailchimp#3"
                  )}`,
                },
                {
                  id: "aweber",
                  name: "AWeber",
                  local: true,
                  integrateUrl: `${
                    process.env.REACT_APP_AWEBER_INTEGRATE_URL
                  }?user=${user.data.id}&redirectTo=${encodeURIComponent(
                    "https://app.referralkit.co/onboarding?esp=aweber#3"
                  )}`,
                },
                {
                  id: "mailerlite",
                  name: "Mailerlite",
                  local: true,
                  integrateUrl: "/onboarding?esp=mailerlite#2",
                },
                {
                  id: "convertkit",
                  name: "ConvertKit",
                  local: true,
                  integrateUrl: "/onboarding?esp=convertkit#2",
                },
              ]}
            />
          ),
        },
        {
          name: "Configure ESP",
          description:
            "Fill in your API key. You can find the API key on your ESPs settings page." +
            "\n" +
            "Note: ConvertKit will only work with a premium ConvertKit account.",
          nextCopy: "Choose Payment Method",
          component: <ESPConfigurationView query={query} />,
        },
        {
          name: "Add script",
          description:
            "Add this script to your website. Ideally in the head tag.",
          nextCopy: "Setup Domain",
          component: <ScriptConfiguration hasBack={false} />,
        },
        {
          name: "Setup Domain",
          description:
            "Enter the URL of the website where you added the script.",
          nextCopy: "Check Installation",
          component: <DomainConfiguration baseURL={user?.data?.baseURL} />,
        },
        {
          name: "You’re Ready!",
          component: (
            <ESPIntegrationDone
              query={query}
              // baseURL={user?.data?.baseURL}
            />
          ),
        },
      ]}
    />
  );
}

function InviteUsers(props) {
  return (
    <Form
      data={{
        email: {
          label: "Email",
          type: "email",
          required: true,
        },
      }}
      buttonText="Send Invite"
      url="/api/invite"
      method="POST"
    />
  );
}

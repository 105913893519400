import React from "react";
import { useAPI } from "../../components/hooks/api";
import MailchimpConfiguration from "./MailchimpConfiguration";
import MailerliteConfiguration from "./MailerliteConfiguration";
import ConvertKitConfiguration from "./ConvertKitConfiguration";
import AweberConfiguration from "./AweberConfiguration";

export default function ESPConfigurationView({ query, ...props }) {
  const esp = query.get("esp");
  return (
    <div>
      {esp === "mailchimp" && <MailchimpConfiguration {...props} />}

      {esp === "mailerlite" && <MailerliteConfiguration {...props} />}

      {esp === "convertkit" && <ConvertKitConfiguration {...props} />}

      {esp === "aweber" && <AweberConfiguration {...props} />}
    </div>
  );
}

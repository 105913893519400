/***
 *
 *   SIGN UP
 *   Signup form for account owners
 *
 **********/

import React, { useContext } from "react";
import { Heading } from "@chakra-ui/react";
import { AuthContext, Form, Link, usePlans } from "components/lib";
import Column from "components/custom/Column";

export function Signup(props) {
  const context = useContext(AuthContext);

  return (
    <Column w="100%" align="center" spacing="32px">
      <Heading
        as="h2"
        fontfamily="Inter"
        fontstyle="normal"
        fontweight="bold"
        fontsize="24px"
        lineheight="32px"
        textalign="center"
        color="#4A4F56"
      >
        Create An Account
      </Heading>

      <Column w="100%" align="center" spacing="8px">
        <Form
          data={{
            name: {
              label: "First Name",
              value: "",
              type: "text",
              required: true,
              errorMessage: "Please enter your first name",
            },
            lastname: {
              label: "Last Name",
              value: "",
              type: "text",
              required: true,
              errorMessage: "Please enter your last name",
            },
            email: {
              label: "Email",
              value: "",
              type: "email",
              required: true,
            },
            password: {
              label: "Password",
              type: "password",
              required: true,
              complexPassword: true,
            },
            confirm_password: {
              type: "hidden",
              value: null,
            },
          }}
          url="/api/account"
          method="POST"
          buttonText="Create Account"
          callback={context.signin}
          fullWidthButton
          xlButton
        />

        <span style={{ fontSize: "14px" }}>
          I have an account already. <Link url="/signin" text="Log In" />
        </span>
      </Column>
    </Column>
  );
}

/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAPI } from "components/lib";
const permissions = require("./permissions");

// auth context
export const AuthContext = React.createContext();

export function AuthProvider(props) {
  const cache = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState(cache);
  const subscription = useAPI(user ? "/api/account/subscription" : null);

  useEffect(() => {
    // update subscription status
    if (!subscription.loading)
      update({ subscription: subscription?.data?.status });
  }, [subscription]);

  function signin(res) {
    if (res.data) {
      localStorage.setItem("user", JSON.stringify(res.data));

      window.location =
        res.data.permission === "master"
          ? "/dashboard"
          : res.data.onboarded
          ? "/dashboard"
          : "/onboarding";
    }
  }

  function signout() {
    localStorage.clear();
    window.location = "/signin";
  }

  function update(data) {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      for (let key in data) {
        if (typeof data[key] === "object") {
          for (let innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey];
          }
        } else {
          user[key] = data[key];
        }
      }

      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: user,
        signin: signin,
        signout: signout,
        update: update,
        permission: permissions[user?.permission],
      }}
      {...props}
    />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  return (
    <Route
      {...props}
      render={(data) => {
        // check user
        const user = JSON.parse(localStorage.getItem("user"));
        const path = window.location.pathname;

        if (user?.token) {
          if (permissions[props.permission][props.permission]) {
            // user has no subscription
            // if (user.subscription !== "active" && user.plan !== "master") {
            //   if (
            //     !path.includes("/settings/billing") &&
            //     !path.includes("/settings/upgrade")
            //   ) {
            //     return <Redirect to="/settings/billing" />;
            //   }
            // }

            // user is good
            return <props.render {...props} />;
          }
        }

        // user is not authenticated
        return <Redirect to="/signin" />;
      }}
    />
  );
}

import React from "react";

function Aweber({ size = 24, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.95 10H29.0125C18.5139 10 10 18.5139 10 29.0125V70.8533C10 81.3558 18.5139 89.8697 29.0125 89.8697H31.1315C26.0551 77.5901 23.4287 64.329 23.4287 48.2945C23.4287 33.6599 25.6426 21.3489 29.95 10Z"
        fill="#256BE8"
      />
      <path
        d="M70.8503 10H44.2604C39.3299 21.1285 36.6213 33.3307 36.6213 48.2945C36.6213 64.7414 39.8834 77.9301 45.6201 89.8697H70.8503C81.3558 89.8697 89.8687 81.3558 89.8687 70.8533V29.0125C89.8687 18.5139 81.3558 10 70.8503 10ZM57.983 84.5161C57.3256 85.0274 56.5919 85.2439 55.8542 85.2439C54.9726 85.2439 54.0919 84.8031 53.3581 83.8519C45.8689 73.6462 41.9005 63.9508 41.9005 50.0715C41.9005 36.2637 45.8689 26.6428 53.4346 16.5106C54.1683 15.5525 55.1195 15.1117 56.0041 15.1117C56.7349 15.1117 57.3962 15.3321 57.983 15.7719C58.942 16.3627 59.6023 17.2443 59.6023 18.269C59.6023 18.7862 59.3779 19.373 58.942 19.9608C52.3295 28.7716 48.4385 37.4393 48.4385 50.0715C48.4385 62.8468 52.403 71.6575 58.8646 80.3282C59.3045 80.9894 59.5249 81.5752 59.5249 82.0866C59.5249 83.1182 58.8646 83.9293 57.983 84.5161ZM70.7455 70.3948C71.0531 70.8562 71.2059 71.2657 71.2059 71.6223C71.2059 72.3384 70.7455 72.9017 70.1293 73.3111C69.6679 73.6707 69.1575 73.8205 68.6462 73.8205C68.0349 73.8205 67.4157 73.5178 66.9054 72.8497C61.6889 65.7416 58.9234 58.9861 58.9234 49.3192C58.9234 39.7012 61.6889 32.9986 66.9573 25.9434C67.4686 25.2753 68.1328 24.9677 68.749 24.9677C69.2623 24.9677 69.7208 25.1215 70.1293 25.4252C70.7935 25.8386 71.2549 26.4498 71.2549 27.166C71.2549 27.5235 71.1011 27.9359 70.7935 28.3444C66.1883 34.4847 63.4796 40.5231 63.4796 49.3192C63.4796 58.2181 66.2451 64.3564 70.7455 70.3948Z"
        fill="#256BE8"
      />
    </svg>
  );
}

export default React.memo(Aweber);

import React, { useState } from "react";
import { useAPI } from "../../components/hooks/api";
import { useHistory } from "react-router-dom";

import { Form } from "../../components/lib";

export default function AweberConfiguration() {
  const history = useHistory();
  const form = {
    apiKey: {
      label: "API Key",
      required: true,
      placeholder: "12345",
    },
  };

  function onSettingsSaved() {
    if (window.location.pathname === "/integrate") {
      history.replace("/integrate?esp=aweber#3");
    } else if (window.location.pathname === "/onboarding") {
      history.replace("/onboarding?esp=aweber#3");
    }
    window.location.reload();
  }

  return (
    <div>
      <Form
        method="POST"
        url="/api/aweber/settings"
        data={form}
        buttonText="Save settings"
        callback={onSettingsSaved}
      />
    </div>
  );
}

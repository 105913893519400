/***
 *
 *   MASTER LAYOUT
 *   The master dashboard layout containing the navigation
 *   and header (title, secondary nav)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import React, { Fragment, useContext } from "react";
import ClassNames from "classnames";
import { AuthContext, AppNav, Header, HoverNav, Button } from "components/lib";
import Style from "../app/app.module.scss";
import { useAPI } from "components/hooks/api";
import { AvatarDropdownMenu } from "components/layout/app/app";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";

export function MasterLayout(props) {
  // const history = useHistory();
  // const location = useLocation();

  // context
  const context = useContext(AuthContext);

  // style
  const css = ClassNames([Style.app, "with-sidebar"]);

  const user = useAPI("/api/user");

  // React.useEffect(() => {
  //   const route = location.pathname.split("/")[1];

  //   if (user.data && !user.loading) {
  //     const hasOnboarded = !user?.data?.onboarded;
  //     const isImporting = user?.data?.is_importing;

  //     const whitelistRoutes = ["settings", "plans"];
  //     if (isImporting) whitelistRoutes.push("milestones");

  //     const isProtectedRoute = !whitelistRoutes.includes(route);

  //     if (isProtectedRoute && !hasOnboarded) {
  //       history.push("/locked?reason=onboarding");
  //     } else if (isProtectedRoute && isImporting) {
  //       history.push("/locked?reason=import");
  //     }
  //   }
  // }, [user]);

  return (
    <Fragment>
      <AppNav
        type="popup"
        items={[
          { label: "Dashboard", icon: "activity", link: "/master/dashboard" },
          { label: "Feedback", icon: "heart", link: "/master/feedback" },
          { label: "Accounts", icon: "credit-card", link: "/master/accounts" },
          { label: "Users", icon: "users", link: "/master/users" },
        ]}
      />

      <main className={css}>
        <Header title={props.title}>
          {user.loading ? (
            <Spinner />
          ) : (
            <AvatarDropdownMenu
              src={user?.data?.avatarURL}
              items={[
                {
                  name: "Settings",
                  onClick: (e) => (window.location = "/settings"),
                },
                {
                  name: "Log Out",
                  onClick: context.signout,
                },
              ]}
              firstname={context.user.name}
            />
          )}
        </Header>

        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}

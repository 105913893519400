import React from "react";
import { useDropzone } from "react-dropzone";
import { HiUpload } from "react-icons/hi";

import CustomButton, { SecondaryButton } from "./CustomButton";

export default function UploadButton({
  hasMultiple = false,
  onComplete,
  isSecondary = false,
  text = "Upload",
  ...props
}) {
  const [files, setFiles] = React.useState([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(files);
      onComplete(
        hasMultiple ? files.map((file) => file.preview) : files[0].preview
      );
    },
  });

  const hasFile = files.length > 0;

  return (
    <Button
      isSecondary={isSecondary}
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      {...props}
    >
      {text}
      <input {...getInputProps()} accept="image/jpg, image/png, image/gif" />
    </Button>
  );
}

const Button = ({ children, isSecondary, ...props }) => {
  return isSecondary ? (
    <SecondaryButton rightIcon={<HiUpload />} {...props}>
      {children}
    </SecondaryButton>
  ) : (
    <CustomButton rightIcon={<HiUpload />} {...props}>
      {children}
    </CustomButton>
  );
};

import { MasterDashboard } from 'views/master/dashboard';
import { MasterAccounts } from 'views/master/accounts';
import { MasterUsers } from 'views/master/users';
import { MasterFeedback } from 'views/master/feedback';

export default [
  {
    path: '/master/dashboard',
    view: MasterDashboard,
    layout: 'master',
    permission: 'master',
    title: 'Master Dashboard'
  },
  {
    path: '/master/accounts',
    view: MasterAccounts,
    layout: 'master',
    permission: 'master',
    title: 'Accounts'
  },
  {
    path: '/master/users',
    view: MasterUsers,
    layout: 'master',
    permission: 'master',
    title: 'Users'
  },
  {
    path: '/master/feedback',
    view: MasterFeedback,
    layout: 'master',
    permission: 'master',
    title: 'User Feedback'
  },
]

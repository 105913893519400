import React, { Fragment } from "react";
import { AccountNav } from "../../components/nav/sub/account";
import { Animate, Button, Card, Grid, useAPI } from "../../components/lib";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import SettingsCard from "components/custom/SettingsCard";
import MailChimp from "components/icons/MailChimp";
import Mailerlite from "components/icons/Mailerlite";
import ConvertKit from "components/icons/ConvertKit";
import Row from "components/custom/Row";
import {
  SecondaryButton,
  OutlineButton,
  DangerButton,
} from "components/custom/CustomButton";
import { HiPencil, HiPlus } from "react-icons/hi";
import { BsPlug } from "react-icons/bs";
import Column from "components/custom/Column";
import Aweber from "components/icons/Aweber";

export default function Integrations() {
  const user = useAPI("/api/user");

  const mailchimpSettings = useAPI("/api/mailchimp/settings");
  const mailerliteSettings = useAPI("/api/mailerlite/settings");
  const aweberSettings = useAPI("/api/aweber/settings");
  const convertkitSettings = useAPI("/api/convertkit/settings");

  async function disconnect(esp) {
    if (window.confirm("Are you sure?")) {
      await Axios.delete(`/api/${esp}/settings`);
      window.location.reload();
    }
  }

  const isLoading =
    mailchimpSettings.loading ||
    mailerliteSettings.loading ||
    convertkitSettings.loading ||
    aweberSettings.loading;

  const hasFetched =
    "data" in mailchimpSettings &&
    "data" in mailerliteSettings &&
    "data" in convertkitSettings &&
    "data" in aweberSettings &&
    !mailchimpSettings.loading &&
    !mailerliteSettings.loading &&
    !aweberSettings.loading &&
    !convertkitSettings.loading &&
    mailchimpSettings.data !== null &&
    mailerliteSettings.data !== null &&
    aweberSettings.data !== null &&
    convertkitSettings.data !== null;

  const hasNewsletter =
    mailchimpSettings.data ||
    mailerliteSettings.data ||
    convertkitSettings.data ||
    aweberSettings.data;

  if (isLoading || !hasFetched) {
    return <Spinner />;
  }

  return (
    <SettingsCard>
      <Card title={"Connected Newsletters"} fullWidth noPadding lgTitle>
        <Column w="100%" spacing={4} mb={16}>
          {!mailchimpSettings.loading && mailchimpSettings.data && (
            <ConnectedCard
              name="Mailchimp"
              onClick={() => disconnect("mailchimp")}
              icon={<MailChimp />}
            />
          )}

          {!mailerliteSettings.loading && mailerliteSettings.data && (
            <ConnectedCard
              name="Mailerlite"
              onClick={() => disconnect("mailerlite")}
              icon={<Mailerlite />}
            />
          )}

          {!convertkitSettings.loading && convertkitSettings.data && (
            <ConnectedCard
              name="ConvertKit"
              onClick={() => disconnect("convertkit")}
              icon={<ConvertKit />}
            />
          )}

          {!aweberSettings.loading && aweberSettings.data && (
            <ConnectedCard
              name="AWeber"
              onClick={() => disconnect("aweber")}
              icon={<Aweber />}
            />
          )}

          {!hasNewsletter && !isLoading && (
            <Text
              fontSize="14px"
              fontWeight="600"
              color="#4a4f56"
              opacity="0.5"
            >
              You don't have a connected newletter yet.
            </Text>
          )}
        </Column>

        {!hasNewsletter && (
          <Card title="Connect a new ESP" noPadding>
            <Grid cols={2}>
              {user.data ? (
                <IntegrationCard
                  name="Mailchimp"
                  icon={<MailChimp />}
                  to={`${process.env.REACT_APP_MAILCHIMP_INTEGRATE_URL}?user=${
                    user.data.id
                  }&redirectTo=${encodeURIComponent(
                    "https://app.referralkit.co/integrate?esp=mailchimp#3"
                  )}`}
                  isExternal
                  isDisabled={hasNewsletter}
                />
              ) : (
                <Spinner />
              )}
              {user.data ? (
                <IntegrationCard
                  name="AWeber"
                  icon={<Aweber />}
                  to={`${process.env.REACT_APP_AWEBER_INTEGRATE_URL}?user=${
                    user.data.id
                  }&redirectTo=${encodeURIComponent(
                    "https://app.referralkit.co/integrate?esp=aweber#3"
                  )}`}
                  isExternal
                  isDisabled={hasNewsletter}
                />
              ) : (
                <Spinner />
              )}
              <IntegrationCard
                name="ConvertKit"
                icon={<ConvertKit />}
                to="/integrate?esp=convertkit#2"
                isDisabled={hasNewsletter}
              />
              <IntegrationCard
                name="Mailerlite"
                icon={<Mailerlite />}
                to="/integrate?esp=mailerlite#2"
                isDisabled={hasNewsletter}
              />
            </Grid>
          </Card>
        )}
      </Card>
    </SettingsCard>
  );
}

const ConnectedCard = ({ icon, name, onClick }) => {
  return (
    <Flex
      p="20px"
      w="100%"
      align="center"
      justify="space-between"
      bg="white"
      border="1px solid #DDDDDD"
      borderRadius="8px"
    >
      <Row spacing={4} align="center">
        {icon}
        <Text
          fontWeight="600"
          fontSize="14px"
          lineHeight="20px"
          color="#4A4F56"
        >
          {name}
        </Text>
      </Row>
      <DangerButton onClick={onClick} rightIcon={<BsPlug />}>
        Disconnect
      </DangerButton>
    </Flex>
  );
};

const IntegrationCard = ({
  icon,
  name,
  isDisabled,
  to,
  isExternal = false,
}) => {
  return (
    <Column
      p="20px"
      justify="space-between"
      bg="white"
      border="1px solid #DDDDDD"
      borderRadius="8px"
      spacing={8}
    >
      <Row spacing={4}>
        {icon}
        <Text
          fontWeight="600"
          fontSize="14px"
          lineHeight="20px"
          color="#4A4F56"
        >
          {name}
        </Text>
      </Row>
      <Text
        fontWeight="normal"
        fontSize="12px"
        lineHeight="16px"
        color="#4A4F56"
        opacity="0.8"
      >
        Sync your members with {name}’s email marketing platform.
      </Text>
      {isExternal ? (
        <a href={to}>
          <OutlineButton size="lg" rightIcon={<HiPlus />} w="auto" maxW="100px">
            Connect
          </OutlineButton>
        </a>
      ) : (
        <Link to={to}>
          <OutlineButton size="lg" rightIcon={<HiPlus />} w="auto" maxW="100px">
            Connect
          </OutlineButton>
        </Link>
      )}
    </Column>
  );
};

/***
 *
 *   PASSWORD
 *   Update the users password
 *
 **********/

import React, { Fragment } from "react";
import { Card, Form, AccountNav, Animate } from "components/lib";
import SettingsCard from "components/custom/SettingsCard";

export function Password(props) {
  return (
    <SettingsCard>
      <Card title="Password" restrictWidth noPadding lgTitle>
        <Form
          url="/api/user/password"
          method="PATCH"
          buttonText="Save Password"
          data={{
            oldpassword: {
              label: "Old Password",
              type: "password",
              required: true,
            },
            newpassword: {
              label: "New Password",
              type: "password",
              required: true,
            },
          }}
        />
      </Card>
    </SettingsCard>
  );
}

/***
 *
 *   SIGN UP (user)
 *   Signup form for child users
 *
 **********/

import React, { useContext } from "react";
import { Heading } from "@chakra-ui/react";
import { AuthContext, Form } from "components/lib";
import Column from "components/custom/Column";

export function SignupUser(props) {
  const context = useContext(AuthContext);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const email = urlParams.get("email");

  return (
    <Column w="100%" align="center" spacing="32px">
      <Heading
        as="h2"
        fontfamily="Inter"
        fontstyle="normal"
        fontweight="bold"
        fontsize="24px"
        lineheight="32px"
        textalign="center"
        color="#4A4F56"
      >
        Create An Account
      </Heading>
      <Column w="100%" align="center" spacing="8px">
        <Form
          data={{
            name: {
              label: "First Name",
              value: "",
              type: "text",
              required: true,
              errorMessage: "Please enter your first name",
            },
            lastname: {
              label: "Last Name",
              value: "",
              type: "text",
              required: true,
              errorMessage: "Please enter your last name",
            },
            email: {
              label: "Email",
              value: email,
              type: "email",
              required: true,
            },
            password: {
              label: "Password",
              type: "password",
              required: false,
            },
            inviteId: {
              type: "hidden",
              value: id,
            },
          }}
          url="/api/user"
          method="POST"
          redirect="/dashboard"
          buttonText="Create Account"
          callback={context.signin}
          fullWidthButton
          xlButton
        />

        <span>
          Already registered? <a href="/signin">Sign In</a>
        </span>
      </Column>
    </Column>
  );
}

/***
 *
 *   LOGO
 *   Replace the image in /images with your own logo
 *
 **********/

import React from "react";
import ClassNames from "classnames";

import LogoImage from "./images/logo.svg";
import LogoMark from "./images/logo-mark.svg";
import LogoImageBlue from "./images/logo-blue.svg";
import Style from "./logo.module.scss";
import { Link } from "react-router-dom";

export function Logo({ hasLink = true, link = "/dashboard", ...props }) {
  const css = ClassNames([
    props.isBlue ? Style.logoBlue : Style.logo,
    props.className,
  ]);

  return hasLink ? (
    <Link to={link} className={css}>
      <img
        src={props.mark ? LogoMark : props.isBlue ? LogoImageBlue : LogoImage}
        alt="Logo"
      />
    </Link>
  ) : (
    <img
      src={props.mark ? LogoMark : props.isBlue ? LogoImageBlue : LogoImage}
      alt="Logo"
      className={css}
    />
  );
}

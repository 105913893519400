import { Flex, Spinner, Text } from "@chakra-ui/react";
import Axios from "axios";
import Column from "components/custom/Column";
import CustomButton, { SecondaryButton } from "components/custom/CustomButton";
import React from "react";
import { HiArrowRight } from "react-icons/hi";
import { Link, useHistory } from "react-router-dom";
import { History, useAPI } from "../../components/lib";

export default function ESPIntegrationDone({ query }) {
  const user = useAPI("/api/user");

  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState(true);
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    validate();
  }, []);
  React.useEffect(() => {
    validate();
  }, [user]);

  function validate() {
    const baseURL = user?.data?.baseURL;
    console.log(baseURL);
    if (baseURL) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("url", baseURL);

      Axios({
        method: "GET",
        url: `/api/account/script/validate/${encodeURIComponent(baseURL)}`,
        data: formData,
      })
        .then((response) => {
          const isValid = response.data === "OK";

          setIsLoading(false);
          setIsReady(isValid);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }

  function goToDashboard() {
    history.push("/dashboard");
  }

  console.log(user);
  if (isLoading || user?.loading || !user?.data) {
    return <Spinner />;
  }

  return (
    <Column spacing="32px">
      <Column spacing="16px">
        {isReady ? <CheckIcon /> : <WarningIcon />}

        <Text
          fontWeight="bold"
          fontSize="24px"
          lineHeight="32px"
          color="#4A4F56"
        >
          {isReady ? "You're Ready" : "Almost Done..."}
        </Text>

        <Text fontSize="14px" lineHeight="20px" color="#4A4F56">
          {isReady
            ? "Your website includes our script, then you’re ready to start using ReferralKit!"
            : "You need to add the script to your website."}
        </Text>
      </Column>

      {/* <CustomButton onClick={() => setIsReady(!isReady)}>Toggle</CustomButton> */}

      <Flex justify="flex-end" w="100%">
        {!isReady && (
          <SecondaryButton onClick={validate} mr={2}>
            Re-Check
          </SecondaryButton>
        )}
        <CustomButton
          rightIcon={<HiArrowRight />}
          onClick={goToDashboard}
          isDisabled={!isReady}
        >
          Open Dashboard
        </CustomButton>
      </Flex>
    </Column>
  );
}

const WarningIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25ZM12.5 5C11.6716 5 11 5.67157 11 6.5V13.5C11 14.3284 11.6716 15 12.5 15C13.3284 15 14 14.3284 14 13.5V6.5C14 5.67157 13.3284 5 12.5 5ZM12.5 20C13.3284 20 14 19.3284 14 18.5C14 17.6716 13.3284 17 12.5 17C11.6716 17 11 17.6716 11 18.5C11 19.3284 11.6716 20 12.5 20Z"
        fill="#FF9950"
      />
    </svg>
  );
};
const CheckIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8 25.6C16.1948 25.6 19.4505 24.2514 21.851 21.851C24.2514 19.4505 25.6 16.1948 25.6 12.8C25.6 9.40523 24.2514 6.1495 21.851 3.74903C19.4505 1.34857 16.1948 0 12.8 0C9.40523 0 6.1495 1.34857 3.74903 3.74903C1.34857 6.1495 0 9.40523 0 12.8C0 16.1948 1.34857 19.4505 3.74903 21.851C6.1495 24.2514 9.40523 25.6 12.8 25.6V25.6ZM18.7312 10.7312C19.0227 10.4294 19.1839 10.0253 19.1803 9.60576C19.1766 9.18624 19.0084 8.78494 18.7117 8.48829C18.4151 8.19164 18.0138 8.02337 17.5942 8.01972C17.1747 8.01608 16.7706 8.17735 16.4688 8.4688L11.2 13.7376L9.1312 11.6688C8.82944 11.3773 8.42527 11.2161 8.00576 11.2197C7.58624 11.2234 7.18494 11.3916 6.88829 11.6883C6.59164 11.9849 6.42337 12.3862 6.41972 12.8058C6.41608 13.2253 6.57735 13.6294 6.8688 13.9312L10.0688 17.1312C10.3688 17.4312 10.7757 17.5997 11.2 17.5997C11.6243 17.5997 12.0312 17.4312 12.3312 17.1312L18.7312 10.7312V10.7312Z"
        fill="#1EC26A"
      />
    </svg>
  );
};

import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  Spinner,
  Heading,
  Text,
  Flex,
  Box,
  Menu,
  Button as ChakraButton,
  Portal,
  MenuList,
  MenuButton,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
} from "@chakra-ui/react";
import { Table, useAPI } from "components/lib";
import Column from "components/custom/Column";
import Row from "components/custom/Row";
import CustomButton, {
  LinkButton,
  OutlineButton,
} from "components/custom/CustomButton";
import { HiChevronLeft, HiChevronRight, HiChevronDown } from "react-icons/hi";
import ConvertKit from "components/icons/ConvertKit";
import Mailerlite from "components/icons/Mailerlite";
import MailChimp from "components/icons/MailChimp";
import { useHistory } from "react-router-dom";
import Aweber from "components/icons/Aweber";

function mostReferralsFirst(a, b) {
  if (a.referrals > b.referrals) {
    return -1;
  }
  if (a.referrals < b.referrals) {
    return 1;
  }
  return 0;
}

export function Subscribers({ location }) {
  const history = useHistory();

  const mailchimpAudiences = useAPI("/api/mailchimp/lists");
  const mailerliteLists = useAPI("/api/mailerlite/lists");
  const convertkitLists = useAPI("/api/convertkit/lists");
  const aweberAudiences = useAPI("/api/aweber/lists");

  const [esp, setEsp] = React.useState();
  const [mailchimpName, setMailchimpName] = React.useState();
  const [aweberName, setAweberName] = React.useState();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(25);

  function onListSelected(espListId) {
    const [esp, listId] = espListId.split("_");
    history.replace(`/subscribers?esp=${esp}&listId=${listId}`);
    setPage(0);
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryEsp = query.get("esp");
    const queryListId = query.get("listId");

    if (!queryEsp || !queryListId) {
      if (mailchimpAudiences.data && mailchimpAudiences.data.length > 0) {
        setMailchimpName(mailchimpAudiences.data[0].name);
        return onListSelected(`mailchimp_${mailchimpAudiences.data[0].id}`);
      } else if (aweberAudiences.data && aweberAudiences.data.length > 0) {
        setAweberName(aweberAudiences.data[0].name);
        return onListSelected(`aweber_${aweberAudiences.data[0].id}`);
      } else if (mailerliteLists.data) {
        return onListSelected(`mailerlite_${mailerliteLists.data[0].id}`);
      } else if (convertkitLists.data) {
        return onListSelected(`convertkit_${convertkitLists.data[0].id}`);
      }
    }

    setEsp(queryEsp);
  }, [
    location,
    mailchimpAudiences,
    mailerliteLists,
    convertkitLists,
    aweberAudiences,
  ]);

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    loadData();
  }, [location, page]);

  function loadData() {
    const query = new URLSearchParams(location.search);
    const esp = query.get("esp");
    const listId = query.get("listId");

    setIsLoading(true);

    Axios.get(
      `/api/${esp}/lists/${listId}/subscribers?orderBy=referralCount&direction=desc&limit=${pageCount}&page=${page}`
    )
      .then((response) => {
        console.log(response);
        const emailList = response.data.data;

        const table = emailList.subscribers.map(({ email, referralCount }) => ({
          email,
          referrals: Number(referralCount),
        }));

        setTotalPages(emailList.pages);

        setData(table);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  const isListsLoading = React.useMemo(
    () =>
      mailchimpAudiences?.loading ||
      aweberAudiences?.loading ||
      mailerliteLists?.loading ||
      convertkitLists?.loading,
    [aweberAudiences, mailchimpAudiences, mailerliteLists, convertkitLists]
  );

  const hasLoadLists =
    Array.isArray(mailchimpAudiences?.data) ||
    Array.isArray(aweberAudiences?.data) ||
    Array.isArray(convertkitLists?.data) ||
    Array.isArray(mailerliteLists?.data);

  if (isLoading || isListsLoading || !hasLoadLists) {
    return <Spinner />;
  }

  return (
    <Column spacing="20px" w="100%">
      <Box>
        <Menu>
          <MenuButton
            as={ChakraButton}
            rightIcon={<HiChevronDown size={18} />}
            p="10px 16px"
            borderRadius="8px"
            bg="white"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
            minH="40px"
            _hover={{ bg: "white" }}
            _expanded={{ bg: "#f8f8f8" }}
            _focus={{ bg: "white" }}
            isDisabled={esp === null}
            zIndex={10}
          >
            <Row align="center">
              {esp === "convertkit" ? (
                <ConvertKit />
              ) : esp === "mailerlite" ? (
                <Mailerlite />
              ) : esp === "aweber" ? (
                <Aweber />
              ) : esp === "mailchimp" ? (
                <MailChimp />
              ) : null}
              <Text
                fontfamily="Inter"
                fontWeight="600"
                fontSize="14px"
                lineHeight="20px"
                color="#4A4F56"
                textTransform="capitalize"
              >
                {esp === "mailchimp"
                  ? mailchimpName
                  : esp === "aweber"
                  ? aweberName
                  : esp}
                {esp === null && "Please, Connect A Newsletter"}
              </Text>
            </Row>
          </MenuButton>

          <Portal>
            <MenuList>
              {mailerliteLists?.data?.length > 0 && (
                <MenuItem
                  onClick={() =>
                    onListSelected(`mailerlite_${mailerliteLists?.data[0]?.id}`)
                  }
                >
                  <Row p={2} spacing={4}>
                    <Mailerlite />
                    <Text>MailerLite</Text>
                  </Row>
                </MenuItem>
              )}
              {convertkitLists?.data?.length > 0 && (
                <MenuItem
                  onClick={(event) =>
                    onListSelected(`convertkit_${convertkitLists?.data[0]?.id}`)
                  }
                >
                  <Row p={2} spacing={4}>
                    <ConvertKit />
                    <Text>Convertkit</Text>
                  </Row>
                </MenuItem>
              )}

              {aweberAudiences?.data?.length > 0 && (
                <MenuOptionGroup defaultValue="asc" title="Aweber" type="radio">
                  {aweberAudiences.data.map((audience) => (
                    <MenuItemOption
                      key={audience.id}
                      onClick={() => {
                        onListSelected(`aweber_${audience.id}`);
                        setAweberName(audience.name);
                      }}
                    >
                      <Row p={2} spacing={4}>
                        <Aweber />
                        <Text>{audience.name}</Text>
                      </Row>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              )}

              {mailchimpAudiences?.data?.length > 0 && (
                <MenuOptionGroup
                  defaultValue="asc"
                  title="MailChimp"
                  type="radio"
                >
                  {mailchimpAudiences.data.map((audience) => (
                    <MenuItemOption
                      key={audience.id}
                      onClick={() => {
                        onListSelected(`mailchimp_${audience.id}`);
                        setMailchimpName(audience.name);
                      }}
                    >
                      <Row p={2} spacing={4}>
                        <MailChimp />
                        <Text>{audience.name}</Text>
                      </Row>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              )}
            </MenuList>
          </Portal>
        </Menu>
      </Box>

      <Column spacing="20px" p="40px" borderRadius="16px" bg="white">
        <Heading fontWeight="600" fontSize="16px" lineHeight="24px">
          All Your Subscribers
        </Heading>

        <Table data={data} />

        <Flex w="100%" justify="space-between" align="center">
          <Text
            textTransform="uppercase"
            fontSize="10px"
            fontWeight={600}
            color="gray.400"
          >
            Page{" "}
            <Box as="span" color="gray.600">
              {page + 1}
            </Box>{" "}
            of {totalPages}
          </Text>
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        </Flex>
      </Column>
    </Column>
  );
}

const Pagination = ({ page, setPage, totalPages }) => {
  const hasPrevious = page > 0;
  const hasNext = page < totalPages - 1;

  function previousPage() {
    if (hasPrevious) {
      setPage(page - 1);
    }
  }

  function nextPage() {
    if (hasNext) {
      setPage(page + 1);
    }
  }

  const handleClick = (number) => (e) => {
    e.preventDefault();
    setPage(number);
  };

  const visiblePages = getVisiblePages(page, totalPages);
  return (
    <Row spacing={4} align="center">
      <LinkButton
        leftIcon={<HiChevronLeft />}
        isDisabled={!hasPrevious}
        onClick={previousPage}
      >
        Previous
      </LinkButton>

      <Row align="flex-end" spacing={1}>
        {visiblePages.map((number) =>
          number === "..." ? (
            <Text color="#4F7EFE">...</Text>
          ) : number === page ? (
            <CustomButton
              onClick={handleClick(number)}
              size="md"
              w="28px"
              h="28px"
              px={0}
              borderRadius="40px"
            >
              {number + 1}
            </CustomButton>
          ) : (
            <LinkButton
              onClick={handleClick(number)}
              size="md"
              px={0}
              fontWeight={800}
              borderRadius="40px"
              color="gray.600"
            >
              {number + 1}
            </LinkButton>
          )
        )}
      </Row>

      <LinkButton
        rightIcon={<HiChevronRight />}
        isDisabled={!hasNext}
        onClick={nextPage}
      >
        Next
      </LinkButton>
    </Row>
  );
};

function getVisiblePages(page, totalPages) {
  if (totalPages < 8) {
    return createArrayFromN(totalPages);
  }
  if (page < 2)
    return [0, 1, 2, "...", totalPages - 2, totalPages - 1, totalPages];
  else if (page < totalPages - 1) {
    return [0, "...", page - 1, page, page + 1, "...", totalPages];
  } else return [0, 1, 2, "...", totalPages - 2, totalPages - 1, totalPages];
}

function createArrayFromN(n) {
  return Array.from(Array(n).keys());
}

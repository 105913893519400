import React from "react";
import { Button } from "@chakra-ui/react";

const CustomButton = (props) => {
  return (
    <Button
      size="lg"
      p="8px 12px"
      color="white"
      background="linear-gradient(135deg, #6A92FF -0.56%, #2E66FE 99.44%)"
      borderRadius="8px"
      _hover={{
        color: "white",
        background: "linear-gradient(135deg, #6A92FF -0.56%, #2E66FE 99.44%)",
      }}
      _focus={{
        color: "white",
        background: "linear-gradient(135deg, #6A92FF -0.56%, #2E66FE 99.44%)",
      }}
      _active={{
        color: "white",
        background: "linear-gradient(135deg, #6A92FF -0.56%, #2E66FE 99.44%)",
      }}
      {...props}
    />
  );
};

export default CustomButton;

export const DangerButton = (props) => {
  return (
    <Button
      size="lg"
      p="8px 12px"
      bg="rgba(194, 30, 50, 0.05)"
      color="#C21E32"
      borderRadius="4px"
      _hover={{
        bg: "rgba(194, 30, 50, 0.05)",
        color: "#C21E32",
      }}
      _focus={{
        bg: "rgba(194, 30, 50, 0.05)",
        color: "#C21E32",
      }}
      _active={{
        bg: "rgba(194, 30, 50, 0.05)",
        color: "#C21E32",
      }}
      {...props}
    />
  );
};

export const SecondaryButton = (props) => {
  return (
    <Button
      size="lg"
      p="8px 12px"
      bg="#E8EEFF"
      color="#013BDA"
      borderRadius="4px"
      _hover={{
        bg: "#E8EEFF",
        color: "#013BDA",
      }}
      _focus={{
        bg: "#E8EEFF",
        color: "#013BDA",
      }}
      _active={{
        bg: "#E8EEFF",
        color: "#013BDA",
      }}
      {...props}
    />
  );
};

export const OutlineButton = (props) => {
  return (
    <Button
      size="lg"
      p="8px 12px"
      bg="transparent"
      color="#4F7EFE"
      border="1px solid #4F7EFE"
      borderRadius="4px"
      _hover={{
        bg: "transparent",
        color: "#4F7EFE",
        border: "1px solid #4F7EFE",
      }}
      _focus={{
        bg: "transparent",
        color: "#4F7EFE",
        border: "1px solid #4F7EFE",
      }}
      _active={{
        bg: "transparent",
        color: "#4F7EFE",
        border: "1px solid #4F7EFE",
      }}
      {...props}
    />
  );
};

export const LinkButton = (props) => {
  return (
    <Button
      size="lg"
      p="8px 12px"
      bg="transparent"
      color="#4F7EFE"
      border="none"
      borderRadius="4px"
      _hover={{
        bg: "transparent",
        color: "#4F7EFE",
        border: "none",
      }}
      _focus={{
        bg: "transparent",
        color: "#4F7EFE",
        border: "none",
      }}
      _active={{
        bg: "transparent",
        color: "#4F7EFE",
        border: "none",
      }}
      {...props}
    />
  );
};
